import React from "react";

function privacyTemrs() {
  return (
    <div>
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration="600"
        data-easing="ease-out-expo"
        data-easing2="ease-out-expo"
        role="banner"
        class="navbar w-nav"
      >
        <div class="nav-block">
          <div class="nav">
            <nav role="navigation" class="nav-menu w-nav-menu">
              <a href="features" class="nav-link w-nav-link">
                Features
                <br />
              </a>
              <a href="blog" class="nav-link w-nav-link">
                Soon
              </a>
              <a href="contact" class="nav-link w-nav-link">
                Contact
              </a>
            </nav>
          </div>
          <div class="nav">
            <a href="login" class="nav-link-extra w-nav-link">
              Log in
            </a>
            <div class="block-nav-button">
              <a href="register" class="nav-button w-button">
                Create account
              </a>
              <div class="menu-button w-nav-button">
                <div class="menu-icon w-icon-nav-menu"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow">
        <div className="section-top">
          <div style={{ opacity: 0 }} className="content">
            <div className="block-top">
              <div className="block-heading-top">
                <h1
                  style={{
                    transform:
                      "translate3d(null, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                  }}
                  className="heading-hero"
                >
                  PRIVACY TERMS
                </h1>
              </div>
            </div>
          </div>
          <div style={{ opacity: 0 }} className="blur-top"></div>

          <div className="section">
            <div style={{ opacity: 0 }} className="content">
              <div className="content-narrow template">
                <div className="block-template">
                  <h3 className="heading-template">Privacy terms Swapbits</h3>
                </div>
              </div>
            </div>
            <p>
              <strong>Effective Date:</strong> January 5, 2025
              <br />
              <strong>Last Updated:</strong> January 5, 2025
              <br />
              At Swapbits Financial, we respect your privacy and are committed
              to protecting your personal information. This document explains
              how we collect, use, protect, and share your data. By using our
              services on{" "}
              <a target="_new" href="http://www.swapbits.io/">
                www.swapbits.io
              </a>
              , you agree to the terms of this Privacy Policy.
              <br />
              <br />
              <strong>1. Data Controller</strong>
              <br />
              The entity responsible for processing your personal data is:
              <br />
              <strong>Legal Name:</strong> Swapbits Financial
              <br />
              <strong>Registration Number:</strong> 0001132148
              <br />
              <strong>VASP License Number:</strong> RDWW-1584
              <br />
              <strong>Address:</strong> Bartycka 22B/21A, 00-716 Warsaw, Poland
              <br />
              <strong>Email:</strong>
              <a href="mailto:support@swapbits.io">Support@swapbits.io</a>
              <br />
              <br />
              <strong>2. Information We Collect</strong>
              <br />
              <strong>a) User-Provided Data</strong>
              <br />
              <strong>Personal Information:</strong> Name, address, email, phone
              number, and identification documents.
              <br />
              <strong>Financial Information:</strong> Bank details or
              cryptocurrency wallet information.
              <br />
              <strong>Other Information:</strong> Data required to comply with
              KYC (Know Your Customer) regulations.
              <br />
              <br />
              <strong>b) Automatically Collected Data</strong>
              <br />
              IP address, browser type, device, and operating system.
              <br />
              Platform usage information, such as dates, times, and activities
              performed.
              <br />
              <br />
              <strong>c) Third-Party Data:</strong>
              <br />
              Information obtained from external providers for KYC and AML
              (Anti-Money Laundering) verifications.
              <br />
              <br />
              <strong>3. Purposes of Data Processing</strong>
              <br />
              Your personal data will be processed for the following purposes:
              <br />
              <strong>Service Provision:</strong> Managing your registration,
              facilitating transactions, and operating the platform.
              <br />
              <strong>Legal Compliance:</strong> Ensuring adherence to AML and
              KYC regulations.
              <br />
              <strong>Service Improvement:</strong> Optimizing user experience
              and developing new features.
              <br />
              <strong>Communications:</strong> Sending account-related
              notifications and, with your consent, promotional information.
              <br />
              <br />
              <strong>4. Legal Basis for Processing</strong>
              <br />
              <strong>Contract Execution:</strong> To provide the requested
              services.
              <br />
              <strong>Legal Obligations:</strong> Compliance with AML/CTF
              regulations.
              <br />
              <strong>Consent:</strong> For commercial communications.
              <br />
              <strong>Legitimate Interest:</strong> To improve and protect our
              services.
              <br />
              <br />
              <strong>5. International Data Transfers</strong>
              <br />
              Your data may be transferred and processed outside the European
              Economic Area (EEA). We ensure that such transfers comply with
              appropriate safeguards, such as standard contractual clauses
              approved by the European Commission.
              <br />
              <br />
              <strong>6. User Rights</strong>
              <br />
              As a data subject, you have the following rights:
              <br />
              <strong>Access:</strong> Obtain a copy of your data.
              <br />
              <strong>Rectification:</strong> Correct inaccurate information.
              <br />
              <strong>Erasure:</strong> Request the deletion of your personal
              data.
              <br />
              <strong>Restriction:</strong> Limit the processing of your data.
              <br />
              <strong>Objection:</strong> Object to processing in certain
              circumstances.
              <br />
              <strong>Data Portability:</strong> Receive your data in a
              structured format.
              <br />
              <br />
              To exercise these rights, contact us at{" "}
              <a href="mailto:Support@swapbits.io" target="_blank">
                Support@swapbits.io
              </a>
              , including a copy of your identification document.
              <br />
              <br />
              <strong>7. Data Security</strong>
              <br />
              We implement technical and organizational measures to protect your
              data, including:
              <br />
              Encryption of sensitive data.
              <br />
              Restrictive access controls.
              <br />
              Continuous monitoring of the platform to prevent unauthorized
              access.
              <br />
              <br />
              <strong>8. Data Retention</strong>
              <br />
              We will retain your data as long as necessary to fulfill our legal
              obligations, provide requested services, resolve disputes, or
              enforce agreements. Once these purposes are fulfilled, the data
              will be securely deleted.
              <br />
              <br />
              <strong>9. Use of Cookies</strong>
              <br />
              We use cookies to personalize your experience, analyze traffic and
              user behavior, and facilitate essential website functionalities.
              You can manage your cookie preferences through your browser
              settings. For more details, refer to our Cookie Policy.
              <br />
              <br />
              <strong>10. Data Sharing with Third Parties</strong>
              <br />
              We may share your information with:
              <br />
              Service providers for KYC/AML verifications and payment
              processing.
              <br />
              Regulatory authorities as required by applicable laws.
              <br />
              Third parties authorized by the user with explicit consent.
              <br />
              <br />
              <strong>11. Changes to This Policy</strong>
              <br />
              We reserve the right to update this Privacy Policy. Any
              significant changes will be communicated via our website or email.
              <br />
              <br />
              <strong>12. Contact Information</strong>
              <br />
              For inquiries related to this Privacy Policy, you can contact us
              at:
              <br />
              <strong>Email:</strong>
              <a href="mailto:privacy@swapbits.io">privacy@swapbits.io</a>
              <br />
              <strong>Registration Number:</strong> 0001132148
              <br />
              <strong>VASP License Number:</strong> RDWW-1584
              <br />
              <strong>Address:</strong> Bartycka 22B/21A, 00-716 Warsaw, Poland
            </p>
          </div>
        </div>

        <div className="section-footer">
          <div className="content">
            <div className="w-layout-grid grid-footer">
              <div
                id="w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fb3-12d14fb0"
                className="block-footer"
              >
                <p className="paragraph-footer">
                  Swapbits Financial: Your trusted platform to manage
                  cryptocurrencies safely and efficiently. We are committed to
                  offering an exceptional user experience and complying with the
                  most demanding regulatory standards. <br />
                  VASP LICENCE RDWW-1584
                </p>
                <h4></h4>
                <a href="contact" className="link-block w-inline-block">
                  <img
                    src="assets/images/icon_6.svg"
                    loading="eager"
                    alt=""
                    className="icon-link"
                  />
                  <div className="text-link">Support@swapbits.io</div>
                </a>
              </div>
              <div
                id="w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fbb-12d14fb0"
                className="block-footer"
              >
                <div className="subtitle-footer">Quick Links</div>
                <a href="features" className="link-footer">
                  Features
                </a>
                <a href="blog" className="link-footer">
                  Soon
                </a>
                <a href="contact" className="link-footer">
                  Contact
                </a>
              </div>
              <div
                id="w-node-_92ed4044-9cfe-904b-e616-3634ff09c860-12d14fb0"
                className="block-footer"
              >
                <div className="subtitle-footer">COMPANY</div>
                <a href="terms" className="link-footer">
                  Terms and conditions
                </a>
                <a href="privacy-terms" className="link-footer">
                  Privacy terms
                </a>
              </div>
            </div>
            <div className="block-footer-down">
              <div className="text-footer-down">
                <a
                  href="https://swapbits.io/"
                  target="_blank"
                  className="link-footer-down"
                >
                  © 2025 Swapbits Financial.All rights reserved
                  <br />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default privacyTemrs;
