import React, { useState, useEffect } from "react";
import axios from 'axios'; // Usamos axios para hacer las solicitudes HTTP
import {jwtDecode} from 'jwt-decode';
import { SignJWT } from 'jose';
import { v4 } from "uuid";
function Contact() {

    const [formData, setFormData] = useState({
        "firstname": "",
        "lastname": "",
        email: "",
        Message: "",
      });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading , setIsLoading] = useState(false);

  // Función para manejar el cambio en los campos del formulario
  const handleChange = (e) => {
    
    const { name, value } = e.target;
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Actualiza el campo correspondiente en formData
    }));
  };

  const handleSubmit = async (e) => {
    setIsLoading(true)
    setErrorMessage("")
    setSuccessMessage("")
    e.preventDefault();
  
    // Validación de los campos
    console.log(!formData.email)
    if (!formData.firstname || !formData.email || !formData.Message) {
      setErrorMessage('Please fill in all the fields: first name, email, and message.');
    setIsLoading(false)

      return; // Si falta algún campo, no enviamos los datos
    }
  
    // Validar formato de email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
    setIsLoading(false)

      return; // Si el email no es válido, no enviamos los datos
    }
    const encoder = new TextEncoder();
  const secret = encoder.encode(process.env.REACT_APP_SECRET_KEY); // La clave secreta
  const token = await new SignJWT({ email: formData.email, nonce: v4() }) // Datos a firmar
    .setProtectedHeader({ alg: 'HS256' }) // Algoritmo de firma
    .sign(secret);
  
    try {
      // Generar un token usando jwtDecode y la clave secreta del backend
  
      // Enviar los datos al backend con el token de verificación
      const response = await axios.post('https://www.swapbits.io/api/auth/users/contact', {
        ...formData,
        token, // Enviamos el token para validar la solicitud en el backend
      });
    //   console.log(response.data.message)
      if (response.status === 201) {
        setErrorMessage("")
        setSuccessMessage(response.data.message);
    setIsLoading(false)

      }
    } catch (error) {
    setIsLoading(false)
        // console.log(error)
      setErrorMessage('Oops! Something went wrong while submitting the form.');
    }
  };
  
  
  useEffect(() => {
        const wrappers = document.querySelectorAll(".wrapper");
    
        wrappers.forEach((wrapper) => {
          const blurredColor = wrapper.querySelector(".blurred-color");
    
          if (!blurredColor) return;
    
          let lastOffsetX = 0.01; // Última posición en X
          let lastOffsetY = 0.01; // Última posición en Y
    
          const handleMouseMove = (event) => {
            const rect = wrapper.getBoundingClientRect();
            const isInBounds =
              event.clientX >= rect.left &&
              event.clientX <= rect.right &&
              event.clientY >= rect.top &&
              event.clientY <= rect.bottom;
    
            if (isInBounds) {
              // Calcular desplazamiento dinámico dentro del wrapper
              lastOffsetX = ((event.clientX - rect.left) / rect.width - 0.5) * 200; // De -100% a 100%
              lastOffsetY = ((event.clientY - rect.top) / rect.height - 0.5) * 200; // De -100% a 100%
    
              // Aplicar posición dinámica
              // blurredColor.style.transition = "transform 0.01s ease-out"; // Suavizar la transición
    
              blurredColor.style.transform = `translate3d(${lastOffsetX}%, ${lastOffsetY}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
              blurredColor.style.willChange = "transform";
              blurredColor.style.transformStyle = "preserve-3d";
            } else {
              // Si el mouse sale, usa la última posición como referencia
              blurredColor.style.transform = `translate3d(${lastOffsetX.toFixed(
                2
              )}%, ${lastOffsetY.toFixed(
                2
              )}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
            }
          };
    
          // Añadir listener para el movimiento del mouse
          document.addEventListener("mousemove", handleMouseMove);
    
          // Limpiar al desmontar
          return () => {
            document.removeEventListener("mousemove", handleMouseMove);
          };
        });
      }, []);
    
      useEffect(() => {
        const wrappers = document.querySelectorAll(".wrapper");
    
        wrappers.forEach((wrapper) => {
          const blurredColor = wrapper.querySelector(".blurred-color");
    
          if (!blurredColor) return;
    
          let isMouseInside = false;
          let opacityInterval = null;
          let currentOpacity = 0;
    
          const handleMouseEnter = () => {
            isMouseInside = true;
            if (opacityInterval) clearInterval(opacityInterval);
    
            opacityInterval = setInterval(() => {
              if (currentOpacity < 1) {
                currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incrementar opacidad
                blurredColor.style.opacity = currentOpacity.toFixed(2);
              } else {
                clearInterval(opacityInterval); // Detener en 1
                opacityInterval = null;
              }
            }, 10); // Incrementar cada 10ms
          };
    
          const handleMouseLeave = () => {
            isMouseInside = false;
            if (opacityInterval) clearInterval(opacityInterval);
    
            opacityInterval = setInterval(() => {
              if (currentOpacity > 0) {
                currentOpacity = Math.max(currentOpacity - 0.01, 0); // Reducir opacidad
                blurredColor.style.opacity = currentOpacity.toFixed(2);
              } else {
                clearInterval(opacityInterval); // Detener en 0
                opacityInterval = null;
              }
            }, 10); // Reducir cada 10ms
          };
    
          // Añadir listeners al contenedor
          wrapper.addEventListener("mouseenter", handleMouseEnter);
          wrapper.addEventListener("mouseleave", handleMouseLeave);
    
          // Limpiar listeners al desmontar
          return () => {
            wrapper.removeEventListener("mouseenter", handleMouseEnter);
            wrapper.removeEventListener("mouseleave", handleMouseLeave);
            if (opacityInterval) clearInterval(opacityInterval);
          };
        });
      }, []);


      useEffect(() => {
        const content = document.querySelectorAll(".section first");
        let opacityInterval = null;

        content.forEach((e) => {
          const img = e.querySelector(".content");
      
          let currentOpacity = 0;
      
          if (opacityInterval) clearInterval(opacityInterval);
      
          opacityInterval = setInterval(() => {
            if (currentOpacity < 1) {
              currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incrementar opacidad
              img.style.opacity = currentOpacity.toFixed(2);
            } else {
              clearInterval(opacityInterval); // Detener en 1
              opacityInterval = null;
            }
          }, 10);
        });
      
        // Limpiar intervalo cuando el componente se desmonte
        return () => {
            clearInterval(opacityInterval);
          };
      }, []);

      useEffect(() => {
        const content = document.querySelectorAll(".section-top");
        let opacityInterval = null;

        content.forEach((e) => {
          const img = e.querySelector(".content");
          const blurtop = e.querySelector(".blur-top");
      
          let currentOpacity = 0;
      
          if (opacityInterval) clearInterval(opacityInterval);
      
          opacityInterval = setInterval(() => {
            if (currentOpacity < 1) {
              currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incrementar opacidad
              img.style.opacity = currentOpacity.toFixed(2);
              blurtop.style.opacity = currentOpacity.toFixed(2);
            } else {
              clearInterval(opacityInterval); // Detener en 1
              opacityInterval = null;
            }
          }, 10);
        });
      
        // Limpiar intervalo cuando el componente se desmonte
        return () => {
            clearInterval(opacityInterval);
          };
      }, []);

      useEffect(() => {
        const content = document.querySelectorAll(".section first");
        let opacityInterval = null;

        content.forEach((e) => {
          const img = e.querySelector(".content");
          const blurtop = e.querySelector(".blur-top");
      
          let currentOpacity = 0;
      
          if (opacityInterval) clearInterval(opacityInterval);
      
          opacityInterval = setInterval(() => {
            if (currentOpacity < 1) {
              currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incrementar opacidad
              img.style.opacity = currentOpacity.toFixed(2);
              blurtop.style.opacity = currentOpacity.toFixed(2);
            } else {
              clearInterval(opacityInterval); // Detener en 1
              opacityInterval = null;
            }
          }, 10);
        });
      
        // Limpiar intervalo cuando el componente se desmonte
        return () => {
            clearInterval(opacityInterval);
          };
      }, []);
  return (
    <div>
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration="600"
        data-easing="ease-out-expo"
        data-easing2="ease-out-expo"
        role="banner"
        class="navbar w-nav"
      >
        <div class="nav-block">
          <div class="nav">
            <nav role="navigation" class="nav-menu w-nav-menu">
              <a
                href="features"
                aria-current="page"
                class="nav-link w-nav-link w--current"
              >
                Features
                <br />
              </a>
              <a href="blog" class="nav-link w-nav-link">
                Soon
              </a>
              <a href="contact" class="nav-link w-nav-link">
                Contact
              </a>
            </nav>
          </div>
          <div class="nav">
            <a href="login" class="nav-link-extra w-nav-link">
              Log in
            </a>
            <div class="block-nav-button">
              <a href="register" class="nav-button w-button">
                Create account
              </a>
              <div class="menu-button w-nav-button">
                <div class="menu-icon w-icon-nav-menu"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow">
        <div
          data-w-id="c21e9273-07ed-2b45-c62e-0b9527ee6c42"
          class="section-top"
        >
          <div style={{ opacity: "0" }} class="content">
            <div class="block-top">
              <div class="block-heading-top">
                <div
                  style={{
                    "-webkit-transform":
                      "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    "-moz-transform":
                      "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    "-ms-transform":
                      "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: "1",
                  }}
                  class="subtitle"
                >
                  Contact
                </div>
                <h1
                  style={{
                    "-webkit-transform":
                      "translate3d(null, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    "-moz-transform":
                      "translate3d(null, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    "-ms-transform":
                      "translate3d(null, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(null, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: "1",
                  }}
                  class="heading-hero"
                >
                  Contact our support team for assistance
                </h1>
              </div>
              <p
                style={{
                  "-webkit-transform":
                    "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  "-moz-transform":
                    "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  "-ms-transform":
                    "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: "1",
                }}
                class="paragraph-hero"
              >
                Our support team is available to help you with any queries
                regarding Swapbits.
              </p>
            </div>
          </div>
          <div style={{ opacity: "0" }} class="blur-top"></div>
        </div>
        <div class="section first">
          <div  class="content">
            <div class="block-contact">
              <div
                data-w-id="7d653bd4-7643-7d43-19ea-eef99b735a58"
                class="wrapper"
              >
                <div style={{ opacity: "0" }} class="blurred-color"></div>
                <div class="content-wrapper">
                  <div class="contact">
                    <div class="form-block w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        class="form"
                        data-wf-page-id="67968b85dad40729c53bab11"
                        data-wf-element-id="c83ce313-760e-a65b-e92f-3fbdab136dcc"
                        onSubmit={handleSubmit}
                      >
                        <div class="field-group">
                          <div class="field">
                            <label for="firstname" class="field-label">
                              First name{" "}
                              <span class="color-label">(required)</span>
                            </label>
                            <input
                              class="text-field w-input"
                              maxLength="256"
                              name="firstname"
                              data-name="First Name"
                              placeholder="First Name"
                              type="text"
                              id="firstname"
                              required=""
                              value={formData.firstname}
                              onChange={handleChange}
                            />
                          </div>
                          <div class="field">
                            <label for="lastname" class="field-label">
                              Last Name
                            </label>
                            <input
                              class="text-field w-input"
                              maxLength="256"
                              name="lastname"
                              data-name="Last Name"
                              placeholder="Last Name"
                              type="email"
                              id="lastname"
                              value={formData.lastname}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div class="field">
                          <label for="email" class="field-label">
                            Email <span class="color-label">(required)</span>
                          </label>
                          <input
                            class="text-field w-input"
                            maxLength="256"
                            name="email"
                            data-name="Email"
                            placeholder="Your Email"
                            type="email"
                            id="email"
                            required=""
                            value={formData.email}
                              onChange={handleChange}
                          />
                        </div>
                        <div class="field">
                          <label for="message" class="field-label">
                            Message
                          </label>
                          <textarea
                            placeholder="Tell us how we can help"
                            maxlength="5000"
                            id="message"
                            name="Message"
                            data-name="Message"
                            class="textarea w-input"
                            value={formData.Message}
                              onChange={handleChange}
                          ></textarea>
                        </div>
                        <input
                          type="submit"
                          data-wait="Please wait..."
                          class="submit-button w-button"
                          value={isLoading ? "Please Wait..." : "Submit"}
                          disabled={isLoading}
                        />
                      </form>
                      {errorMessage &&
                   
                      <div class="text-error">{errorMessage}</div>
                   
                      }
                        {successMessage && <div class="text-success">{successMessage}</div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-footer">
          <div className="content">
            <div className="w-layout-grid grid-footer">
              <div
                id="w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fb3-12d14fb0"
                className="block-footer"
              >
                <p className="paragraph-footer">
                  Swapbits Financial: Your trusted platform to manage
                  cryptocurrencies safely and efficiently. We are committed to
                  offering an exceptional user experience and complying with the
                  most demanding regulatory standards. <br />
                  VASP LICENCE RDWW-1584
                </p>
                <h4></h4>
                <a href="contact" className="link-block w-inline-block">
                  <img
                    src="assets/images/icon_6.svg"
                    loading="eager"
                    alt=""
                    className="icon-link"
                  />
                  <div className="text-link">Support@swapbits.io</div>
                </a>
              </div>
              <div
                id="w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fbb-12d14fb0"
                className="block-footer"
              >
                <div className="subtitle-footer">Quick Links</div>
                <a href="features" className="link-footer">
                  Features
                </a>
                <a href="blog" className="link-footer">
                  Soon
                </a>
                <a href="contact" className="link-footer">
                  Contact
                </a>
              </div>
              <div
                id="w-node-_92ed4044-9cfe-904b-e616-3634ff09c860-12d14fb0"
                className="block-footer"
              >
                <div className="subtitle-footer">COMPANY</div>
                <a href="terms" className="link-footer">
                  Terms and conditions
                </a>
                <a href="privacy-terms" className="link-footer">
                  Privacy terms
                </a>
              </div>
            </div>
            <div className="block-footer-down">
              <div className="text-footer-down">
                <a
                  href="https://swapbits.io/"
                  target="_blank"
                  className="link-footer-down"
                >
                  © 2025 Swapbits Financial.All rights reserved
                  <br />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
