import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../styles/DashboardWithdraw.css";

const Withdraw = () => {
  const [withdrawDetails, setWithdrawDetails] = useState({});
  const [balances, setBalances] = useState({}); // Balance del usuario
  const [coins, setCoins] = useState([]); // Lista de todas las monedas
  const [selectedCoin, setSelectedCoin] = useState(''); // Criptomoneda seleccionada
  const [networks, setNetworks] = useState([]); // Redes compatibles con la moneda seleccionada
  const [selectedNetwork, setSelectedNetwork] = useState(''); // Red seleccionada
  const [amount, setAmount] = useState(''); // Cantidad total ingresada
  const [to, setTo] = useState(''); // Dirección de destino
  const [message, setMessage] = useState(''); // Mensaje de éxito o error
  const [liveError, setLiveError] = useState(''); // Errores en tiempo real
  const [showConfirmation, setShowConfirmation] = useState(false); // Mostrar modal de confirmación
  const [confirmationDetails, setConfirmationDetails] = useState(null); // Detalles de confirmación

  useEffect(() => {
    const fetchWithdrawDetails = async () => {
      try {
        const token = localStorage.getItem('jwt');
        const response = await axios.get('https://www.swapbits.io/api/wallets/info', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.withdrawDetails && response.data.balances) {
          setWithdrawDetails(response.data.withdrawDetails);

          // Procesar balances del usuario
          const userBalances = response.data.balances.reduce((acc, item) => {
            acc[item.symbol] = item.balance;
            return acc;
          }, {});
          setBalances(userBalances);

          // Cargar todas las monedas disponibles
          const availableCoins = Array.from(
            new Set(
              Object.values(response.data.withdrawDetails).flatMap((detail) =>
                detail.minAmount.map((entry) => entry.currency)
              )
            )
          );
          setCoins(availableCoins);
          setSelectedCoin(availableCoins[0]); // Selecciona la primera moneda como predeterminada
        }
      } catch (error) {
        console.error('Error al obtener los detalles de retiro:', error);
      }
    };

    fetchWithdrawDetails();
  }, []);

  useEffect(() => {
    if (selectedCoin) {
      const compatibleNetworks = Object.keys(withdrawDetails).filter((network) =>
        withdrawDetails[network]?.minAmount.some((entry) => entry.currency === selectedCoin)
      );

      setNetworks(compatibleNetworks);
      setSelectedNetwork(compatibleNetworks[0]); // Selecciona la primera red como predeterminada
    }
  }, [selectedCoin, withdrawDetails]);

  const handleWithdraw = async () => {
    try {
      const minAmountEntry = withdrawDetails[selectedNetwork]?.minAmount.find((entry) => entry.currency === selectedCoin);
      const numericAmount = parseFloat(amount);
      const userBalance = balances[selectedCoin] || 0;

      if (!minAmountEntry) {
        setMessage(`Error: No se encontraron detalles de retiro para ${selectedCoin} en la red ${selectedNetwork}.`);
        return;
      }

      const amountSent = numericAmount - minAmountEntry.fee;

      if (numericAmount > userBalance) {
        setMessage(`Error: No puedes retirar más de tu balance disponible (${userBalance} ${selectedCoin}).`);
        return;
      }

      if (amountSent < minAmountEntry.amount) {
        setMessage(`Error: El monto enviado (${amountSent.toFixed(8)} ${selectedCoin}) debe ser mayor o igual al retiro mínimo (${minAmountEntry.amount} ${selectedCoin}).`);
        return;
      }

      const payload = {
        coin: selectedCoin,
        amount: numericAmount,
        type: selectedNetwork,
        to,
      };

      const response = await axios.post(
        'https://www.swapbits.io/api/wallets/withdraw',
        payload,
        { headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` } }
      );

      setMessage(`Transacción exitosa: ${response.data.message}`);
    } catch (error) {
      console.error('Error al enviar la transacción:', error);
      setMessage(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*(\.\d{0,8})?$/.test(value)) {
      setAmount(value);

      const numericValue = parseFloat(value);
      const minAmountEntry = withdrawDetails[selectedNetwork]?.minAmount.find((entry) => entry.currency === selectedCoin);
      const userBalance = balances[selectedCoin] || 0;

      if (minAmountEntry) {
        const amountSent = numericValue - minAmountEntry.fee;

        if (numericValue > userBalance) {
          setLiveError(`El monto total (${numericValue.toFixed(8)} ${selectedCoin}) excede tu balance (${userBalance} ${selectedCoin}).`);
        } else if (amountSent < minAmountEntry.amount) {
          setLiveError(`El monto enviado (${amountSent.toFixed(8)} ${selectedCoin}) debe ser mayor o igual al retiro mínimo (${minAmountEntry.amount} ${selectedCoin}).`);
        } else {
          setLiveError('');
        }
      }
    }
  };

  const handleMaxClick = () => {
    const userBalance = balances[selectedCoin] || 0;
    const minAmountEntry = withdrawDetails[selectedNetwork]?.minAmount.find((entry) => entry.currency === selectedCoin);

    if (minAmountEntry) {
      // if (numericAmount > userBalance) {
      //   setMessage(`Error: No puedes retirar más de tu balance disponible (${userBalance} ${selectedCoin}).`);
      //   return;
      // }

      
      const maxAmount = userBalance;
      if (maxAmount < minAmountEntry.amount) {
        setLiveError(`Error: El monto enviado (${maxAmount.toFixed(8)} ${selectedCoin}) debe ser mayor o igual al retiro mínimo (${minAmountEntry.amount} ${selectedCoin}).`);
        // return;
      }
      setAmount(maxAmount.toFixed(8));
      // setLiveError('');
    }
  };

  const handleShowConfirmation = () => {
    if (!to || !amount) {
      setMessage("Error: Debes ingresar una dirección válida y un monto.");
      
      return;
    }
    setMessage("")
    const minAmountEntry = withdrawDetails[selectedNetwork]?.minAmount.find((entry) => entry.currency === selectedCoin);
    if (minAmountEntry) {
      const numericAmount = parseFloat(amount);
      const fee = minAmountEntry.fee;
      const totalToSend = numericAmount - fee;
  
      setConfirmationDetails({
        amount: numericAmount,
        fee,
        totalToSend,
        coin: selectedCoin,
        network: selectedNetwork,
        to,
      });
  
      setShowConfirmation(true);
    }
  };

  const handleConfirmWithdraw = async () => {
    try {
      const { totalToSend, coin, network, to } = confirmationDetails;
      
      const payload = {
        coin: coin,
        amount: totalToSend,
        type: network,
        to,
      };
      console.log(payload)
  
      const response = await axios.post(
        'https://www.swapbits.io/api/wallets/withdraw',
        payload,
        { headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` } }
      );
  
      setMessage(`Transacción exitosa: ${response.data.message}`);
      setShowConfirmation(false); // Cierra el modal
    } catch (error) {
      console.error('Error al enviar la transacción:', error);
      setMessage(`Error: ${error.response?.data?.message || error.message}`);
      setShowConfirmation(false); // Cierra el modal
    }
  };
  
  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
    setConfirmationDetails(null);
  };
  

  return (
    <div className="withdraw-container">
      <h2>Retirar Fondos</h2>
      <div>
        <label>Criptomoneda:</label>
        <select
          className="withdraw-select-coin"
          value={selectedCoin}
          onChange={(e) => {
            setSelectedCoin(e.target.value);
            setAmount('');
            setLiveError('');
          }}
        >
          {coins.map((coin) => (
            <option key={coin} value={coin}>{coin}</option>
          ))}
        </select>
        <p className="withdraw-info">Balance disponible: {balances[selectedCoin] || 0} {selectedCoin}</p>
      </div>
      <div>
        <label>Red:</label>
        <select
          className="withdraw-select-network"
          value={selectedNetwork}
          onChange={(e) => {
            setSelectedNetwork(e.target.value);
            setAmount('');
            setLiveError('');
          }}
        >
          {networks.map((network) => (
            <option key={network} value={network}>{network}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Cantidad:</label>
        <div className="amount-container">
          <input
            className="withdraw-input-amount"
            type="text"
            placeholder="Ingrese la cantidad"
            value={amount}
            onChange={handleAmountChange}
          />
          <button className="max-button" onClick={handleMaxClick}>MAX</button>
        </div>
        <p className="withdraw-info">
          Comisión: {withdrawDetails[selectedNetwork]?.minAmount.find((entry) => entry.currency === selectedCoin)?.fee || 'N/A'} {selectedCoin}
        </p>
        <p className="withdraw-info">
          Retiro mínimo: {withdrawDetails[selectedNetwork]?.minAmount.find((entry) => entry.currency === selectedCoin)?.amount || 'N/A'} {selectedCoin}
        </p>
        <p className="withdraw-info">
          Confirmaciones necesarias: {withdrawDetails[selectedNetwork]?.confirmations || 'N/A'}
        </p>
        {liveError && <p className="withdraw-error">{liveError}</p>}
      </div>
      <div>
        <label>Dirección de destino:</label>
        <input
          className="withdraw-input-destination"
          type="text"
          placeholder="Ingrese la dirección"
          value={to}
          onChange={(e) => setTo(e.target.value)}
        />
      </div>
      <button className="withdraw-button" onClick={handleShowConfirmation}>
  Enviar Retiro
</button>

      {message && (
        <p className={`withdraw-message ${message.startsWith('Error') ? 'error' : 'success'}`}>
          {message}
        </p>
      )}

      {/* Modal de confirmación */}
{showConfirmation && confirmationDetails && (
  <div className="confirmation-modal">
    <h3>Confirmación de Retiro</h3>
    <p>Vas a enviar: {confirmationDetails.totalToSend.toFixed(8)} {confirmationDetails.coin}</p>
    <p>Comisión aplicada: {confirmationDetails.fee} {confirmationDetails.coin}</p>
    <p>Total ingresado: {confirmationDetails.amount} {confirmationDetails.coin}</p>
    <p>Red: {confirmationDetails.network}</p>
    <p>Dirección de destino: {confirmationDetails.to}</p>
    <div className="confirmation-buttons">
      <button onClick={handleConfirmWithdraw}>Confirmar</button>
      <button onClick={handleCancelConfirmation}>Cancelar</button>
    </div>
  </div>
)}

    </div>
  );
};

export default Withdraw;
