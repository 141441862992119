import React, { useEffect } from 'react';

const Logout = () => {
    useEffect(() => {
        // Clear user data from local storage or cookies
        localStorage.removeItem('jwt');
        localStorage.removeItem('userData');

        // Redirect to login page
        window.location.href = "/";
    }, []);

    return (
        <div>
            <h1>Logging out...</h1>
        </div>
    );
};

export default Logout;