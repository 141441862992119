import React, { useState, useEffect } from "react";
import axios from "axios";

const KycVerification = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [externalUserId, setExternalUserId] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("jwt");

    if (!token) {
      setErrorMessage("Usuario no autenticado. Por favor, inicie sesión.");
      window.location.href = "/";
      return;
    }

    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      setExternalUserId(`user${decodedToken.userId}`); // Asegúrate de que `userId` esté presente en el JWT
    } catch (error) {
      setErrorMessage("Error decodificando el token.");
      console.error("JWT Error:", error);
    }
  }, []);

  const startKycProcess = async () => {
    if (!externalUserId) {
      setErrorMessage("No se encontró un ID de usuario válido.");
      return;
    }

    setErrorMessage(""); // Limpiar errores previos

    try {
      // Verificar estado KYC
      const statusResponse = await axios.get(
        `https://www.swapbits.io/api/kyc/status`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          validateStatus: (status) => {
            // Considerar 401 como válido para manejarlo manualmente
            return status === 200 || status === 401;
          },
        }
      );

      if (statusResponse.status === 401) {
        const errorCode = statusResponse.data?.code;
        console.log(statusResponse.data);
        if (errorCode === 5002) {
          console.log("Usuario verificado pero sin KYC activo. Continuando...");
          // Continuar con el flujo
        } else {
          setErrorMessage("Usuario no autenticado. Por favor, inicie sesión.");
          localStorage.removeItem("jwt");
          window.location.href = "/";
          return;
        }
      } else {
        const kycStatus = statusResponse.data.status;

        if (kycStatus.enable === "pending" || kycStatus.enable === "true") {
          setErrorMessage(
            "No puedes realizar la verificación KYC, ya estás aprobado o tu solicitud está pendiente."
          );
          return;
        }
      }
    } catch (error) {
      console.error(
        "Error al verificar el estado KYC:",
        error.response?.data || error.message
      );
      setErrorMessage("Error al verificar el estado KYC.");
      return;
    }

    let applicantId;

    try {
      // Crear Applicant
      const jwt = localStorage.getItem("jwt");
      const applicantResponse = await axios.post(
        "https://www.swapbits.io/api/kyc/applicant",
        { levelName: "basic-kyc-level" },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      console.log("Applicant creado:", applicantResponse.data);
      applicantId = applicantResponse.data.id; // ID del applicant recién creado
    } catch (error) {
      console.error("Error al crear el applicant:", error.response?.data.code);
      if (error.response?.data.code === 409) {
        console.warn("Applicant ya existe:", error.response.data);
        const match = error.response.data.description.match(/'([\w\d]+)'/);
        if (match) {
          applicantId = match[1]; // Extraer el applicantId del mensaje de error
        }
      } else {
        console.error(
          "Error en el flujo KYC:",
          error.response?.data || error.message
        );
        setErrorMessage("Error durante el proceso KYC.");
        return;
      }
    }

    // Si el applicantId ya está definido (ya existía), continuar con el paso 2
    if (!applicantId) {
      setErrorMessage("No se pudo obtener el ID del aplicante.");
      return;
    }

    try {
      // Obtener Access Token
      const tokenResponse = await axios.post(
        "https://www.swapbits.io/api/kyc/access-token",
        { levelName: "basic-kyc-level" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          body: {

          }
        }
      );
      const accessToken = tokenResponse.data.accessToken;
      console.log("Access Token obtenido:", accessToken);

      // Lanzar WebSDK
      const script = document.createElement("script");
      script.src =
        "https://static.sumsub.com/idensic/static/sns-websdk-builder.js";
      script.onload = () => {
        const snsWebSdkInstance = window.snsWebSdk
          .init(accessToken, () => Promise.resolve(accessToken))
          .withConf({
            lang: "en",
            email: localStorage.getItem("email"),
            phone: localStorage.getItem("phone"),
          })
          .withOptions({ addViewportTag: false, adaptIframeHeight: true })
          .on("idCheck.onStepCompleted", (payload) => {
            console.log("onStepCompleted", payload);
          })
          .on("idCheck.onError", (error) => {
            console.log("onError", error);
          })
          .onMessage((type, payload) => {
            console.log("onMessage", type, payload);
          })
          .build();

        snsWebSdkInstance.launch("#sumsub-websdk-container");
      };
      document.body.appendChild(script);
    } catch (error) {
      console.error(
        "Error obteniendo el Access Token:",
        error.response?.data || error.message
      );
      setErrorMessage("Error obteniendo el Access Token.");
    }
  };

  return (
    <div>
      <h2>Verificación KYC</h2>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <div id="sumsub-websdk-container"></div>
      <button onClick={startKycProcess}>Iniciar Verificación KYC</button>
    </div>
  );
};

export default KycVerification;
