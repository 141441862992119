import React, { useEffect } from "react";
import "../styles/TermsAndConditions.css";

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = "Términos y Condiciones - Swapbits";
  }, []);

  return (
    <div className="terms-container">
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration="600"
        data-easing="ease-out-expo"
        data-easing2="ease-out-expo"
        role="banner"
        class="navbar w-nav"
      >
        <div class="nav-block">
          <div class="nav">
            <nav role="navigation" class="nav-menu w-nav-menu">
              <a href="features" class="nav-link w-nav-link">
                Features
                <br />
              </a>
              <a href="blog" class="nav-link w-nav-link">
                Soon
              </a>
              <a href="contact" class="nav-link w-nav-link">
                Contact
              </a>
            </nav>
          </div>
          <div class="nav">
            <a href="login" class="nav-link-extra w-nav-link">
              Log in
            </a>
            <div class="block-nav-button">
              <a href="register" class="nav-button w-button">
                Create account
              </a>
              <div class="menu-button w-nav-button">
                <div class="menu-icon w-icon-nav-menu"></div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div data-w-id="c21e9273-07ed-2b45-c62e-0b9527ee6c42" class="section-top">
      <div style={{opacity: 0}} class="content">
        <div class="block-top">
          <div class="block-heading-top">
            <h1 style={{"-webkit-transform":"translate3d(null, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)", "-moz-transform":"translate3d(null, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)","-ms-transform":"translate3d(null, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)","transform":"translate3d(null, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)", opacity: 1}} class="heading-hero">TERMS AND CONDITIONS</h1>
          </div>
        </div>
      </div>
      <div style={{"opacity":"0"}} class="blur-top"></div>
    </div>

    <div className="section">
      <div style={{ opacity: 0 }} className="content">
        <div className="content-narrow template">
          <div className="block-template">
            <h3 className="heading-template">TERMS AND CONDITIONS SWAPBITS</h3>
            <div className="rich-text-block w-richtext">
              <p>
                <strong>Effective Date:</strong> January 5, 2025
                <br />
                <strong>Last Updated:</strong> January 5, 2025
              </p>
              <h3>1. Introduction</h3>
              <p>
                Welcome to Swapbits Financial. These Terms and Conditions govern your access to and use of the services provided through{' '}
                <a target="_new" href="http://www.swapbits.io/">
                  www.swapbits.io
                </a>
                . By using our platform, you agree to comply with these terms.
              </p>
              <h3>2. Company Identity</h3>
              <p>
                <strong>Legal Name:</strong> Swapbits Financial
                <br />
                <strong>Registration Number:</strong> 0001132148
                <br />
                <strong>VASP License Number:</strong> RDWW-1584
                <br />
                <strong>Address:</strong> Bartycka 22B/21A, 00-716 Warsaw, Poland
                <br />
                <strong>Email:</strong>
                <a href="mailto:support@swapbits.io">support@swapbits.io</a>
              </p>
              <h3>3. Definitions</h3>
              <ul>
                <li>
                  <strong>Crypto-tokens:</strong> Digital representations of value based on blockchain technology.
                </li>
                <li>
                  <strong>Platform:</strong> The Swapbits website and mobile applications.
                </li>
                <li>
                  <strong>User:</strong> Any person who uses the services provided by Swapbits.
                </li>
                <li>
                  <strong>Transaction:</strong> Any purchase, sale, or exchange operation conducted on the platform.
                </li>
                <li>
                  <strong>Account:</strong> The User’s personal registration on the platform.
                </li>
              </ul>
              <h3>4. User Registration</h3>
              <ul>
                <li>Users must complete the KYC verification process to activate their accounts.</li>
                <li>Users are responsible for maintaining the confidentiality of their credentials.</li>
                <li>Swapbits reserves the right to suspend accounts that violate these terms or applicable laws.</li>
              </ul>
              <h3>5. Services Provided</h3>
              <ul>
                <li>Buying and selling cryptocurrencies.</li>
                <li>Cryptocurrency exchanges.</li>
                <li>Secure wallets for crypto asset storage.</li>
              </ul>
              <h3>6. Prohibited Activities</h3>
              <ul>
                <li>Participating in illegal activities, such as money laundering or terrorist financing.</li>
                <li>Attempting to hack, manipulate, or fraudulently use the system.</li>
                <li>Using scripts or bots to automate operations.</li>
                <li>Transmitting viruses, spyware, or other malicious software.</li>
                <li>Impersonating another user or engaging in deceptive behavior.</li>
              </ul>
              <h3>7. Service Modifications and Suspension</h3>
              <p>
                Swapbits reserves the right to modify, suspend, or discontinue any service at any time. Updates will be made to improve functionality or comply with regulatory changes.
              </p>
              <h3>8. Limitation of Liability</h3>
              <ul>
                <li>Cryptocurrencies are highly volatile. We are not responsible for any losses arising from market fluctuations.</li>
                <li>We strive to ensure continuous availability of the platform but do not guarantee uninterrupted operation.</li>
                <li>We are not liable for unauthorized access resulting from user negligence.</li>
              </ul>
              <h3>9. Dispute Resolution</h3>
              <p>Disputes will be resolved through arbitration unless otherwise required by local laws. The applicable jurisdiction will be Poland.</p>
              <h3>10. Amendments to the Terms</h3>
              <p>Swapbits reserves the right to update these Terms at any time. Changes will be communicated at least 30 days in advance.</p>
              <h3>11. Service Termination</h3>
              <ul>
                <li>Users may close their accounts at any time, provided all outstanding balances are settled.</li>
                <li>Swapbits may suspend accounts for violations of these terms.</li>
              </ul>
              <h3>12. Contact Information</h3>
              <p>For inquiries, support, or complaints, contact us at:</p>
              <p>
                <strong>Email:</strong>
                <a href="mailto:support@swapbits.io">support@swapbits.io</a>
                <br />
                <strong>Address:</strong> Bartycka 22B/21A, 00-716 Warsaw, Poland
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="section-footer">
      <div className="content">
        <div className="w-layout-grid grid-footer">
          <div id="w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fb3-12d14fb0" className="block-footer">
            <p className="paragraph-footer">Swapbits Financial: Your trusted platform to manage cryptocurrencies safely and efficiently. We are committed to offering an exceptional user experience and complying with the most demanding regulatory standards. <br />VASP LICENCE RDWW-1584</p>
            <h4></h4>
            <a href="contact" className="link-block w-inline-block"><img src="assets/images/icon_6.svg" loading="eager" alt="" className="icon-link" />
              <div className="text-link">Support@swapbits.io</div>
            </a>
          </div>
          <div id="w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fbb-12d14fb0" className="block-footer">
            <div className="subtitle-footer">Quick Links</div>
            <a href="features" className="link-footer">Features</a>
            <a href="blog" className="link-footer">Soon</a>
            <a href="contact" className="link-footer">Contact</a>
          </div>
          <div id="w-node-_92ed4044-9cfe-904b-e616-3634ff09c860-12d14fb0" className="block-footer">
            <div className="subtitle-footer">COMPANY</div>
            <a href="terms" className="link-footer">Terms and conditions</a>
            <a href="privacy-terms" className="link-footer">Privacy terms</a>
          </div>
        </div>
        <div className="block-footer-down">
          <div className="text-footer-down">
            <a href="https://swapbits.io/" target="_blank" className="link-footer-down">© 2025 Swapbits Financial.All rights reserved<br/></a>
          </div>
        </div>
      </div>
    </div>

    </div>
  );
};

export default TermsAndConditions;
