import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import "../styles/WithHome.css";

const DepositOptions = () => {
  const navigate = useNavigate();
  
  const options = [
    {
      title: 'Criptomonedas',
      description: 'Retira tus criptomonedas',
      link: '/dashboard/withdraw/crypto'
    },
    {
      title: 'Usuario de SwapBits',
      description: 'Enviale dinero a otro usuario de swapbits',
      link: '/dashboard/withdraw/internal'
    }
  ];

  return (
    <main className="withdraw-options-main">
      <div className="withdraw-options-header">
        <button className="back-button" onClick={() => navigate('/dashboard/wallet')}>
          <ArrowLeft size={24} />
        </button>
        <h2 className="withdraw-options-title">Enviar</h2>
      </div>
      <div className="withdraw-options-container">
        {options.map((option, index) => (
          <a
            key={index}
            className={`withdraw-option-item option-item-${index}`}
            href={option.link}
          >
            <div className="withdraw-option-content">
              <div className="withdraw-option-header">
                <div className="withdraw-option-icon">
                  <span className="withdraw-option-title">{option.title}</span>
                </div>
                <span className="withdraw-option-description">{option.description}</span>
              </div>
            </div>
          </a>
        ))}
      </div>
    </main>
  );
};

export default DepositOptions;