import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import "../styles/DashboardChange.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import en from "../langs/en-us.json";
import es from "../langs/es-es.json";
import pl from "../langs/pl-pl.json";
import pt from "../langs/pt-br.json";
import zh from "../langs/zh-cn.json";


const languages = {
  en: { name: "English", flag: "/assets/images/flags/us.png", data: en },
  es: { name: "Español", flag: "/assets/images/flags/es.png", data: es },
  pl: { name: "Polski", flag: "/assets/images/flags/pl.png", data: pl },
  pt: { name: "Português", flag: "/assets/images/flags/br.png", data: pt },
  zh: { name: "中文", flag: "/assets/images/flags/cn.png", data: zh },
}
const DashboardChange = () => {
  const [fromCurrency, setFromCurrency] = useState(""); // Moneda inicial
  const [toCurrency, setToCurrency] = useState("USDT"); // Moneda de destino
  const [balances, setBalances] = useState({}); // Almacena los saldos dinámicos
  const [currencies, setCurrencies] = useState([]); // Monedas disponibles dinámicamente
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [isCalculating, setIsCalculating] = useState(false);
  const [error, setError] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("candlestick");
  const [showModal, setShowModal] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(null);
const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lang") || "en");
          const [translations, setTranslations] = useState(languages[selectedLang].data);
          useEffect(() => {
            // Guardar idioma seleccionado en localStorage
            localStorage.setItem("lang", selectedLang);
            setTranslations(languages[selectedLang].data);
          }, [selectedLang]);
  useEffect(() => {
    fetchWalletInfo(); // Obtener los saldos al cargar el componente
  }, []);

  useEffect(() => {
    if (fromCurrency === toCurrency) {
      setError("La moneda de origen debe ser diferente a la de destino.");
      return;
    }
    if (fromAmount && fromCurrency && toCurrency) {
      if (parseFloat(fromAmount) > (balances[fromCurrency] || 0)) {
        setError("La cantidad ingresada excede tu saldo disponible.");
        return;
      }
      setIsCalculating(true);
      setError(null); // Limpiar errores previos
      setToAmount(""); // Limpiar temporalmente el resultado
      fetchExchangeRate(fromCurrency, toCurrency, fromAmount);
    } else {
      setToAmount(""); // Restablecer si no hay entrada
      setError("");
      setIsCalculating(false);
    }
  }, [fromAmount, fromCurrency, toCurrency]);

  const fetchWalletInfo = async () => {
    try {
      const token = localStorage.getItem("jwt");
      if (!token) {
        console.error("Token no encontrado en localStorage.");
        return;
      }

      const response = await fetch(
        "https://www.swapbits.io/api/wallets/info",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      processPortfolio(data);
    } catch (error) {
      console.error("Error fetching wallet info:", error);
    }
  };

  const processPortfolio = (data) => {
    const aggregatedPortfolio = {};

    // Extraer las monedas y saldos desde los balances
    data.balances.forEach((balance) => {
      // if (balance.balance > 0) {
      aggregatedPortfolio[balance.symbol] = parseFloat(balance.balance);
      // }
    });

    setBalances(aggregatedPortfolio);
    setCurrencies(Object.keys(aggregatedPortfolio)); // Configurar monedas dinámicamente

    // Seleccionar la primera moneda disponible como predeterminada
    if (Object.keys(aggregatedPortfolio).length > 0) {
      setFromCurrency(Object.keys(aggregatedPortfolio)[0]);
    }
  };

  const setMaxAmount = () => {
    setFromAmount(balances[fromCurrency]);
  };
  const fetchExchangeRate = async (from, to, amount) => {
    try {
      const response = await fetch(
        `https://www.swapbits.io/api/quotes/exchange-rate?from=${from}&to=${to}`
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      const rate = parseFloat(data.rate);
      if (rate && amount) {
        const result = parseFloat(amount) * rate;
        
        const formattedResult = result > 1
          ? new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result) // 1.514,61
          : new Intl.NumberFormat("es-ES", { minimumFractionDigits: 8, maximumFractionDigits: 8 }).format(result); // 0,00123400
        console.log("Exchange rate:", formattedResult);
        setToAmount(formattedResult);
      }
      
      
      
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      setToAmount("Error al calcular");
    } finally {
      setIsCalculating(false);
    }
  };

  const handleSwitch = () => {
    const tempCurrency = fromCurrency;
    setFromCurrency(toCurrency);
    setToCurrency(tempCurrency);

    const tempAmount = fromAmount;
    setFromAmount(toAmount);
    setToAmount("");
  };

  const handleCancel = () => {
    setIsPreview(false);
  };

  const handleConfirm = () => {
    setIsPreview(false);
    handleConvert();
  };

  const handleConvert = async () => {
    try {
      const token = localStorage.getItem("jwt");
      if (!token) {
        setError("No se encontró el token de autenticación.");
        return;
      }

      const body = {
        of: fromCurrency,
        a: toCurrency,
        amount: parseFloat(fromAmount),
      };

      const response = await fetch(
        "https://www.swapbits.io/api/wallets/convert",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Conversión exitosa:", data);
      alert("Conversión completada con éxito.");
      // Actualizar los balances después de la conversión
      fetchWalletInfo();
    } catch (error) {
      console.error("Error realizando la conversión:", error);
      setError("No se pudo realizar la conversión. Intenta nuevamente.");
    }
  };

  const handlePreview = () => {
    if (fromCurrency === toCurrency) {
      setError("La moneda de origen debe ser diferente a la de destino.");
      return;
    }
    if (!fromAmount || parseFloat(fromAmount) <= 0) {
      setError("La cantidad debe ser mayor a 0.");
      return;
    }
    if (parseFloat(fromAmount) > (balances[fromCurrency] || 0)) {
      setError("La cantidad ingresada excede tu saldo disponible.");
      return;
    }
    setError(null);
    setIsPreview(true);
  };
  const handleClick = () => {
    const sideNavbar = document.querySelector(".side-navbar-wrapper");
    if (sideNavbar) {
      sideNavbar.classList.toggle("active");
    }
  };

  const confirmConversion = async () => {
    try {
      const token = localStorage.getItem("jwt");
      if (!token) {
        setError("No se encontró el token de autenticación.");
        return;
      }

      const body = {
        of: fromCurrency,
        a: toCurrency,
        amount: parseFloat(fromAmount),
      };

      const response = await fetch(
        "https://www.swapbits.io/api/wallets/convert",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Conversión exitosa:", data);
      alert("Conversión completada con éxito.");
      fetchWalletInfo();
      setIsPreview(false);
    } catch (error) {
      console.error("Error realizando la conversión:", error);
      setError("No se pudo realizar la conversión. Intenta nuevamente.");
    }
  };

  const [cryptoData, setCryptoData] = useState(null);
  const [orderBook, setOrderBook] = useState(null);

  useEffect(() => {
    const fetchCryptoData = async () => {
      try {
        const response = await fetch(
          "https://www.swapbits.io/api/quotes/crypto?symbol=BTC"
        );
        const data = await response.json();
        console.log("Data:", data);
        setCryptoData(data);
      } catch (error) {
        console.error("Error al obtener datos de CoinMarketCap:", error);
      }
    };

    fetchCryptoData();

    // Refrescar cada 30 segundos para mantener datos actualizados
    const interval = setInterval(fetchCryptoData, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchOrderBook = async () => {
      try {
        const response = await fetch(
          `https://www.swapbits.io/api/quotes/order-book?symbol=BTC&limit=3`
        );
        const data = await response.json();
        console.log("Order Book:", data);
        setOrderBook(data);
      } catch (error) {
        console.error("Error fetching order book:", error);
      }
    };

    fetchOrderBook();
    const interval = setInterval(fetchOrderBook, 3000); // Actualiza cada 3s
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await fetch(
          `https://api.binance.com/api/v3/klines?symbol=BTCUSDT&interval=30m&limit=50`
        );
        const data = await response.json();

        const formattedData = data
          .filter((candle) => candle.length >= 5) // Filtra datos inválidos
          .map((candle) => ({
            x: new Date(candle[0]),
            y: [
              parseFloat(candle[1]), // Open
              parseFloat(candle[2]), // High
              parseFloat(candle[3]), // Low
              parseFloat(candle[4]), // Close
            ],
            close: parseFloat(candle[4]), // Para el Line Chart
          }));

        setChartData(formattedData);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };

    fetchChartData();
    const interval = setInterval(fetchChartData, 60000); // Actualiza cada 1 min
    return () => clearInterval(interval);
  }, []);

  const options = {
    chart: { type: chartType, background: "transparent" }, // Fondo transparente
    xaxis: { type: "datetime", labels: { style: { colors: "#fff" } } }, // Fechas en blanco
    yaxis: { labels: { style: { colors: "#fff" } } },
    tooltip: { theme: "dark" }, // Tooltips en modo oscuro
    stroke: { curve: "smooth", width: 2, colors: ["#00E396"] }, // Línea verde
  };

  const swapCurrencies = () => {
    setFromCurrency(toCurrency);
    setToCurrency(fromCurrency);
    setFromAmount("");
    setToAmount("");
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="topbar-wrapper">
          <div className="header-content">
            <div className="header-toggle-wrapper">
              <div className="header-toggle" onClick={handleClick}>
                <img
                  src="../assets/images/Toggle.png"
                  loading="lazy"
                  alt=""
                  className="header-toggle-img"
                ></img>
              </div>
              <div className="header-title-wrapper">
                <h1 className="header-title">{translations.exchange}</h1>
              </div>
            </div>
            <div className="language-selector">
          <select
            className="language-dropdown"
            value={selectedLang}
            onChange={(e) => setSelectedLang(e.target.value)}
          >
            {Object.entries(languages).map(([key, lang]) => (
              <option key={key} value={key}>
                {lang.name}
              </option>
            ))}
          </select>
          <img
            src={languages[selectedLang].flag}
            alt={languages[selectedLang].name}
            className="language-flag"
          />
        </div>
          </div>
        </div>

        <div className="sections-wrapper">
          <div className="exchange-wrapper">
            <div className="container">
              <div className="exchange-sngel-wrapper">
                {console.log("CryptoData:", cryptoData)}
                {cryptoData !== null ? (
                  <div className="w-layout-grid exchange-card-grid">
                    {/* BTC/USDT */}
                    <div className="exchange-card-singel-wrapper">
                      <div className="exchange-card-image-text-wrapper">
                        <img
                          src="../images/Exchange-Card-Image_1Exchange-Card-Image.png"
                          alt=""
                          className="exchange-card-image"
                        />
                        <div className="exchange-card-text-wrapper">
                          <h2 className="exchange-card-name">BTC/USDT</h2>
                          <div className="exchange-card-info">Bitcoin</div>
                        </div>
                      </div>
                      <div className="exchange-card-boder" />
                    </div>

                    {/* 24h Change (Cambio en USDT) */}
                    <div className="exchange-card-singel-wrapper">
                      <div className="exchange-card-text-wrapper">
                        <h2 className="exchange-card-name">{translations["24h_change"]}</h2>
                        <div className="exchange-card-info">
                          {cryptoData.percent_change_24h
                            ? parseFloat(cryptoData.percent_change_24h).toFixed(
                                2
                              )
                            : "-"}{" "}
                          USDT
                        </div>
                      </div>
                      <div className="exchange-card-boder" />
                    </div>

                    {/* Last Price (Último Precio) */}
                    <div className="exchange-card-singel-wrapper">
                      <div className="exchange-card-text-wrapper">
                        <h2 className="exchange-card-name">{translations.last_price}</h2>
                        <div className="exchange-card-info">
                          {cryptoData.last_price
                            ? parseFloat(cryptoData.last_price).toFixed(2)
                            : "-"}{" "}
                          USDT
                        </div>
                      </div>
                      <div className="exchange-card-boder" />
                    </div>

                    {/* 24h High (% de aumento) */}
                    <div className="exchange-card-singel-wrapper">
                      <div className="card-height-wrapper">
                        <div className="exchange-card-text-wrapper">
                          <h2 className="exchange-card-name">{translations["24h_high"]}</h2>
                          <div className="exchange-card-detais">
                            <img
                              src="images/Exchange-Card-Info-Img_1Exchange-Card-Info-Img.png"
                              alt=""
                              className="exchange-card-info-img"
                            />
                            <div className="exchange-card-info high">
                              {cryptoData.high_24h
                                ? parseFloat(cryptoData.high_24h).toFixed(2)
                                : "-"}
                              %
                            </div>
                          </div>
                        </div>
                        <div className="exchange-card-height-img-wrap">
                          <img
                            src="images/Exchange-Card-Height-Img_1Exchange-Card-Height-Img.png"
                            alt=""
                            className="exchange-card-height-img"
                          />
                        </div>
                      </div>
                      <div className="exchange-card-boder" />
                    </div>

                    {/* 24h Low (% de caída) */}
                    <div className="exchange-card-singel-wrapper">
                      <div className="card-height-wrapper">
                        <div className="exchange-card-text-wrapper">
                          <h2 className="exchange-card-name">{translations["24h_low"]}</h2>
                          <div className="exchange-card-detais">
                            <img
                              src="images/Exchange-Card-Info-Img2_1Exchange-Card-Info-Img2.png"
                              alt=""
                              className="exchange-card-info-img"
                            />
                            <div className="exchange-card-info low">
                              {cryptoData.low_24h
                                ? parseFloat(cryptoData.low_24h).toFixed(2)
                                : "-"}
                              %
                            </div>
                          </div>
                        </div>
                        <div className="exchange-card-height-img-wrap">
                          <img
                            src="images/Exchange-Card-Height-Img-2_1Exchange-Card-Height-Img-2.png"
                            alt=""
                            className="exchange-card-height-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>{translations.loading}</div>
                )}
              </div>

              {/* Order Book */}
              <div className="order-book-chrat-wrapper">
                {orderBook !== null ? (
                  //  <div className="order-book-wrapper">
                  //    <div className="order-book-title-wrap">
                  //      <h2 className="dashboard-chart-title">Order Book</h2>
                  //    </div>
                  //    <div className="order-book-header">
                  //      <div className="w-layout-grid order-book-header-grid">
                  //        <div className="order-book-header-title-wrapper">
                  //          <div className="order-book-header-title">Price (USDT)</div>
                  //        </div>
                  //        <div className="order-book-header-title-wrapper">
                  //          <div className="order-book-header-title">Amount (USDT)</div>
                  //        </div>
                  //        <div className="order-book-header-title-wrapper">
                  //          <div className="order-book-header-title">Total (USDT)</div>
                  //        </div>
                  //      </div>
                  //    </div>

                  //    {/* ASKS (Vendedores) */}
                  //    <div className="order-book-content">
                  //      {orderBook.asks.map((order, index) => (
                  //        <div key={index} className="order-book-singel-wrapper">
                  //          <div className="w-layout-grid order-book-grid">
                  //            <div className="order-book-singel">
                  //              <div className="order-book-text-block red">
                  //                {order.price.toFixed(2)}
                  //              </div>
                  //            </div>
                  //            <div className="order-book-singel">
                  //              <div className="order-book-text-block">{order.amount.toFixed(5)}</div>
                  //            </div>
                  //            <div className="order-book-singel">
                  //              <div className="order-book-text-block">{order.total.toFixed(2)}</div>
                  //            </div>
                  //          </div>
                  //        </div>
                  //      ))}
                  //    </div>

                  //    <div className="order-book-title-wrap">Spread</div>

                  //    {/* BIDS (Compradores) */}
                  //    <div className="order-book-content">
                  //      {orderBook.bids.map((order, index) => (
                  //        <div key={index} className="order-book-singel-wrapper">
                  //          <div className="w-layout-grid order-book-grid">
                  //            <div className="order-book-singel">
                  //              <div className="order-book-text-block green">
                  //                {order.price.toFixed(2)}
                  //              </div>
                  //            </div>
                  //            <div className="order-book-singel">
                  //              <div className="order-book-text-block">{order.amount.toFixed(5)}</div>
                  //            </div>
                  //            <div className="order-book-singel">
                  //              <div className="order-book-text-block">{order.total.toFixed(2)}</div>
                  //            </div>
                  //          </div>
                  //        </div>
                  //      ))}
                  //    </div>

                  //  </div>
                  <div className="marketplace-market-trades-wrap">
                    <div className="marketplace-wrapper">
                      <div className="dashboard-chart-title-wrap">
                        <h2 className="dashboard-chart-title">
                          {translations.coin_exchange}
                        </h2>
                        <div className="marketplace-buttons">
                          <button className="market-button active">
                            {translations.market}
                          </button>
                          <button className="limit-button" disabled>
                            {translations.limit}
                          </button>
                        </div>
                      </div>
                      <div className="marketplace-tab-content">
                        <div className="marketplace-tab-pane">
                          <div className="marketplace-tab-single-wrapper">
                            <div>
                              <div className="currency-input">
                                {/* <label htmlFor="fromCurrency">De:</label> */}
                                <select
                                  id="fromCurrency"
                                  value={fromCurrency}
                                  onChange={(e) =>
                                    setFromCurrency(e.target.value)
                                  }
                                >
                                  {currencies.map((currency) => (
                                    <option key={currency} value={currency}>
                                      {currency}
                                    </option>
                                  ))}
                                </select>
                                <div className="input-wrapper">
                                  <input
                                    type="text"
                                    id="fromAmount"
                                    placeholder={translations.quantity}
                                    value={fromAmount}
                                    onChange={(e) =>
                                      setFromAmount(e.target.value)
                                    }
                                  />
                                  <p
                                    className="max-text"
                                    onClick={setMaxAmount}
                                  >
                                    {translations.max}
                                  </p>
                                </div>
                              </div>
                              <p>
  {translations.available}:{" "}
  {balances[fromCurrency]
    ? new Intl.NumberFormat("es-ES", {
        minimumFractionDigits: balances[fromCurrency] > 1 ? 2 : 8,
        maximumFractionDigits: balances[fromCurrency] > 1 ? 2 : 8,
      }).format(balances[fromCurrency])
    : "0,00"}{" "}
  {fromCurrency}
</p>
                            </div>

                            <button
                              onClick={swapCurrencies}
                              className="swap-button"
                            >
                              <FontAwesomeIcon icon={faExchangeAlt} />
                            </button>
                            <div className="currency-input">
                              <select
                                id="toCurrency"
                                value={toCurrency}
                                onChange={(e) => setToCurrency(e.target.value)}
                              >
                                {currencies.map((currency) => (
                                  <option key={currency} value={currency}>
                                    {currency}
                                  </option>
                                ))}
                              </select>

                              <input
                                type="text"
                                id="toAmount"
                                placeholder={translations.total}
                                value={toAmount}
                                readOnly
                              />
                            </div>
                            <button
                              onClick={handlePreview}
                              className="convert-button"
                            >
                              {translations.convert}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isPreview && (
                      <div className="custom-modal">
                        <div className="custom-modal-content">
                          <h3 className="custom-modal-title">
                            {translations.confirm_conversion}
                          </h3>
                          <p className="custom-modal-text">
                            {translations.from}: {fromAmount} {fromCurrency}
                          </p>
                          <p className="custom-modal-text">
                            {translations.to}: {toAmount} {toCurrency}
                          </p>
                          <button
                            onClick={confirmConversion}
                            className="custom-confirm-button"
                          >
                            <FontAwesomeIcon icon={faCheck} /> {translations.confirm}
                          </button>
                          <button
                            onClick={() => setIsPreview(false)}
                            className="custom-cancel-button"
                          >
                            <FontAwesomeIcon icon={faTimes} /> {translations.cancel}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>{translations.loading}</div>
                )}

                <div className="exchange-chart-wrapper">
                  {/* Botones para cambiar entre gráficos */}
                  <div className="exchange-chart-tab-menu">
                    <button
                      className={`exchange-chart-tab-link ${
                        chartType === "line" ? "active" : ""
                      }`}
                      onClick={() => setChartType("line")}
                    >
                      {translations.line_chart}
                    </button>
                    <button
                      className={`exchange-chart-tab-link ${
                        chartType === "candlestick" ? "active" : ""
                      }`}
                      onClick={() => setChartType("candlestick")}
                    >
                      {translations.candlestick_chart}
                    </button>
                  </div>

                  {/* Renderiza el gráfico correctamente */}
                  <Chart
                    options={options}
                    series={[
                      {
                        data:
                          chartType === "line"
                            ? chartData.map((d) => ({ x: d.x, y: d.close }))
                            : chartData,
                      },
                    ]}
                    type={chartType}
                    height={350}
                  />
                </div>
              </div>

              {/* Order Book */}
              {/* <div className="marketplace-market-trades-wrap">
            <div className="marketplace-wrapper">
              <div className="dashboard-chart-title-wrap">
                <h2 className="dashboard-chart-title">Marketplace</h2>
              </div>
              <div className="marketplace-tab-wrapper">
                <div
                  data-current="Tab 1"
                  data-easing="ease"
                  data-duration-in={300}
                  data-duration-out={100}
                  className="marketplace-tabs w-tabs"
                >
                  <div className="marketplace-tab-menu-wrapper w-tab-menu">
                    <a
                      data-w-tab="Tab 1"
                      className="marketplace-tab-link w-inline-block w-tab-link w--current"
                    >
                      <div>Buy</div>
                    </a>
                    <a
                      data-w-tab="Tab 2"
                      className="marketplace-tab-link ml15 w-inline-block w-tab-link"
                    >
                      <div>Sell</div>
                    </a>
                  </div>
                  <div className="marketplace-tab-content w-tab-content">
                    <div
                      data-w-tab="Tab 1"
                      className="marketplace-tab-pane w-tab-pane w--tab-active"
                    >
                      <div className="marketplace-tab-singel-wrapper">
                        <div className="marketplace-tab-seclect-wrapper">
                          <div className="marketplace-text-wrapper">
                            <div className="marketplace-text-block">
                              Order Type
                            </div>
                          </div>
                          <div className="marketplace-form-block w-form">
                            <form
                              id="email-form"
                              name="email-form"
                              data-name="Email Form"
                              method="get"
                              className="marketplace-form"
                              data-wf-page-id="6796f465ce700a674ce57e21"
                              data-wf-element-id="6b897629-7038-f01c-1df1-f341bc7419de"
                            >
                              <select
                                id="field"
                                name="field"
                                data-name="Field"
                                className="marketplace-select w-select"
                              >
                                <option value="">Stop Limit</option>
                                <option value="First">First choice</option>
                                <option value="Second">Second choice</option>
                                <option value="Third">Third choice</option>
                              </select>
                            </form>
                            <div className="w-form-done">
                              <div>
                                Thank you! Your submission has been received!
                              </div>
                            </div>
                            <div className="w-form-fail">
                              <div>
                                Oops! Something went wrong while submitting the
                                form.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="marketplace-tab-value">
                          <div className="marketplace-prices">
                            <div className="marketplace-price">Limit Price</div>
                          </div>
                          <div className="dollar-wrapper one">
                            <div>USDT</div>
                          </div>
                        </div>
                        <div className="marketplace-tab-value">
                          <div className="marketplace-prices">
                            <div className="marketplace-price">Limit Price</div>
                          </div>
                          <div className="dollar-wrapper two">
                            <div>USDT</div>
                          </div>
                        </div>
                        <div className="marketplace-tab-value">
                          <div className="marketplace-prices">
                            <div className="marketplace-price">Limit Price</div>
                          </div>
                          <div className="dollar-wrapper one">
                            <div>USDT</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-w-tab="Tab 2"
                      className="marketplace-tab-pane w-tab-pane"
                    >
                      <div className="marketplace-tab-singel-wrapper">
                        <div className="marketplace-tab-seclect-wrapper">
                          <div className="marketplace-text-wrapper">
                            <div className="marketplace-text-block">
                              Order Type
                            </div>
                          </div>
                          <div className="marketplace-form-block w-form">
                            <form
                              id="email-form"
                              name="email-form"
                              data-name="Email Form"
                              method="get"
                              className="marketplace-form"
                              data-wf-page-id="6796f465ce700a674ce57e21"
                              data-wf-element-id="66df20c0-d14f-2bfb-9a71-98624aa9b250"
                            >
                              <select
                                id="field-2"
                                name="field-2"
                                data-name="Field 2"
                                className="marketplace-select w-select"
                              >
                                <option value="">Stop Limit</option>
                                <option value="First">First choice</option>
                                <option value="Second">Second choice</option>
                                <option value="Third">Third choice</option>
                              </select>
                            </form>
                            <div className="w-form-done">
                              <div>
                                Thank you! Your submission has been received!
                              </div>
                            </div>
                            <div className="w-form-fail">
                              <div>
                                Oops! Something went wrong while submitting the
                                form.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="marketplace-tab-value">
                          <div className="marketplace-prices">
                            <div className="marketplace-price">Amount</div>
                          </div>
                          <div className="dollar-wrapper two">
                            <div>BTC</div>
                          </div>
                        </div>
                        <div className="marketplace-tab-value">
                          <div className="marketplace-prices">
                            <div className="marketplace-price">Limit Price</div>
                          </div>
                          <div className="dollar-wrapper one">
                            <div>USDT</div>
                          </div>
                        </div>
                        <div className="marketplace-tab-value">
                          <div className="marketplace-prices">
                            <div className="marketplace-price">Limit Price</div>
                          </div>
                          <div className="dollar-wrapper two">
                            <div>USDT</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="market-trades">
              <div className="market-trades-tab-wrapper">
                <div className="market-trades-title-wrapper">
                  <div className="dashboard-chart-title-wrap">
                    <h2 className="dashboard-chart-title">Market Trades</h2>
                  </div>
                </div>
                <div
                  data-current="Tab 1"
                  data-easing="ease"
                  data-duration-in={300}
                  data-duration-out={100}
                  className="market-trades-tab w-tabs"
                >
                  <div className="market-trades-tab-menu w-tab-menu">
                    <a
                      data-w-tab="Tab 1"
                      className="market-trades-tab-link w-inline-block w-tab-link w--current"
                    >
                      <div>Price Chart</div>
                    </a>
                    <a
                      data-w-tab="Tab 2"
                      className="market-trades-tab-link w-inline-block w-tab-link"
                    >
                      <div>Deep Chart</div>
                    </a>
                  </div>
                  <div className="market-trades-tab-content w-tab-content">
                    <div
                      data-w-tab="Tab 1"
                      className="market-trades-tab-pane w-tab-pane w--tab-active"
                    >
                      <div className="market-table-wrapper">
                        <div className="market-table-top-content">
                          <div className="market-table-heading-wrapper">
                            <div className="w-layout-grid market-table-grid">
                              <div
                                id="w-node-b163d64a-2474-4c0e-294a-303274a8f631-4ce57e21"
                                className="market-table-title-wrapper"
                              >
                                <h1 className="market-table-heading">Time</h1>
                              </div>
                              <div
                                id="w-node-a3c4a50f-da17-ec4f-dbb1-f55a6a442fc0-4ce57e21"
                                className="market-table-title-wrapper"
                              >
                                <h1 className="market-table-heading">
                                  Price (USDT)
                                </h1>
                              </div>
                              <div
                                id="w-node-_85c61e77-9c1e-307a-3e88-c927414e1701-4ce57e21"
                                className="market-table-title-wrapper"
                              >
                                <h1 className="market-table-heading">
                                  Amount (BTC)
                                </h1>
                              </div>
                              <div
                                id="w-node-dfec7d25-211a-b480-2c81-386a3f6ae9d6-4ce57e21"
                                className="market-table-title-wrapper"
                              >
                                <h1 className="market-table-heading">
                                  Total (USDT)
                                </h1>
                              </div>
                            </div>
                          </div>
                          <div className="market-table-content">
                            <div className="w-layout-grid market-table-grid">
                              <div
                                id="w-node-_27720d88-72bf-67f6-5d06-a6af557c01e5-4ce57e21"
                                className="market-table-text-block-wrapper"
                              >
                                <div className="market-table-text-block">
                                  18:02:12
                                </div>
                              </div>
                              <div
                                id="w-node-_326738e7-ec44-98d0-5112-a90d6ee93785-4ce57e21"
                                className="market-table-text-block-wrapper"
                              >
                                <div className="market-table-text-block">
                                  18432.3204898
                                </div>
                              </div>
                              <div
                                id="w-node-_6903483e-0010-ffcc-f606-6b573f749407-4ce57e21"
                                className="market-table-text-block-wrapper"
                              >
                                <div className="market-table-text-block">
                                  18446.62230935
                                </div>
                              </div>
                              <div
                                id="w-node-fb9d5c34-037c-b01b-ddc5-0f47fe5bf0c9-4ce57e21"
                                className="market-table-text-block-wrapper"
                              >
                                <div className="market-table-text-block">
                                  18446.62230935
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="market-table-bottom-content">
                          <div className="market-table">
                            <div className="market-table-content">
                              <div className="w-layout-grid market-table-grid">
                                <div
                                  id="w-node-_8d78247f-a59c-9ddf-f3b0-f8b67ee08dc5-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18:02:12
                                  </div>
                                </div>
                                <div
                                  id="w-node-_8d78247f-a59c-9ddf-f3b0-f8b67ee08dc8-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18432.3204898
                                  </div>
                                </div>
                                <div
                                  id="w-node-_8d78247f-a59c-9ddf-f3b0-f8b67ee08dcb-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                                <div
                                  id="w-node-_8d78247f-a59c-9ddf-f3b0-f8b67ee08dce-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="market-table">
                            <div className="market-table-content">
                              <div className="w-layout-grid market-table-grid">
                                <div
                                  id="w-node-e16cd58e-8c4a-b716-7057-f985b1d80b61-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18:02:12
                                  </div>
                                </div>
                                <div
                                  id="w-node-e16cd58e-8c4a-b716-7057-f985b1d80b64-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18432.3204898
                                  </div>
                                </div>
                                <div
                                  id="w-node-e16cd58e-8c4a-b716-7057-f985b1d80b67-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                                <div
                                  id="w-node-e16cd58e-8c4a-b716-7057-f985b1d80b6a-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="market-table">
                            <div className="market-table-content">
                              <div className="w-layout-grid market-table-grid">
                                <div
                                  id="w-node-_2b810449-4f51-678c-9857-1ed3b25e8ca1-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18:02:12
                                  </div>
                                </div>
                                <div
                                  id="w-node-_2b810449-4f51-678c-9857-1ed3b25e8ca4-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18432.3204898
                                  </div>
                                </div>
                                <div
                                  id="w-node-_2b810449-4f51-678c-9857-1ed3b25e8ca7-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                                <div
                                  id="w-node-_2b810449-4f51-678c-9857-1ed3b25e8caa-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="market-table pd0">
                            <div className="market-table-content">
                              <div className="w-layout-grid market-table-grid">
                                <div
                                  id="w-node-c8de327e-d4c9-8d65-1566-8af247838a08-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18:02:12
                                  </div>
                                </div>
                                <div
                                  id="w-node-c8de327e-d4c9-8d65-1566-8af247838a0b-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18432.3204898
                                  </div>
                                </div>
                                <div
                                  id="w-node-c8de327e-d4c9-8d65-1566-8af247838a0e-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                                <div
                                  id="w-node-c8de327e-d4c9-8d65-1566-8af247838a11-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-w-tab="Tab 2"
                      className="market-trades-tab-pane w-tab-pane"
                    >
                      <div className="market-table-wrapper">
                        <div className="market-table-top-content">
                          <div className="market-table-heading-wrapper">
                            <div className="w-layout-grid market-table-grid">
                              <div
                                id="w-node-_85659838-4dee-56a2-881b-ba398dae7cfc-4ce57e21"
                                className="market-table-title-wrapper"
                              >
                                <h1 className="market-table-heading">Time</h1>
                              </div>
                              <div
                                id="w-node-_85659838-4dee-56a2-881b-ba398dae7cff-4ce57e21"
                                className="market-table-title-wrapper"
                              >
                                <h1 className="market-table-heading">
                                  Price (USDT)
                                </h1>
                              </div>
                              <div
                                id="w-node-_85659838-4dee-56a2-881b-ba398dae7d02-4ce57e21"
                                className="market-table-title-wrapper"
                              >
                                <h1 className="market-table-heading">
                                  Low Price
                                </h1>
                              </div>
                              <div
                                id="w-node-_85659838-4dee-56a2-881b-ba398dae7d05-4ce57e21"
                                className="market-table-title-wrapper"
                              >
                                <h1 className="market-table-heading">
                                  Central
                                </h1>
                              </div>
                            </div>
                          </div>
                          <div className="market-table-content">
                            <div className="w-layout-grid market-table-grid">
                              <div
                                id="w-node-_85659838-4dee-56a2-881b-ba398dae7d0a-4ce57e21"
                                className="market-table-text-block-wrapper"
                              >
                                <div className="market-table-text-block">
                                  18:02:12
                                </div>
                              </div>
                              <div
                                id="w-node-_85659838-4dee-56a2-881b-ba398dae7d0d-4ce57e21"
                                className="market-table-text-block-wrapper"
                              >
                                <div className="market-table-text-block">
                                  18432.3204898
                                </div>
                              </div>
                              <div
                                id="w-node-_85659838-4dee-56a2-881b-ba398dae7d10-4ce57e21"
                                className="market-table-text-block-wrapper"
                              >
                                <div className="market-table-text-block">
                                  18446.62230935
                                </div>
                              </div>
                              <div
                                id="w-node-_85659838-4dee-56a2-881b-ba398dae7d13-4ce57e21"
                                className="market-table-text-block-wrapper"
                              >
                                <div className="market-table-text-block">
                                  18446.62230935
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="market-table-bottom-content">
                          <div className="market-table">
                            <div className="market-table-content">
                              <div className="w-layout-grid market-table-grid">
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d1a-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18:02:12
                                  </div>
                                </div>
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d1d-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18432.3204898
                                  </div>
                                </div>
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d20-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d23-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="market-table">
                            <div className="market-table-content">
                              <div className="w-layout-grid market-table-grid">
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d29-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18:02:12
                                  </div>
                                </div>
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d2c-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18432.3204898
                                  </div>
                                </div>
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d2f-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d32-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="market-table">
                            <div className="market-table-content">
                              <div className="w-layout-grid market-table-grid">
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d38-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18:02:12
                                  </div>
                                </div>
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d3b-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18432.3204898
                                  </div>
                                </div>
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d3e-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d41-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="market-table pd0">
                            <div className="market-table-content">
                              <div className="w-layout-grid market-table-grid">
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d47-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18:02:12
                                  </div>
                                </div>
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d4a-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18432.3204898
                                  </div>
                                </div>
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d4d-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                                <div
                                  id="w-node-_85659838-4dee-56a2-881b-ba398dae7d50-4ce57e21"
                                  className="market-table-text-block-wrapper"
                                >
                                  <div className="market-table-text-block">
                                    18446.62230935
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            </div>
          </div>
          <div className="footer">
            <div className="container">
              <div className="footer-details-wrapper">
                <div className="footer-text-block">
                  <a
                    href="http://webflow.com"
                    target="_blank"
                    className="footer-text-link"
                  >
                    <strong>
                      © 2025 Swapbits Financial.All rights reserved
                    </strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-w-id="ad850566-76af-f3eb-5277-07c1948f8ecc"
        className="preloader"
      >
        <img
          src="images/Sidebar-Logo_1Sidebar-Logo.png"
          loading="lazy"
          data-w-id="ad850566-76af-f3eb-5277-07c1948f8ecd"
          alt=""
          className="preloader-image"
        />
      </div>
    </>
  );
};

export default DashboardChange;
