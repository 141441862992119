import React from 'react';
import Header from './Header';
import Champaign from './Champaign';

function App() {
  return (
    <>
      <Header />
      <Champaign />
    </>
  );
}

export default App;
