import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
// import '../styles/HomePage.css';

const HomePage = () => {
    const navigate = useNavigate();
    const carouselRef = useRef(null);
    const animationFrameIdRef = useRef(null); // Referencia para cancelar la animación
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        document.title = "Inicio - Swapbits";

        const carousel = carouselRef.current;

        // Duplicar elementos dinámicamente para el bucle
        // const items = carousel.children;
        // const totalItems = items.length;

        // for (let i = 0; i < totalItems; i++) {
        //     const clone = items[i].cloneNode(true);
        //     carousel.appendChild(clone);
        // }

        // const startCarousel = () => {
        //     const moveCarousel = () => {
        //         if (!isPaused && carousel) {
        //             carousel.scrollLeft += 1; // Desplazamiento suave

        //             // Comprobar si estamos al final de los elementos originales
        //             if (carousel.scrollLeft >= carousel.scrollWidth / 2) {
        //                 carousel.scrollLeft = 0; // Volver al inicio de forma continua
        //             }
        //             animationFrameIdRef.current = requestAnimationFrame(moveCarousel);
        //         }
        //     };
        //     moveCarousel();
        // };

        // startCarousel();

        return () => {
            // Cancelar la animación al desmontar el componente
            cancelAnimationFrame(animationFrameIdRef.current);
        };
    }, [isPaused]);

    
    
   
      
    useEffect(() => {
        if (window.Webflow) {
          window.Webflow.destroy(); // Destruye instancias previas
          window.Webflow.ready(); // Reactiva Webflow
          window.Webflow.require("ix2").init(); // Reactiva interacciones (si aplica)
        }
      }, []);

      useEffect(() => {
        const elements = document.querySelectorAll(".overflow .content, .overflow .blur-top, .overflow .heading-hero, .overflow .paragraph-hero, .overflow .button");
    
        elements.forEach((el) => {
            if (window.getComputedStyle(el).opacity === "0") {
                let currentOpacity = 0;
                const opacityInterval = setInterval(() => {
                    if (currentOpacity < 1) {
                        currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incremento gradual
                        el.style.opacity = currentOpacity.toFixed(2);
                    } else {
                        clearInterval(opacityInterval);
                    }
                }, 10);
            }
        });
    }, []);
    
    

             
    return (
        <>
            <div data-animation="default" data-collapse="medium" data-duration="600" data-easing="ease-out-expo" data-easing2="ease-out-expo" role="banner" className="navbar w-nav">
    <div className="nav-block">
      <div className="nav">
        <nav role="navigation" className="nav-menu w-nav-menu">
          <a href="features" className="nav-link w-nav-link">Features<br/></a>
          <a href="blog" className="nav-link w-nav-link">Soon</a>
          <a href="contact" className="nav-link w-nav-link">Contact</a>
        </nav>
      </div>
      <div className="nav">
        <a href="login" className="nav-link-extra w-nav-link">Log in</a>
        <div className="block-nav-button">
          <a href="register" className="nav-button w-button">Create account</a>
          <div className="menu-button w-nav-button">
            <div className="menu-icon w-icon-nav-menu"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="overflow">
    <div data-w-id="7a156164-9dec-ac90-a568-7aabe3bd2ab3" className="section-hero">
      <div style={{opacity:0}} className="content">
        <div className="w-layout-grid grid-hero">
          <div className="block-hero">
            <h1 className="heading-hero large">Experience The Power of Blockchain</h1>
            <p className="paragraph-hero max-w">Start trading Bitcoin, Ethereum and more safely today, with the confidence of being regulated.</p>
            <a
  style={{
    WebkitTransform: "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MozTransform: "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    msTransform: "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    transform: "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    opacity: 0,
  }}
  href="register"
  className="button w-button"
>
  Create account
</a>

          </div>


          <div id="w-node-_3faee776-541d-8a2f-1798-8c606d3a3e51-c53bab0c" data-w-id="3faee776-541d-8a2f-1798-8c606d3a3e51" className="wrapper-circle">
            <div className="content-wrapper-circle">
              <div className="circle-hero">
                <div data-w-id="b7256e00-eab5-70db-eaca-4548a2a5b956" className="area-a1">
                  <div className="color-circle"></div>
                </div>
                <div data-w-id="d03d882b-d932-e901-12e0-1a25ba9e83b1" className="area-a2">
                  <div className="color-circle"></div>
                </div>
                <div data-w-id="dc5e63ff-dd31-1f91-0b3e-5fac54383022" className="area-a3">
                  <div className="color-circle"></div>
                </div>
                <div data-w-id="927fb4f0-9b18-b5bb-f00a-9e3e37ec61a0" className="area-a4">
                  <div className="color-circle"></div>
                </div>
                <div data-w-id="5b79ffc3-319f-e4d7-ce6e-7e3cf8b5a514" className="area-a5">
                  <div className="color-circle"></div>
                </div>
                <div data-w-id="3346f3a3-387b-e5a0-3573-e04b3d8eccda" className="area-main-a6">
                  <div className="color-circle line"></div>
                </div>
                <div data-w-id="409b1e90-116f-54b0-95fb-f6ecb3bbf760" className="area-a7">
                  <div className="color-circle"></div>
                </div>
                <div data-w-id="d1abea59-3332-93f0-973e-b8b97ab954bf" className="area-a8">
                  <div className="color-circle"></div>
                </div>
                <div data-w-id="b0976099-f507-6107-d4c8-0d2f76c5e0ed" className="area-a9">
                  <div className="color-circle"></div>
                </div>
                <div data-w-id="728802df-d86e-cb4f-3e20-bb548d63a4d8" className="area-a10">
                  <div className="color-circle"></div>
                </div>
              </div>
            </div>
            <div style={{"opacity": "0"}} className="blur-hero"></div>
          </div>
        </div>
      </div>
      <div style={{"opacity":"0"}} className="blur-top"></div>
    </div>

    <div className="section" >
      <div className="content">

        <div className="block-heading">
          <div className="subtitle">Features</div>
          <h2 className="heading">Discover Swapbits Key Features</h2>
        </div>

        <div className="w-layout-grid grid-features">
          <div data-w-id="cf279c53-10d5-47c0-1f6e-84c9b074e328" className="wrapper">
            <div className="blurred-color"></div>
            <div className="content-wrapper">
              <div className="feature"><img className="image-feature" src="assets/images/img1.png" alt="" sizes="(max-width: 479px) 88vw, (max-width: 767px) 93vw, 94vw" data-w-id="fa5d5a88-78b4-779c-a5f3-45286a0afd9b" loading="eager" srcSet="assets/images/img1-p-500.png 500w, assets/images/img1.png 885w" />
                <div className="block-feature">
                  <h4 className="heading-feature">Regulated Exchange</h4>
                  <p className="paragraph-feature">Our VASP license ensures safe and regulated operations at all times</p>
                </div>
              </div>
            </div>
          </div>
          <div data-w-id="a9e50413-39fb-6e71-0b9c-f8730cb656a1" className="wrapper">
            <div className="blurred-color"></div>
            <div className="content-wrapper">
              <div className="feature">
                <div className="block-feature top">
                  <h4 className="heading-feature">BaaS-Ready</h4>
                  <p className="paragraph-feature">Coming soon: multi-currency accounts, IBAN, and advanced banking services</p>
                </div><img className="image-feature" src="assets/images/img2.png" alt="" sizes="(max-width: 479px) 88vw, (max-width: 767px) 93vw, 94vw" data-w-id="a9e50413-39fb-6e71-0b9c-f8730cb656a5" loading="eager" srcSet="assets/images/img2-p-500.png 500w, assets/images/img2.png 885w" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-layout-grid grid-features-small">
          <div id="w-node-_2be3a212-21cf-2c2a-b69e-5b5c8cc4a2de-c53bab0c" data-w-id="2be3a212-21cf-2c2a-b69e-5b5c8cc4a2de" className="wrapper">
            <div  className="blurred-color"></div>
            <div className="content-wrapper">
              <div className="feature"><img className="image-feature" src="assets/images/img3.png" alt="" style={{"opacity":"0"}} sizes="(max-width: 479px) 88vw, (max-width: 767px) 93vw, 94vw" data-w-id="2be3a212-21cf-2c2a-b69e-5b5c8cc4a2e2" loading="eager" srcSet="assets/images/img3-p-500.png 500w, assets/images/img3.png 774w" />
                <div className="block-feature">
                  <h4 className="heading-feature">Instant Transactions</h4>
                  <p className="paragraph-feature">Operate in real time with the best technology to process deposits and withdrawals instantly</p>
                </div>
              </div>
            </div>
          </div>
          <div id="w-node-_4f33f29c-2ef1-5436-81c6-a99f21ffcf26-c53bab0c" data-w-id="4f33f29c-2ef1-5436-81c6-a99f21ffcf26" className="wrapper">
            <div className="blurred-color"></div>
            <div className="content-wrapper">
              <div className="feature">
                <div className="block-feature top">
                  <h4 className="heading-feature">Privacy-First</h4>
                  <p className="paragraph-feature">Your data and transactions are protected with advanced encryption, ensuring maximum confidentiality.</p>
                </div><img className="image-feature" src="assets/images/img4.png" alt="" style={{"opacity":"0"}} sizes="(max-width: 479px) 88vw, (max-width: 767px) 93vw, 94vw" data-w-id="4f33f29c-2ef1-5436-81c6-a99f21ffcf2a" loading="eager" srcSet="assets/images/img4.png 500w, assets/images/img4.png 774w" />
              </div>
            </div>
          </div>
          <div id="w-node-_955a1bea-0db9-358b-b5e9-1c0fae8cf9da-c53bab0c" data-w-id="955a1bea-0db9-358b-b5e9-1c0fae8cf9da" className="wrapper">
            <div style={{"opacity":"0"}} className="blurred-color"></div>
            <div className="content-wrapper">
              <div className="feature"><img className="image-feature" src="assets/images/img5.png" alt="" style={{"opacity":"0"}} sizes="(max-width: 479px) 88vw, (max-width: 767px) 93vw, 94vw" data-w-id="955a1bea-0db9-358b-b5e9-1c0fae8cf9de" loading="eager" srcSet="assets/images/img5-p-500.png 500w, assets/images/img5.png 774w" />
                <div className="block-feature">
                  <h4 className="heading-feature">24/7 Support</h4>
                  <p className="paragraph-feature">Our team is available to help you anytime, no matter your time zone.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div className="section">
      <div  className="content">
        <div className="block-cta">
          <h2 className="heading">Live the Future of Finance with Swapbits</h2>
          <h2 className="heading opacity">Explore secure and efficient cryptocurrency exchange solutions, built for users of all skill levels.</h2>
          <a href="features" className="button w-button">Learn More</a>
          <div className="blur-block"></div>
        </div>
      </div>
    </div>
    <div className="section">
      <div  className="content">
        <div className="w-layout-grid grid">
          <div id="w-node-_855c69b3-d5c7-cafa-5ff9-f2028c4dbc1e-c53bab0c" data-w-id="855c69b3-d5c7-cafa-5ff9-f2028c4dbc1e" className="wrapper-circle">
            <div className="content-wrapper-circle">
              <div className="circle-c">
                <div data-w-id="855c69b3-d5c7-cafa-5ff9-f2028c4dbc27" className="area-c1">
                  <div className="color-circle"></div>
                </div>
                <div data-w-id="855c69b3-d5c7-cafa-5ff9-f2028c4dbc29" className="area-c2">
                  <div className="color-circle"></div>
                </div>
                <div data-w-id="855c69b3-d5c7-cafa-5ff9-f2028c4dbc2b" className="area-main-c3">
                  <div className="color-circle line"></div>
                </div>
                <div data-w-id="855c69b3-d5c7-cafa-5ff9-f2028c4dbc2d" className="area-c4">
                  <div className="color-circle"></div>
                </div>
                <div data-w-id="855c69b3-d5c7-cafa-5ff9-f2028c4dbc2f" className="area-c5">
                  <div className="color-circle line"></div>
                </div>
                <div data-w-id="855c69b3-d5c7-cafa-5ff9-f2028c4dbc31" className="area-c6">
                  <div className="color-circle"></div>
                </div>
              </div>
            </div>
            <div className="blur-block-c"></div>
          </div>
          <div id="w-node-e9b78613-13df-23f7-5a88-5cb6ec3fcf07-c53bab0c" className="block right">
            <div className="block-heading-left">
              <div className="subtitle">Revolutionary</div>
              <h2 className="heading">Transforming the future of blockchain technology</h2>
            </div>
            <p className="paragraph">We offer you an intuitive platform, designed to transform the way you manage your digital assets.</p>
            <div className="list"><img src="assets/images/icon_1.svg" loading="eager" alt="" className="icon-list" />
              <div className="block-list">
                <h6 className="heading-list">Secure Transactions</h6>
                <p className="paragraph-list">At Swapbits, we protect your assets with advanced encryption technology and multi-factor authentication. Every transaction is designed to ensure maximum security.</p>
              </div>
            </div>
            <div className="list"><img src="assets/images/icon_2.svg" loading="eager" alt="" className="icon-list" />
              <div className="block-list">
                <h6 className="heading-list">Interoperability</h6>
                <p className="paragraph-list">Connect and manage assets across multiple blockchains from one place.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section">
      <div className="content">
        <div className="block-heading">
          <div className="subtitle">FAQ</div>
          <h2 className="heading">Get answers to common questions</h2>
          <p className="paragraph-block">Here we answer the most frequently asked questions about Swapbits, so you can get started with confidence.</p>
        </div>
        <div className="w-layout-grid grid-faq">
          <div id="w-node-_55ccc3df-9ebc-e84e-ceda-3c81384483bc-c53bab0c" className="accordion-item">
            <div className="accordion-header">
              <h5 className="heading-accordion">What is Swapbits and how does it work?</h5><img src="assets/images/icon_accordion.svg" loading="eager" alt="" className="icon-accordion" />
            </div>
            <div className="accordion-content">
              <p className="paragraph-accordion">Swapbits is a cryptocurrency exchange platform that allows you to buy, sell and manage digital assets quickly, safely and easily. Sign up, verify your account and start trading major cryptocurrencies.</p>
            </div>
          </div>
          <div id="w-node-_55ccc3df-9ebc-e84e-ceda-3c81384483c4-c53bab0c" className="accordion-item">
            <div className="accordion-header">
              <h5 className="heading-accordion">Is Swapbits safe?</h5><img src="assets/images/icon_accordion.svg" loading="eager" alt="" className="icon-accordion" />
            </div>
            <div className="accordion-content">
              <p className="paragraph-accordion">Yes, Swapbits uses bank-grade encryption, multi-factor authentication (2FA), and complies with international regulations to ensure the security of your assets and personal data.</p>
            </div>
          </div>
          <div id="w-node-_55ccc3df-9ebc-e84e-ceda-3c81384483cc-c53bab0c" className="accordion-item">
            <div className="accordion-header">
              <h5 className="heading-accordion">What cryptocurrencies can I trade on Swapbits?</h5><img src="assets/images/icon_accordion.svg" loading="eager" alt="" className="icon-accordion" />
            </div>
            <div className="accordion-content">
              <p className="paragraph-accordion">We currently support Bitcoin, Ethereum, USDT, USDC and other major cryptocurrencies. We are constantly adding new coins.</p>
            </div>
          </div>
          <div id="w-node-_55ccc3df-9ebc-e84e-ceda-3c81384483d4-c53bab0c" className="accordion-item">
            <div className="accordion-header">
              <h5 className="heading-accordion">Can I withdraw funds to my bank account?</h5><img src="assets/images/icon_accordion.svg" loading="eager" alt="" className="icon-accordion"/>
            </div>
            <div className="accordion-content">
              <p className="paragraph-accordion">Yes, you can link your bank account and make withdrawals via SEPA. We also support fast transfers for added convenience.</p>
            </div>
          </div>
          <div id="w-node-_55ccc3df-9ebc-e84e-ceda-3c81384483dc-c53bab0c" className="accordion-item">
            <div className="accordion-header">
              <h5 className="heading-accordion">How do I activate my account?</h5><img src="assets/images/icon_accordion.svg" loading="eager" alt="" className="icon-accordion" />
            </div>
            <div className="accordion-content">
              <p className="paragraph-accordion">After registering, you will need to complete the KYC verification process to activate your account and start trading.</p>
            </div>
          </div>
          <div id="w-node-_55ccc3df-9ebc-e84e-ceda-3c81384483e4-c53bab0c" className="accordion-item">
            <div className="accordion-header">
              <h5 className="heading-accordion">How long does it take to process a deposit or withdrawal?</h5><img src="assets/images/icon_accordion.svg" loading="eager" alt="" className="icon-accordion" />
            </div>
            <div className="accordion-content">
              <p className="paragraph-accordion">Cryptocurrency deposits and withdrawals are usually instant. For fiat currencies, the time can vary up to 24 hours.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section-footer">
          <div className="content">
            <div className="w-layout-grid grid-footer">
              <div
                id="w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fb3-12d14fb0"
                className="block-footer"
              >
                <p className="paragraph-footer">
                  Swapbits Financial: Your trusted platform to manage
                  cryptocurrencies safely and efficiently. We are committed to
                  offering an exceptional user experience and complying with the
                  most demanding regulatory standards. <br />
                  VASP LICENCE RDWW-1584
                </p>
                <h4></h4>
                <a href="contact" className="link-block w-inline-block">
                  <img
                    src="assets/images/icon_6.svg"
                    loading="eager"
                    alt=""
                    className="icon-link"
                  />
                  <div className="text-link">Support@swapbits.io</div>
                </a>
              </div>
              <div
                id="w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fbb-12d14fb0"
                className="block-footer"
              >
                <div className="subtitle-footer">Quick Links</div>
                <a href="features" className="link-footer">
                  Features
                </a>
                <a href="blog" className="link-footer">
                  Soon
                </a>
                <a href="contact" className="link-footer">
                  Contact
                </a>
              </div>
              <div
                id="w-node-_92ed4044-9cfe-904b-e616-3634ff09c860-12d14fb0"
                className="block-footer"
              >
                <div className="subtitle-footer">COMPANY</div>
                <a href="terms" className="link-footer">
                  Terms and conditions
                </a>
                <a href="privacy-terms" className="link-footer">
                  Privacy terms
                </a>
              </div>
            </div>
            <div className="block-footer-down">
              <div className="text-footer-down">
                <a
                  href="https://swapbits.io/"
                  target="_blank"
                  className="link-footer-down"
                >
                  © 2025 Swapbits Financial.All rights reserved
                  <br />
                </a>
              </div>
            </div>
          </div>
        </div>
  </div>
  {/* <script src="../js/webflow.js"></script> */}
        </>
    );
};

export default HomePage;
