// DashboardHome.jsx
import React, { useState, useEffect, useRef } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from "recharts";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Slider from 'react-slick';
import ReactEcharts from "echarts-for-react";
import axios from 'axios';
import chroma from "chroma-js";
import en from "../langs/en-us.json";
import es from "../langs/es-es.json";
import pl from "../langs/pl-pl.json";
import pt from "../langs/pt-br.json";
import zh from "../langs/zh-cn.json";
import "./DashboardHome/cards.css"
import "../css/dasbord-swapbits.webflow.css"


const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{
        backgroundColor: "#16c784",
        padding: "6px 10px",
        borderRadius: "6px",
        color: "#fff",
        fontSize: "14px",
      }}>
        ${payload[0].value.toFixed(2)}
      </div>
    );
  }
  return null;
};


// import ReactEcharts from "echarts-for-react";

// import {
//   Chart as ChartJS,
//   ArcElement,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import { Pie } from "react-chartjs-2";
// import "../styles/DashboardHome.css";
// import Part1 from "./DashboardHome/part1";
// import Part2 from "./DashboardHome/part2";
// import Grow from "./DashboardHome/grows";


const languages = {
  en: { name: "English", flag: "/assets/images/flags/us.png", data: en },
  es: { name: "Español", flag: "/assets/images/flags/es.png", data: es },
  pl: { name: "Polski", flag: "/assets/images/flags/pl.png", data: pl },
  pt: { name: "Português", flag: "/assets/images/flags/br.png", data: pt },
  zh: { name: "中文", flag: "/assets/images/flags/cn.png", data: zh },
};
const intervalMapping = {
  "15m": { interval: "minute", days: 0.01 },
  "1h": { interval: "hourly", days: 1 },
  "5h": { interval: "hourly", days: 2 },
  "1d": { interval: "daily", days: 30 },
  "1w": { interval: "daily", days: 365 }
};
// ChartJS.register(ArcElement, Tooltip, Legend);
const DashboardHome = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletBalanceInUSDT, setWalletBalanceInUSDT] = useState(0);
  const [walletBalanceInEUR, setWalletBalanceInEUR] = useState(0);
  const [portfolio, setPortfolio] = useState({});
  const [quotes, setQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [currency, setCurrency] = useState("EUR"); // Moneda seleccionada
  const called = useRef(false);
const [initialized, setInitialized] = useState(false);
const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [balances, setBalances] = useState([]);
    const [convertedBalances, setConvertedBalances] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [data, setData] = useState([]);
  const [currency, setCurrency] = useState("bitcoin");
  const [interval, setInterval] = useState("1w");
  const [visible, setVisible] = useState([]);
  const [cards, setCards] = useState(null);

  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      const token = localStorage.getItem('jwt');
      if (!token) {
        console.error("Token no encontrado en localStorage.");
        return;
      }

      try {
        const response = await axios.get('https://www.swapbits.io/api/wallets/movements', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        console.log("Transacciones:", response.data);

        const formattedTransactions = response.data.slice(0, 5).map((tx) => {
          return {
            id: tx.id || "-", 
            type: tx.type || "Unknown",
            asset: tx.crypto || tx.cryptoFrom || tx.cryptoTo || "Unknown",
            amount: tx.amount || tx.amountFrom || tx.amountTo || 0,
            address: "0x26...EEf1", // Dirección fija para mostrar
            status: tx.status === "complete" ? "Success" : tx.status,
            date: new Date(tx.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit"
            }),
          };
        });

        setTransactions(formattedTransactions);
      } catch (error) {
        console.error("Error al obtener las transacciones:", error);
      }
    };

    fetchTransactions();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    axios.get('https://swapbits.io/api/users/get-virtual-card', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const data = res.data;
        setCards(data.virtualCards);
        setVisible(Array(data.virtualCards?.length || 0).fill(false));
      })
      .catch(() => setCards(null));
  }, []);

  const toggleVisibility = (index) => {
    setVisible((prev) => {
      const newVisibility = [...prev];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    arrows: false,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { interval: apiInterval, days } = intervalMapping[interval];
        const response = await axios.get(
          `https://api.coingecko.com/api/v3/coins/${currency}/market_chart?vs_currency=usd&days=${days}&interval=${apiInterval}`
        );
        const formattedData = response.data.prices.map((item) => ({
          name: interval === "15m" || interval === "1h" ? new Date(item[0]).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" }) : new Date(item[0]).toLocaleDateString("en-US", { month: "short", day: "numeric" }),
          value: item[1]
        }));
        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, [currency, interval]);
    
    useEffect(() => {
      const fetchBalances = async () => {
        const token = localStorage.getItem("jwt");
        if (!token) {
          console.error("Token no encontrado en localStorage.");
          return;
        }
  
        try {
          const response = await fetch("https://www.swapbits.io/api/wallets/balance", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (!response.ok) {
            throw new Error("Error al obtener datos de la API");
          }
  
          const data = await response.json();
          console.log("data: ", data)
          const filteredBalances = data.filter(asset => asset.balance > 0);
          convertToEUR(filteredBalances);
        } catch (error) {
          console.error("Error al obtener los balances:", error);
        }
      };
  
      fetchBalances();
    }, []);
  
    const convertToEUR = async (portfolio) => {
      try {
        const cryptos = portfolio.reduce((acc, asset) => {
          acc[asset.symbol] = asset.balance;
          return acc;
        }, {});
        
        const response = await fetch("https://www.swapbits.io/api/convert-cryptos", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cryptos }),
        });
  
        if (!response.ok) {
          throw new Error("Error al convertir criptomonedas");
        }
  
        const conversionData = await response.json();
        const converted = Object.keys(conversionData).map(symbol => ({
          name: symbol,
          value: parseFloat(conversionData[symbol].eur),
        }));
        
        setConvertedBalances(converted);
        setSelectedAsset({ name: "Total", value: converted.reduce((acc, item) => acc + item.value, 0) });
      } catch (error) {
        console.error("Error al convertir criptomonedas:", error);
      }
    };
  
    const totalBalanceEUR = convertedBalances.reduce((acc, asset) => acc + asset.value, 0);
  
    const chartOptions = {
      series: [
        {
          type: 'pie',
          radius: ['100%', '50%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: selectedAsset && selectedAsset.name !== "Total" ? `${((selectedAsset.value / totalBalanceEUR) * 100).toFixed(2)}%` : '100%'
          },
          data: convertedBalances.map(asset => ({
            name: asset.name,
            value: asset.value
          })),
        }
      ]
    };

  const handleClick = () => {
      const sideNavbar = document.querySelector('.side-navbar-wrapper');
      if (sideNavbar) {
        sideNavbar.classList.toggle('active');
      }
    };
    const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lang") || "en");
      const [translations, setTranslations] = useState(languages[selectedLang].data);
      useEffect(() => {
        // Guardar idioma seleccionado en localStorage
        localStorage.setItem("lang", selectedLang);
        setTranslations(languages[selectedLang].data);
      }, [selectedLang]);

useEffect(() => {
  if (!called.current && !initialized) {
    called.current = true;
    setInitialized(true);
    // fetchWalletInfo();
    console.log("Llamando API...");
  }
}, [initialized]);


  useEffect(() => {
    document.title = "Dashboard Home - Swapbits";
    
    
  }, []);

  useEffect(() => {
    // fetchQuotes();
  }, [currency]); // Actualizar cotizaciones al cambiar la moneda

  // const fetchWalletInfo = async () => {
  //   try {
  //     console.log("aoara")
  //     const token = localStorage.getItem("jwt");
  //     if (!token) {
  //       console.error("Token no encontrado en localStorage.");
  //       return;
  //     }

  //     const response = await fetch("https://www.swapbits.io/api/wallets/info", {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
      

  //     if (!response.ok) {
  //       throw new Error(`Error: ${response.status} ${response.statusText}`);
  //     }

  //     const data = await response.json();
  //     // console.log(data)
  //     processPortfolio(data);
  //   } catch (error) {
  //     console.error("Error fetching wallet info:", error);
  //   }
  // };

  // const fetchQuotes = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await fetch(`https://www.swapbits.io/api/quotes?currency=${currency}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Error: ${response.status} ${response.statusText}`);
  //     }

  //     const data = await response.json();
  //     setQuotes(data);
  //     calculateTotalInUSDT(data); // Calcular el saldo total en USDT
  //   } catch (error) {
  //     console.error("Error fetching quotes:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const processPortfolio = (data) => {
    const aggregatedPortfolio = {};
  
    // Asumimos que 'data' tiene la estructura correcta de balances
    Object.entries(data.balances).forEach((value) => {
      const token = value["1"].symbol
      const amount = value["1"].balance
      console.log("token ",value["1"])
      if (!aggregatedPortfolio[token]) {
        aggregatedPortfolio[token] = 0;
      }
      aggregatedPortfolio[token] += parseFloat(amount);
    });
  
    console.log("ag",aggregatedPortfolio)
    // Filtrar monedas con balance > 0
    const filteredPortfolio = Object.fromEntries(
      Object.entries(aggregatedPortfolio).filter(([_, amount]) => amount > 0)
    );
  
    console.log("Portafolio Filtrado:", filteredPortfolio);
  
    
  
    convertPortfolio(filteredPortfolio);  // Llamamos a la conversión
  };
  
  

  const convertPortfolio = async (portfolio) => {
    console.log({"cryptos": portfolio})
    try {
      const response = await fetch("https://www.swapbits.io/api/convert-cryptos", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cryptos: portfolio }), // Enviar portafolio
      });
      console.log(response)
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
  
      // Actualizamos los estados con los datos procesados
      setWalletBalanceInEUR(
        Object.values(data).reduce((sum, values) => sum + parseFloat(values.eur), 0)
      );
      setWalletBalanceInUSDT(
        Object.values(data).reduce((sum, values) => sum + parseFloat(values.usdt), 0)
      );
      setPortfolio(Object.fromEntries(
        Object.entries(data).map(([key, value]) => [key, parseFloat(value.eur)])
      ))
      
  
      console.log("Datos Convertidos:", data);
    } catch (error) {
      console.error("Error al convertir el portafolio:", error);
    }
  };
  

  const calculateTotalInUSDT = (quotesData) => {
    let totalInUSDT = 0;
    Object.entries(portfolio).forEach(([token, amount]) => {
      const quote = quotesData.find((q) => q.currency === token);
      if (quote) {
        totalInUSDT += amount * parseFloat(quote.price); // Convertir usando el precio en USDT
      }
    });
    setWalletBalanceInUSDT(totalInUSDT);
  };

  const getPortfolioData = () => {
    const tokens = Object.keys(portfolio);
    const amounts = Object.values(portfolio);
  
    function generateDistinctColors(count) {
      // Usa una escala de colores para generar colores uniformemente distribuidos
      return chroma.scale(['#f94144', '#f3722c', '#f9c74f', '#90be6d', '#577590'])
        .mode('lch') // Usa un modo perceptivo para mejor contraste
        .colors(count); // Genera 'count' colores
    }
  
    const colors = generateDistinctColors(tokens.length);
  
    // Datos para el gráfico
    const data = {
      labels: tokens,
      datasets: [
        {
          data: amounts,
          backgroundColor: colors,
          hoverBackgroundColor: colors.map((color) =>
            chroma(color).alpha(0.7).css()
          ),
          borderColor: "#121212",
          borderWidth: 2,
        },
      ],
    };
  
    // Opciones del gráfico
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "right",
          labels: {
            color: "#ffffff", // Cambiar el color del texto de la leyenda
            font: {
              size: 14,
            },
          },
        },
        tooltip: {
          enabled: true,
          backgroundColor: "#333",
          titleColor: "#fff",
          bodyColor: "#fff",
          borderColor: "#fff",
          borderWidth: 1,
        },
      },
      layout: {
        padding: 20, // Espacio alrededor del gráfico
      },
    };
  
    return { data, options };
  };
  const portfolioData = getPortfolioData();

  
  return (
    <div className="page-wrapper">
      <div className="topbar-wrapper">
      <div className="header-content">
        <div className="header-toggle-wrapper">
          <div className="header-toggle" onClick={handleClick}><img src="../assets/images/Toggle.png" loading="lazy" alt="" className="header-toggle-img"></img></div>
          <div className="header-title-wrapper">
            <h1 className="header-title">{translations.dashboard}</h1>
          </div>
        </div>
        <div className="language-selector">
          <select
            className="language-dropdown"
            value={selectedLang}
            onChange={(e) => setSelectedLang(e.target.value)}
          >
            {Object.entries(languages).map(([key, lang]) => (
              <option key={key} value={key}>
                {lang.name}
              </option>
            ))}
          </select>
          <img
            src={languages[selectedLang].flag}
            alt={languages[selectedLang].name}
            className="language-flag"
          />
        </div>
        
      </div>
    </div>


      {/* <Part1/> */}
      {/* <Part2 />  */}
      <div class="sections-wrapper">
      <div class="dashboard-wrapper">
        <div class="container">
          {/* <Cards/> */}
          <div className="card-wrapper">
      <div className="w-layout-grid card-grid">
        <div id="w-node-c7fe566a-07e2-3947-4056-6b157d451928-4ce57e1f" className="card-singel-wrapper">
          <div className="card-image-wrapper _1">
            <img
              src="../assets/images/bitcoin-btc-logo-2.png"
              loading="lazy"
              sizes="(max-width: 767px) 25px, 100vw"
              srcSet="../assets/images/bitcoin-btc-logo-2-p-500.png 500w, ../assets/images/bitcoin-btc-logo-2-p-800.png 800w, ../assets/images/bitcoin-btc-logo-2-p-1080.png 1080w, ../assets/images/bitcoin-btc-logo-2-p-1600.png 1600w, ../assets/images/bitcoin-btc-logo-2-p-2000.png 2000w, ../assets/images/bitcoin-btc-logo-2-p-2600.png 2600w, ../assets/images/bitcoin-btc-logo-2-p-3200.png 3200w, ../assets/images/bitcoin-btc-logo-2.png 3420w"
              alt=""
              className="card-image"
            />
          </div>
          <div className="card-details">
            <div className="card-info-wrapper">
              <div className="card-info-text-block">Bitcoin</div>
              <img
                src="../assets/images/Card-Info-Arrow1_1Card-Info-Arrow1.png"
                loading="lazy"
                alt=""
                className="card-info-image"
              />
            </div>
            <div className="card-price">$756.98</div>
          </div>
        </div>
        <div id="w-node-c7fe566a-07e2-3947-4056-6b157d451932-4ce57e1f" className="card-singel-wrapper">
          <div className="card-image-wrapper _2">
            <img
              src="../assets/images/bnb-bnb-logo-2.png"
              loading="lazy"
              sizes="(max-width: 767px) 25px, 100vw"
              srcSet="../assets/images/bnb-bnb-logo-2-p-500.png 500w, ../assets/images/bnb-bnb-logo-2-p-800.png 800w, ../assets/images/bnb-bnb-logo-2-p-1080.png 1080w, ../assets/images/bnb-bnb-logo-2-p-1600.png 1600w, ../assets/images/bnb-bnb-logo-2-p-2000.png 2000w, ../assets/images/bnb-bnb-logo-2-p-2600.png 2600w, ../assets/images/bnb-bnb-logo-2-p-3200.png 3200w, ../assets/images/bnb-bnb-logo-2.png 3420w"
              alt=""
              className="card-image"
            />
          </div>
          <div className="card-details">
            <div className="card-info-wrapper">
              <div className="card-info-text-block">Bnb Coin</div>
              <img
                src="../assets/images/Card-Info-Arrow2_1Card-Info-Arrow2.png"
                loading="lazy"
                alt=""
                className="card-info-image"
              />
            </div>
            <div className="card-price">$456.98</div>
          </div>
        </div>
        <div id="w-node-c7fe566a-07e2-3947-4056-6b157d45193c-4ce57e1f" className="card-singel-wrapper">
          <div className="card-image-wrapper _3">
            <img
              src="../assets/images/solana-sol-logo-2.png"
              loading="lazy"
              sizes="(max-width: 767px) 25px, 100vw"
              srcSet="../assets/images/solana-sol-logo-2-p-500.png 500w, ../assets/images/solana-sol-logo-2-p-800.png 800w, ../assets/images/solana-sol-logo-2-p-1080.png 1080w, ../assets/images/solana-sol-logo-2-p-1600.png 1600w, ../assets/images/solana-sol-logo-2-p-2000.png 2000w, ../assets/images/solana-sol-logo-2-p-2600.png 2600w, ../assets/images/solana-sol-logo-2-p-3200.png 3200w, ../assets/images/solana-sol-logo-2.png 3420w"
              alt=""
              className="card-image"
            />
          </div>
          <div className="card-details">
            <div className="card-info-wrapper">
              <div className="card-info-text-block">Solana</div>
              <img
                src="../assets/images/Card-Info-Arrow3_1Card-Info-Arrow3.png"
                loading="lazy"
                alt=""
                className="card-info-image"
              />
            </div>
            <div className="card-price">$456.98</div>
          </div>
        </div>
        <div id="w-node-c7fe566a-07e2-3947-4056-6b157d451946-4ce57e1f" className="card-singel-wrapper">
          <div className="card-image-wrapper _4">
            <img
              src="../assets/images/ethereum-eth-logo-2.png"
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 25px, 100vw"
              srcSet="../assets/images/ethereum-eth-logo-2-p-500.png 500w, ../assets/images/ethereum-eth-logo-2-p-800.png 800w, ../assets/images/ethereum-eth-logo-2-p-1080.png 1080w, ../assets/images/ethereum-eth-logo-2-p-1600.png 1600w, ../assets/images/ethereum-eth-logo-2-p-2000.png 2000w, ../assets/images/ethereum-eth-logo-2-p-2600.png 2600w, ../assets/images/ethereum-eth-logo-2-p-3200.png 3200w, ../assets/images/ethereum-eth-logo-2.png 3420w"
              alt=""
              className="card-image"
            />
          </div>
          <div className="card-details">
            <div className="card-info-wrapper">
              <div className="card-info-text-block">Ethereum</div>
              <img
                src="../assets/images/Card-Info-Arrow4_1Card-Info-Arrow4.png"
                loading="lazy"
                alt=""
                className="card-info-image"
              />
            </div>
            <div className="card-price">$456.98</div>
          </div>
        </div>
      </div>
    </div>
          <div class="dashboard-chart-wrapper">
            <div class="dashboard-chart-left-content">
              {/* <div className="dashboard-chart">
                <div className="dashboard-chart-top-content">
                  <div className="dashboard-chart-title-wrapper">
                    <h2 className="dashboard-chart-title mr35">
                      Overall Growth
                    </h2>
                    <div className="dashboard-chart-list-wrap">
                      <img
                        src="../assets/images/Dashboard-Chart-List-Image_1Dashboard-Chart-List-Image.png"
                        loading="lazy"
                        alt=""
                        className="dashboard-chart-list-image"
                      />
                      <div className="dashboard-chart-list-text">Bitcoin</div>
                    </div>
                  </div>
                  <div className="dashboard-categorys">
                    <div className="dashboard-categorys-text-block">15m</div>
                    <div className="dashboard-categorys-text-block">1H</div>
                    <div className="dashboard-categorys-text-block">5H</div>
                    <div className="dashboard-categorys-text-block">1D</div>
                    <div className="dashboard-categorys-text-block">1W</div>
                  </div>
                </div>
                <div className="dashboard-chart-image-wrapper">
                  <img
                    src="../assets/images/Chart-Image.svg"
                    loading="lazy"
                    alt=""
                    className="dashboard-chart-image"
                  />
                </div>
              // </div> */}
              {/* <Grow /> */}
              <div className="dashboard-chart">
                    <div className="dashboard-chart-top-content">
                      <div className="dashboard-chart-title-wrapper">
                        <h2 className="dashboard-chart-title mr35">{translations.overall_growth}</h2>
                        <div className="dashboard-chart-list-wrap">
                          <select onChange={(e) => setCurrency(e.target.value)} className="dashboard-chart-list-text">
                            <option value="bitcoin">Bitcoin</option>
                            <option value="ethereum">Ethereum</option>
                            <option value="cardano">Cardano</option>
                            <option value="ripple">Ripple</option>
                          </select>
                        </div>
                      </div>
                      <div className="dashboard-categorys">
                        {['15m', '1h', '5h', '1d', '1w'].map(time => (
                          <button 
                            key={time} 
                            className={`dashboard-categorys-text-block ${interval === time ? 'active' : ''}`} 
                            onClick={() => setInterval(time)}>
                            {time}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="dashboard-chart-image-wrapper">
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={data}>
                          <XAxis dataKey="name" stroke="#aaa" />
                          <YAxis stroke="#aaa" />
                          <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                          <Tooltip content={<CustomTooltip />} cursor={{ stroke: "#16c784", strokeWidth: 2 }} />
                          <Line type="monotone" dataKey="value" stroke="#16c784" strokeWidth={2} dot={false} />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
              {/* <Overwall /> */}
              <div className="transaction-wrapper">
      <div className="dashboard-chart-title-wrap">
        <h2 className="dashboard-chart-title">{translations.overall_growth}</h2>
      </div>
      <div className="transaction-table-wrapper">
        <div className="transaction-table-heading-wrap">
          <div className="w-layout-grid transaction-table-grid">
            <div className="transaction-table-heading-singel">
              <h1 className="transaction-table-heading">{translations.type}</h1>
            </div>
            <div className="transaction-table-heading-singel">
              <h1 className="transaction-table-heading">{translations.asset}</h1>
            </div>
            <div className="transaction-table-heading-singel">
              <h1 className="transaction-table-heading">{translations.date}</h1>
            </div>
            <div className="transaction-table-heading-singel">
              <h1 className="transaction-table-heading">{translations.address}</h1>
            </div>
            <div className="transaction-table-heading-singel">
              <h1 className="transaction-table-heading">{translations.amount}</h1>
            </div>
            <div className="transaction-table-heading-singel">
              <h1 className="transaction-table-heading">{translations.status}</h1>
            </div>
          </div>
        </div>
        {transactions.map((transaction, index) => (
          <div key={index} className="transaction-table-content">
            <div className="w-layout-grid transaction-table-grid">
              <div className="transaction-table-singel">
                <div className="transaction-table-text-block">{transaction.type}</div>
              </div>
              <div className="transaction-table-singel">
                <div className="transaction-table-text-block">{transaction.asset}</div>
              </div>
              <div className="transaction-table-singel">
                <div className="transaction-table-text-block">{transaction.date}</div>
              </div>
              <div className="transaction-table-singel">
                <div className="transaction-table-text-block">
                  <span style={{ cursor: "pointer" }}>
                    {transaction.address}
                  </span>
                </div>
              </div>
              <div className="transaction-table-singel">
                <div className="transaction-table-text-block">{transaction.amount}</div>
              </div>
              <div className="transaction-table-singel">
                <div className="transaction-table-text-block" style={{
                  color: transaction.status === "Success" ? "green" : "orange"
                }}>
                  {transaction.status}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
            </div>


            <div class="dashboard-chart-right-content">
              {/* <MyCards /> */}
              <div className="chart-card-wrapper">
      <div className="dashboard-chart-title-wrap">
        <h2 className="dashboard-chart-title">{translations.my_cards}</h2>
      </div>

      {cards === null ? (
        <p className="cards-message">{translations.coming_soon}</p>
      ) : cards.length === 0 ? (
        <p className="cards-message">{translations.request_your_virtual_card}</p>
      ) : (
        <Slider {...settings}>
          {cards.map((card, index) => (
            <div key={card.id} className="card">
              <div className="card-content">
                <p className="card-name">{card.name}</p>
                <div className="card-number-container">
                  <p className="card-number">
                    {visible[index] ? card.number : '**** **** **** ****'}
                  </p>
                  <button onClick={() => toggleVisibility(index)} className="eye-icon">
                    {visible[index] ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                <p className="card-expiry">{translations.expiration}: {card.expiry}</p>
                <p className="card-cvv">{transactions.cvv}: {visible[index] ? card.cvv : '***'}</p>
              </div>
            </div>
          ))}
        </Slider>
      )}

      <style jsx>{`
        .card-slider-container {
          max-width: 400px;
          margin: auto;
          text-align: center;
        }
        .card {
          background: #fff;
          border-radius: 10px;
          padding: 20px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          text-align: left;
        }
        .card-content {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        .card-name {
          font-size: 18px;
          font-weight: bold;
        }
        .card-number-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .card-number {
          font-size: 16px;
          letter-spacing: 2px;
        }
        .eye-icon {
          background: none;
          border: none;
          cursor: pointer;
          font-size: 20px;
        }
        .card-expiry, .card-cvv {
          font-size: 14px;
          color: gray;
        }
        .cards-message {
          font-size: 16px;
          color: #555;
          text-align: center;
          margin-top: 20px;
        }
      `}</style>
    </div>
              {/* <Balance /> */}
              {(!selectedAsset) ? <p>{transactions.loading_balance}</p> : <div className="total-balance">
                    <a href="/dashboard/wallet" className="balance-categorys-button withdraw w-button">
                      {translations.deposit}
                    </a>
                    <a href="/dashboard/wallet" className="balance-categorys-button withdraw w-button">
                      {translations.withdrawal}
                    </a>
                    <div className="total-balance-singel-wrapper">
                      <ReactEcharts option={chartOptions} onEvents={{ 'click': (params) => setSelectedAsset(params.data) }} />
                      <div className="balance-text-wrapper">
                        <div className="total-balance-title">{translations.total_balance}</div>
                        <h2 className="total-balance-code">{selectedAsset.value.toFixed(2)} EUR</h2>
                        <div className="total-balance-price">{totalBalanceEUR.toFixed(2)} EUR</div>
                      </div>
                    </div>
                  </div>}
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="container">
            <div class="footer-details-wrapper">
              <div class="footer-text-block">
                <a
                  href="http://webflow.com"
                  target="_blank"
                  class="footer-text-link"
                >
                  <strong>© 2025 Swapbits Financial.All rights reserved</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-w-id="ad850566-76af-f3eb-5277-07c1948f8ecc" class="preloader">
          <img
            src="../assets/images/Sidebar-Logo_1Sidebar-Logo.png"
            loading="lazy"
            data-w-id="ad850566-76af-f3eb-5277-07c1948f8ecd"
            alt=""
            class="preloader-image"
          />
        </div>
        
        
      </div>
    </div>
    
    </div>
  );
};

export default DashboardHome;
