import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import TermsAndConditions from "./pages/TermsAndConditions";
import Privacy from "./pages/Privacy";
import UserDashboard from "./pages/UserDashboard";
import PortaDashboard from "./pages/DashboardPorta";
import DashboardAccount from "./pages/DashboardAccount";
import DepositDashboard from "./pages/DashboardDeposit";
import DepositHome from "./pages/DashboardDepositHome";
import DepositCard from "./pages/DashboardDepositCard";
import DepositInternal from "./pages/DashboardDepositInternal";
import HomeDashboard from "./pages/DashboardHome";
import Exchange from "./pages/DashboardChange";
import WithdrawDashboard from "./pages/DashboardWithdraw";
import WithdrawDashboardHome from "./pages/DashboardWithdrawHome";
import WithSender from "./pages/WithDashboardSend"
import PublicHeader from "./components/Header";
import DashboardHeader from "./components/Header2";
import DashboardTrans from "./pages/DashboardTrans"
import CookieBanner from "./components/CookieBanner";
import Footer from "./components/Footer";
import KycVerification from "./pages/KycVerification";
import DashboardAdminHome from "./pages/admin/Home/DashboardAdminHome"
import AdminHeader from "./components/AdminHeader"
import Intercom from '@intercom/messenger-js-sdk';
import Feacture from "./pages/Feacture";
import Contact from "./pages/Contact";
import PrivacyTemrs from "./pages/privacyTerms";
import Prices from "./pages/Prices";
import Wallets from "./pages/wallets";
import Activities from "./pages/activities";
import Setting from "./pages/setting";
import Logout from "./pages/logout";
import { LanguageProvider } from "./context/LanguageContext"; 
// import "./css/dasbord-swapbits.webflow.css"
// import "./css/dash/normalize.css"
// import "./css/dash/webflow.css"
// import "./index.css";

const HeaderSwitcher = ({ isLoggedIn, isKycVerified }) => {
  const location = useLocation(); // Ahora se usa dentro de un contexto de Router

  if (location.pathname.startsWith("/admin/dashboard")) {
    return <AdminHeader />;
  }

  console.log("isKycVerified", isLoggedIn)
  console.log("isKycVerified", isKycVerified)
  
  if (isLoggedIn && isKycVerified === "true") {
    console.log("headersssssssssssssss")
    return <LanguageProvider>
      <DashboardHeader />;
    </LanguageProvider>
    // return <DashboardHeader />;
  }
  // return <DashboardHeader />;

  // return <PublicHeader />;
};



const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isKycVerified, setIsKycVerified] = useState(null);
  const [loading, setLoading] = useState(false);
  // const location = useLocation();


  
  useEffect(() => {
    const token = localStorage.getItem("jwt");
    setIsLoggedIn(!!token);
    setLoading(true); // Activa la carga
    console.log("Token:", token);
    
    
    if (token) {
      fetch("https://www.swapbits.io/api/kyc/status", {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(async (response) => {
          console.log("Response:", response.body);
          if(response.status === 401){
            const errorData = await response.json();
            console.log(errorData.code)
            if(errorData.code == 5002){
              return <Navigate to="/verification" />;
            }
            else {
              localStorage.removeItem("jwt");
              return <Navigate to="/login" />;
            }
            
          }
          if (!response.ok) {
            throw new Error("Error al verificar estado KYC");
          }
          return response.json();
        })
        .then((data) => {
          console.log("aaaaaaaaaaaaaaaaaa", data.status.enable)
          console.log("KYC Verificado:", isKycVerified);
          setIsKycVerified(data.status.enable);
        })
        .catch((error) => {
          console.error("Error al obtener estado KYC:", error.message);
          console.log(error.message)
          if (error.message === "Unauthorized") {
            localStorage.removeItem("jwt");
          }
          setIsKycVerified(false);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false); // Retrasa el final del loading
          }, 5000); // Puedes ajustar el tiempo a 1s, 2s, según lo que necesites
        });
    } else {
      setTimeout(() => {
        setLoading(false); // Si no hay token, también retrasa el final del loading

        return <Navigate to="/login" />;
      }, 5000);
    }
  }, []);
  
  
  

  useEffect(() => {
    if (window.Webflow) {
      window.Webflow.destroy(); // Destruye instancias previas
      window.Webflow.ready(); // Reactiva Webflow
      window.Webflow.require("ix2").init(); // Reactiva interacciones (si aplica)
    }
  }, []);

  const requireKyc = (element) => {
    const token = localStorage.getItem("jwt");
    if (loading) {
      return <div>Cargando...</div>; // Muestra un loader mientras se verifica el estado
    }
  
    if (token == null) {
      return <Navigate to="/login" />;
    }
  
    // console.log("KYC Verificado:", isKycVerified);
  
    if (
      isKycVerified == false) {
        console.log("KYC Verificado:", isKycVerified);
      return <Navigate to="/verification" />;
    }
  
    return element;
  };
  
  


  // const renderHeader = () => {
  //   if (location.pathname.startsWith("/admin/dashboard")) {
  //     return <AdminHeader />; // Header exclusivo para admin
  //   }
  //   if (isLoggedIn && isKycVerified === "true") {
  //     return <DashboardHeader />;
  //   }
  //   return <PublicHeader />;
  // };
  // console.log("kyc", isKycVerified)

  // useEffect(() => {
  //   Intercom({
  //     app_id: 'b376fn6r',
  //     user_id: 'user.id', // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
  //     name: 'user.name', // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
  //     email: 'user.email', // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
  //     created_at: Math.floor(new Date().getTime() / 1000), // Example: Replace with actual Unix timestamp
  //   });
  // }, []);

  if (loading) {
    // Pantalla de carga
    return (
      <div className="loading-screen">
      <video
        autoPlay
        loop
        muted
        className="loading-video"
      >
        <source src="assets/videos/loading.mp4" type="video/mp4" />
       
      </video>

        <div className="spinner"></div>
    </div>
      
    );
  }


  

  return (
    <Router>
      {/* {renderHeader()} */}
      <HeaderSwitcher isLoggedIn={isLoggedIn} isKycVerified={isKycVerified} />
      <div className={isLoggedIn && (isKycVerified == "true") ? "dashboard-main-content" : "main-content"}>
        <Routes>
          <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard/home" /> : <Home />} />
          <Route
            path="/login"
            element={isLoggedIn ? <Navigate to="/dashboard/home" /> : <Login />}
          />
          <Route
            path="/register"
            element={isLoggedIn ? <Navigate to="/dashboard/home" /> : <Register />}
          />
          <Route path="/verification" element={<KycVerification />} />
          <Route
            path="/dashboard/home"
            element={requireKyc(<HomeDashboard />)}
          />
          <Route
            path="/dashboard/billfold"
            element={requireKyc(<PortaDashboard />)}
          />
          <Route
            path="/dashboard/exchange"
            element={requireKyc(<Exchange />)}
          />
          <Route
            path="/dashboard/deposit/crypto"
            element={requireKyc(<DepositDashboard />)}
          />
          <Route
            path="/dashboard/deposit/card"
            element={requireKyc(<DepositCard />)}
          />
          <Route
            path="/dashboard/deposit/internal"
            element={requireKyc(<DepositInternal />)}
          />
          <Route
            path="/dashboard/deposit"
            element={requireKyc(<DepositHome />)}
          />
          <Route
            path="/dashboard/withdraw"
            element={requireKyc(<WithdrawDashboardHome />)}
          />
          <Route
            path="/dashboard/withdraw/crypto"
            element={requireKyc(<WithdrawDashboard />)}
          />
          <Route
            path="/dashboard/withdraw/internal"
            element={requireKyc(<WithSender />)}
          />
          <Route
            path="/dashboard/movements"
            element={requireKyc(<DashboardTrans />)}
          />
          <Route 
              path="dashboard/prices"
              element={requireKyc(<Prices />)}
              />

              <Route path="dashboard/wallet" element={requireKyc(<Wallets />)}/>

              <Route path="dashboard/activities" element={requireKyc(<Activities/>)} />
              <Route path="dashboard/settings" element={requireKyc(<Setting/>)} />
              <Route path="/logout" element={<Logout/>} />
          <Route
            path="/dashboard/account"
            element={requireKyc(<DashboardAccount />)}
          />
          <Route
            path="/admin/dashboard"
            element={requireKyc(<DashboardAdminHome />)}
            />

            <Route 
              path="/features"
              element={<Feacture />}
              />
              <Route
               path="contact"
               element={<Contact />}
               />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/privacy-terms" element={<PrivacyTemrs />} />
        </Routes>
        {/* <CookieBanner /> */}
        {/* {!isLoggedIn && <Footer />} */}
      </div>
      {/* <script src="./js/webflow.js"></script> */}
    </Router>
  );
};

export default App;
