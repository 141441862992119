import { createContext, useState, useEffect } from "react";

// Importa los archivos JSON de traducción
import en from "../langs/en-us.json";
import es from "../langs/es-es.json";
import pl from "../langs/pl-pl.json";
import pt from "../langs/pt-br.json";
import zh from "../langs/zh-cn.json";

// Lista de idiomas
const languages = {
  en: { name: "English", flag: "/assets/images/flags/us.png", data: en },
  es: { name: "Español", flag: "/assets/images/flags/es.png", data: es },
  pl: { name: "Polski", flag: "/assets/images/flags/pl.png", data: pl },
  pt: { name: "Português", flag: "/assets/images/flags/br.png", data: pt },
  zh: { name: "中文", flag: "/assets/images/flags/cn.png", data: zh },
};

// Crear contexto
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lang") || "en");
  const [translations, setTranslations] = useState(languages[selectedLang].data);

  // Actualizar idioma en tiempo real
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      localStorage.setItem("lang", localStorage.getItem("lang"));
      setTranslations(languages[selectedLang].data);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [selectedLang]);

  return (
    <LanguageContext.Provider value={{ selectedLang, setSelectedLang, translations, languages }}>
      {children}
    </LanguageContext.Provider>
  );
};