import React from "react";
import {
  FaArrowLeft,
  FaShoppingBag,
  FaChalkboard,
  FaCcVisa,
  FaBitcoin,
  FaChartBar,
  FaProjectDiagram,
  FaUser,
  FaIdCard,
  FaExchangeAlt,
  FaThLarge,
  FaFileInvoiceDollar,
  FaImage,
  FaImages,
  FaSignInAlt,
  FaExclamationTriangle,
  FaFileAlt,
  FaPuzzlePiece,
  FaTh,
  FaEnvelopeOpenText,
  FaChevronRight,
  FaBell,
} from "react-icons/fa";
import "../../../styles/AdminHome.css";

function DashboardAdminHome() {
  return (
    <div className="dashboard-main-content">
      <header className="nk-header nk-header-fixed">
        <div className="container-xl wide-xl">
          <div className="nk-header-wrap">
            {/* Menu Trigger */}
            <div className="nk-menu-trigger d-xl-none ms-n1">
              <a
                href="#toggle"
                className="nk-nav-toggle nk-quick-nav-icon d-xl-none ms-n1 me-3"
              >
                <em className="icon ni ni-menu"></em>
              </a>
            </div>

            {/* Navigation Menu */}
            <nav className="header-menu">
              <div className="header-menu-container">
                <ul className="menu-main">
                  {/* Dashboards Menu */}
                  <li className="menu-item menu-item-dashboards">
                    <a className="menu-link menu-link-dashboards" href="/demo9">
                      <span className="menu-text">Dashboards</span>
                    </a>
                    <ul className="submenu">
                      <li className="submenu-item submenu-item-default">
                        <a
                          className="submenu-link submenu-link-default"
                          href="/demo9/"
                        >
                          <span className="submenu-text">
                            Default Dashboard
                          </span>
                        </a>
                      </li>
                      <li className="submenu-item submenu-item-sales">
                        <a
                          className="submenu-link submenu-link-sales"
                          href="/demo9/sales"
                        >
                          <span className="submenu-text">Sales Dashboard</span>
                        </a>
                      </li>
                      <li className="submenu-item submenu-item-invest">
                        <a
                          className="submenu-link submenu-link-invest"
                          href="/demo9/invest"
                        >
                          <span className="submenu-text">Invest Dashboard</span>
                        </a>
                      </li>
                      <li className="submenu-item submenu-item-crypto">
                        <a
                          className="submenu-link submenu-link-crypto"
                          href="/demo9/crypto"
                        >
                          <span className="submenu-text">Crypto Dashboard</span>
                        </a>
                      </li>
                      <li className="submenu-item submenu-item-analytics">
                        <a
                          className="submenu-link submenu-link-analytics"
                          href="/demo9/analytics"
                        >
                          <span className="submenu-text">
                            Analytics Dashboard
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  {/* Apps Menu */}
                  <li className="menu-item menu-item-apps">
                    <a className="menu-link menu-link-apps" href="/demo9">
                      <span className="menu-text">Apps</span>
                    </a>
                    <ul className="submenu">
                      <li className="submenu-item submenu-item-messages">
                        <a
                          className="submenu-link submenu-link-messages"
                          href="/demo9/app-messages"
                        >
                          <span className="submenu-text">Messages</span>
                        </a>
                      </li>
                      <li className="submenu-item submenu-item-inbox">
                        <a
                          className="submenu-link submenu-link-inbox"
                          href="/demo9/app-inbox"
                        >
                          <span className="submenu-text">Inbox / Mail</span>
                        </a>
                      </li>
                      <li className="submenu-item submenu-item-file-manager">
                        <a
                          className="submenu-link submenu-link-file-manager"
                          href="/demo9/app-file-manager"
                        >
                          <span className="submenu-text">File Manager</span>
                        </a>
                      </li>
                      <li className="submenu-item submenu-item-chat">
                        <a
                          className="submenu-link submenu-link-chat"
                          href="/demo9/app-chat"
                        >
                          <span className="submenu-text">
                            Chats / Messenger
                          </span>
                        </a>
                      </li>
                      <li className="submenu-item submenu-item-calendar">
                        <a
                          className="submenu-link submenu-link-calendar"
                          href="/demo9/app-calender"
                        >
                          <span className="submenu-text">Calendar</span>
                        </a>
                      </li>
                      <li className="submenu-item submenu-item-kanban">
                        <a
                          className="submenu-link submenu-link-kanban"
                          href="/demo9/app-kanban"
                        >
                          <span className="submenu-text">Kanban Board</span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  {/* Components Menu */}
                  <li className="menu-item menu-item-components">
                    <a
                      className="menu-link menu-link-components"
                      href="/demo9/components"
                    >
                      <span className="menu-text">Components</span>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>

            {/* User Tools */}
            <div className="header-tools">
              <ul className="quick-nav">
                <li className="notification-dropdown">
                  {/* Notifications */}
                  <div className="dropdown-container">
                    <a className="dropdown-toggle-icon" aria-expanded="false">
                      <div className="icon-status-notification">
                        <em className="icon-bell">
                          <span>
                            <FaBell></FaBell>
                          </span>
                        </em>
                      </div>
                    </a>
                    <div className="dropdown-menu">
                      <div className="dropdown-header">
                        <span className="dropdown-title">Notifications</span>
                        <a href="#markasread" className="mark-all-read">
                          Mark All as Read
                        </a>
                      </div>
                      <div className="dropdown-body">
                        <div className="notification-list">
                          <div className="notification-item">
                            <div className="notification-icon">
                              <em className="icon-warning"></em>
                            </div>
                            <div className="notification-content">
                              <div className="notification-text">
                                You have requested a Withdrawal
                              </div>
                              <div className="notification-time">2 hrs ago</div>
                            </div>
                          </div>
                          {/* Add more notification items as needed */}
                        </div>
                      </div>
                      <div className="dropdown-footer">
                        <a href="#viewall" className="view-all">
                          View All
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                {/* Additional tools */}
                <li className="user-dropdown">
                  {/* User Profile */}
                  <div className="dropdown-container">
                    <a className="dropdown-toggle-icon" aria-expanded="false">
                      <div className="icon-status-user">
                        <em className="icon-user">
                          <span>
                            <FaUser></FaUser>
                          </span>
                        </em>
                      </div>
                    </a>
                    <div
                      tabIndex="-1"
                      role="menu"
                      aria-hidden="false"
                      className="user-dropdown-menu show"
                      data-popper-placement="bottom-end"
                      style={{
                        position: "absolute",
                        inset: "0px 0px auto auto",
                        transform: "translate(0px, 33px)",
                      }}
                    >
                      <div className="dropdown-content-wrapper bg-light hidden-md">
                        <div className="user-card-small">
                          <div className="user-avatar-small">
                            <span>AB</span>
                          </div>
                          <div className="user-info-small">
                            <span className="user-name">Abu Bin Ishtiyak</span>
                            <span className="user-email">info@softnio.com</span>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown-content">
                        <ul className="user-link-list">
                          <li>
                            <a
                              href="/demo9/user-profile-regular"
                              className="user-link-item"
                            >
                              <em className="icon-user-alt"></em>{" "}
                              <span>View Profile</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/demo9/user-profile-setting"
                              className="user-link-item"
                            >
                              <em className="icon-settings"></em>{" "}
                              <span>Account Setting</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/demo9/user-profile-activity"
                              className="user-link-item"
                            >
                              <em className="icon-activity"></em>{" "}
                              <span>Login Activity</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="user-link-item dark-mode-toggle"
                            >
                              <em className="icon-dark-mode"></em>
                              <span>Dark Mode</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="dropdown-footer">
                        <ul className="user-signout-list">
                          <li>
                            <a
                              href="/demo9/auth-login"
                              className="user-link-item"
                            >
                              <em className="icon-signout"></em>
                              <span>Sign Out</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default DashboardAdminHome;
