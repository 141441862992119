import React, { useState, useEffect } from 'react';

function Feacture() {

useEffect(() => {
      const wrappers = document.querySelectorAll(".wrapper");
  
      wrappers.forEach((wrapper) => {
        const blurredColor = wrapper.querySelector(".blurred-color");
  
        if (!blurredColor) return;
  
        let lastOffsetX = 0.01; // Última posición en X
        let lastOffsetY = 0.01; // Última posición en Y
  
        const handleMouseMove = (event) => {
          const rect = wrapper.getBoundingClientRect();
          const isInBounds =
            event.clientX >= rect.left &&
            event.clientX <= rect.right &&
            event.clientY >= rect.top &&
            event.clientY <= rect.bottom;
  
          if (isInBounds) {
            // Calcular desplazamiento dinámico dentro del wrapper
            lastOffsetX = ((event.clientX - rect.left) / rect.width - 0.5) * 200; // De -100% a 100%
            lastOffsetY = ((event.clientY - rect.top) / rect.height - 0.5) * 200; // De -100% a 100%
  
            // Aplicar posición dinámica
            // blurredColor.style.transition = "transform 0.01s ease-out"; // Suavizar la transición
  
            blurredColor.style.transform = `translate3d(${lastOffsetX}%, ${lastOffsetY}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
            blurredColor.style.willChange = "transform";
            blurredColor.style.transformStyle = "preserve-3d";
          } else {
            // Si el mouse sale, usa la última posición como referencia
            blurredColor.style.transform = `translate3d(${lastOffsetX.toFixed(
              2
            )}%, ${lastOffsetY.toFixed(
              2
            )}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
          }
        };
  
        // Añadir listener para el movimiento del mouse
        document.addEventListener("mousemove", handleMouseMove);
  
        // Limpiar al desmontar
        return () => {
          document.removeEventListener("mousemove", handleMouseMove);
        };
      });
    }, []);
  
    useEffect(() => {
      const wrappers = document.querySelectorAll(".wrapper");
  
      wrappers.forEach((wrapper) => {
        const blurredColor = wrapper.querySelector(".blurred-color");
  
        if (!blurredColor) return;
  
        let isMouseInside = false;
        let opacityInterval = null;
        let currentOpacity = 0;
  
        const handleMouseEnter = () => {
          isMouseInside = true;
          if (opacityInterval) clearInterval(opacityInterval);
  
          opacityInterval = setInterval(() => {
            if (currentOpacity < 1) {
              currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incrementar opacidad
              blurredColor.style.opacity = currentOpacity.toFixed(2);
            } else {
              clearInterval(opacityInterval); // Detener en 1
              opacityInterval = null;
            }
          }, 10); // Incrementar cada 10ms
        };
  
        const handleMouseLeave = () => {
          isMouseInside = false;
          if (opacityInterval) clearInterval(opacityInterval);
  
          opacityInterval = setInterval(() => {
            if (currentOpacity > 0) {
              currentOpacity = Math.max(currentOpacity - 0.01, 0); // Reducir opacidad
              blurredColor.style.opacity = currentOpacity.toFixed(2);
            } else {
              clearInterval(opacityInterval); // Detener en 0
              opacityInterval = null;
            }
          }, 10); // Reducir cada 10ms
        };
  
        // Añadir listeners al contenedor
        wrapper.addEventListener("mouseenter", handleMouseEnter);
        wrapper.addEventListener("mouseleave", handleMouseLeave);
  
        // Limpiar listeners al desmontar
        return () => {
          wrapper.removeEventListener("mouseenter", handleMouseEnter);
          wrapper.removeEventListener("mouseleave", handleMouseLeave);
          if (opacityInterval) clearInterval(opacityInterval);
        };
      });
    }, []);


    useEffect(() => {
            const content = document.querySelectorAll(".section-top");
            let opacityInterval = null;
    
            content.forEach((e) => {
              const img = e.querySelector(".content");
              const blurtop = e.querySelector(".blur-top");
          
              let currentOpacity = 0;
          
              if (opacityInterval) clearInterval(opacityInterval);
          
              opacityInterval = setInterval(() => {
                if (currentOpacity < 1) {
                  currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incrementar opacidad
                  img.style.opacity = currentOpacity.toFixed(2);
                  blurtop.style.opacity = currentOpacity.toFixed(2);
                } else {
                  clearInterval(opacityInterval); // Detener en 1
                  opacityInterval = null;
                }
              }, 10);
            });
          
            // Limpiar intervalo cuando el componente se desmonte
            return () => {
                clearInterval(opacityInterval);
              };
          }, []);

          useEffect(() => {
            const content = document.querySelectorAll(".section");
            let opacityInterval = null;
    
            content.forEach((e) => {
              const img = e.querySelector(".content");
             
          
              let currentOpacity = 0;
          
              if (opacityInterval) clearInterval(opacityInterval);
          
              opacityInterval = setInterval(() => {
                if (currentOpacity < 1) {
                  currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incrementar opacidad
                  img.style.opacity = currentOpacity.toFixed(2);
                } else {
                  clearInterval(opacityInterval); // Detener en 1
                  opacityInterval = null;
                }
              }, 10);
            });
          
            // Limpiar intervalo cuando el componente se desmonte
            return () => {
                clearInterval(opacityInterval);
              };
          }, []);



          useEffect(() => {
                  const elements = document.querySelectorAll(".overflow .content, .overflow .blur-top, .overflow .heading-hero, .overflow .paragraph-hero, .overflow .button");
              
                  elements.forEach((el) => {
                      if (window.getComputedStyle(el).opacity === "0") {
                          let currentOpacity = 0;
                          const opacityInterval = setInterval(() => {
                              if (currentOpacity < 1) {
                                  currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incremento gradual
                                  el.style.opacity = currentOpacity.toFixed(2);
                              } else {
                                  clearInterval(opacityInterval);
                              }
                          }, 10);
                      }
                  });
              }, []);
  return (
    <div>
      <div data-animation="default" data-collapse="medium" data-duration="600" data-easing="ease-out-expo" data-easing2="ease-out-expo" role="banner" class="navbar w-nav">
    <div class="nav-block">
      <div class="nav">
        <nav role="navigation" class="nav-menu w-nav-menu">
          <a href="features" aria-current="page" class="nav-link w-nav-link w--current">Features<br /></a>
          <a href="blog" class="nav-link w-nav-link">Soon</a>
          <a href="contact" class="nav-link w-nav-link">Contact</a>
        </nav>
      </div>
      <div class="nav">
        <a href="login" class="nav-link-extra w-nav-link">Log in</a>
        <div class="block-nav-button">
          <a href="register" class="nav-button w-button">Create account</a>
          <div class="menu-button w-nav-button">
            <div class="menu-icon w-icon-nav-menu"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="overflow">
    <div data-w-id="c21e9273-07ed-2b45-c62e-0b9527ee6c42" class="section-top">
      <div style={{"opacity":"0"}} class="content">
        <div class="block-top">
          <div class="section">
            <div style={{"opacity":"0"}} class="content">
              <div class="block-heading">
                <div style={{transform: "translate3d(0, 16px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)", opacity: 0}} className="subtitle">Features</div>
                <h2 class="heading">Discover Swapbits Key Features</h2>
              </div>
              <div class="w-layout-grid grid-features">
                <div data-w-id="1792c06e-b16b-7828-6976-dd20e4ffd590" class="wrapper">
                  <div style={{"opacity":"0"}} class="blurred-color"></div>
                  <div class="content-wrapper">
                    <div class="feature"><img class="image-feature" src="assets/images/img1.png" alt="" style={{"opacity":"1"}} sizes="(max-width: 479px) 79vw, (max-width: 767px) 86vw, 88vw" data-w-id="1792c06e-b16b-7828-6976-dd20e4ffd594" loading="eager" srcset="assets/images/img1-p-500.png 500w, assets/images/img1.png 885w" />
                      <div class="block-feature">
                        <h4 class="heading-feature">Regulated Exchange</h4>
                        <p class="paragraph-feature">Our VASP license ensures safe and regulated operations at all times</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-w-id="1792c06e-b16b-7828-6976-dd20e4ffd59a" class="wrapper">
                  <div style={{"opacity":"0"}} class="blurred-color"></div>
                  <div class="content-wrapper">
                    <div class="feature">
                      <div class="block-feature top">
                        <h4 class="heading-feature">BaaS-Ready</h4>
                        <p class="paragraph-feature">Coming soon: multi-currency accounts, IBAN, and advanced banking services</p>
                      </div><img class="image-feature" src="assets/images/img2.png" alt="" style={{"opacity":"1"}} sizes="(max-width: 479px) 79vw, (max-width: 767px) 86vw, 88vw" data-w-id="1792c06e-b16b-7828-6976-dd20e4ffd5a3" loading="eager" srcset="assets/images/img2-p-500.png 500w, assets/images/img2.png 885w" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-layout-grid grid-features-small">
                <div id="w-node-_1792c06e-b16b-7828-6976-dd20e4ffd5a5-c53bab0d" data-w-id="1792c06e-b16b-7828-6976-dd20e4ffd5a5" class="wrapper">
                  <div style={{"opacity":"0"}} class="blurred-color"></div>
                  <div class="content-wrapper">
                    <div class="feature"><img class="image-feature" src="assets/images/img3.png" alt="" style={{"opacity":"1"}} sizes="(max-width: 479px) 79vw, (max-width: 767px) 86vw, 88vw" data-w-id="1792c06e-b16b-7828-6976-dd20e4ffd5a9" loading="eager" srcset="assets/images/img3-p-500.png 500w, assets/images/img3.png 774w" />
                      <div class="block-feature">
                        <h4 class="heading-feature">Instant Transactions</h4>
                        <p class="paragraph-feature">Operate in real time with the best technology to process deposits and withdrawals instantly</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="w-node-_1792c06e-b16b-7828-6976-dd20e4ffd5af-c53bab0d" data-w-id="1792c06e-b16b-7828-6976-dd20e4ffd5af" class="wrapper">
                  <div style={{"opacity":"0"}} class="blurred-color"></div>
                  <div class="content-wrapper">
                    <div class="feature">
                      <div class="block-feature top">
                        <h4 class="heading-feature">Privacy-First</h4>
                        <p class="paragraph-feature">Your data and transactions are protected with advanced encryption, ensuring maximum confidentiality.</p>
                      </div><img class="image-feature" src="assets/images/img4.png" alt="" style={{"opacity":"1"}} sizes="(max-width: 479px) 79vw, (max-width: 767px) 86vw, 88vw" data-w-id="1792c06e-b16b-7828-6976-dd20e4ffd5b8" loading="eager" srcset="assets/images/img4.png 500w, assets/images/img4.png 774w" />
                    </div>
                  </div>
                </div>
                <div id="w-node-_1792c06e-b16b-7828-6976-dd20e4ffd5b9-c53bab0d" data-w-id="1792c06e-b16b-7828-6976-dd20e4ffd5b9" class="wrapper">
                  <div style={{"opacity":"0"}} class="blurred-color"></div>
                  <div class="content-wrapper">
                    <div class="feature"><img class="image-feature" src="assets/images/img5.png" alt="" style={{"opacity":"1"}} sizes="(max-width: 479px) 79vw, (max-width: 767px) 86vw, 88vw" data-w-id="1792c06e-b16b-7828-6976-dd20e4ffd5bd" loading="eager" srcset="assets/images/img5-p-500.png 500w, assets/images/img5.png 774w" />
                      <div class="block-feature">
                        <h4 class="heading-feature">24/7 Support</h4>
                        <p class="paragraph-feature">Our team is available to help you anytime, no matter your time zone.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{"opacity":"0"}} class="blur-top"></div>
    </div>
    <div class="section">
      <div style={{"opacity":"0"}} class="content">
        <div class="w-layout-grid grid">
          <div id="w-node-_5748c9eb-76ed-0e30-f708-a3242efb407f-c53bab0d" class="block">
            <div class="block-heading-left">
              <div class="subtitle">Interoperability</div>
              <h2 class="heading">Live the Future of Finance with Swapbits</h2>
            </div>
            <p class="paragraph">Explore secure and efficient cryptocurrency exchange solutions, built for users of all skill levels.</p>
            <a href="login" class="button w-button">Sign up</a>
          </div>
          <div id="w-node-_5748c9eb-76ed-0e30-f708-a3242efb4089-c53bab0d" data-w-id="5748c9eb-76ed-0e30-f708-a3242efb4089" class="wrapper-circle">
            <div class="content-wrapper-circle">
              <div class="circle-b">
                <div data-w-id="5748c9eb-76ed-0e30-f708-a3242efb408c" class="area-b1">
                  <div class="color-circle"></div>
                </div>
                <div data-w-id="5748c9eb-76ed-0e30-f708-a3242efb408e" class="area-b2">
                  <div class="color-circle"></div>
                </div>
                <div data-w-id="5748c9eb-76ed-0e30-f708-a3242efb4090" class="area-main-b3">
                  <div class="color-circle line"></div>
                </div>
                <div data-w-id="5748c9eb-76ed-0e30-f708-a3242efb4092" class="area-b4">
                  <div class="color-circle"></div>
                </div>
                <div data-w-id="5748c9eb-76ed-0e30-f708-a3242efb4094" class="area-b5">
                  <div class="color-circle"></div>
                </div>
              </div>
            </div>
            <div class="blur-block-b"></div>
          </div>
        </div>
      </div>
    </div>
    <div className="section-footer">
          <div className="content">
            <div className="w-layout-grid grid-footer">
              <div
                id="w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fb3-12d14fb0"
                className="block-footer"
              >
                <p className="paragraph-footer">
                  Swapbits Financial: Your trusted platform to manage
                  cryptocurrencies safely and efficiently. We are committed to
                  offering an exceptional user experience and complying with the
                  most demanding regulatory standards. <br />
                  VASP LICENCE RDWW-1584
                </p>
                <h4></h4>
                <a href="contact" className="link-block w-inline-block">
                  <img
                    src="assets/images/icon_6.svg"
                    loading="eager"
                    alt=""
                    className="icon-link"
                  />
                  <div className="text-link">Support@swapbits.io</div>
                </a>
              </div>
              <div
                id="w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fbb-12d14fb0"
                className="block-footer"
              >
                <div className="subtitle-footer">Quick Links</div>
                <a href="features" className="link-footer">
                  Features
                </a>
                <a href="blog" className="link-footer">
                  Soon
                </a>
                <a href="contact" className="link-footer">
                  Contact
                </a>
              </div>
              <div
                id="w-node-_92ed4044-9cfe-904b-e616-3634ff09c860-12d14fb0"
                className="block-footer"
              >
                <div className="subtitle-footer">COMPANY</div>
                <a href="terms" className="link-footer">
                  Terms and conditions
                </a>
                <a href="privacy-terms" className="link-footer">
                  Privacy terms
                </a>
              </div>
            </div>
            <div className="block-footer-down">
              <div className="text-footer-down">
                <a
                  href="https://swapbits.io/"
                  target="_blank"
                  className="link-footer-down"
                >
                  © 2025 Swapbits Financial.All rights reserved
                  <br />
                </a>
              </div>
            </div>
          </div>
        </div>
  </div>
    </div>
  );
}

export default Feacture;