import React, { useState, useEffect } from 'react';
import "../styles/UserProfileDashboard.css";
import axios from "axios";
import TwoFactorAuth from './TwoFactorAuth';
import ChangePass from './ChangePass';
import Profile from './dashboardprofile';
import Report from './report';
const UserProfileDashboard = () => {
  const [activeTab, setActiveTab] = useState('profile');
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [document1, setDocument1] = useState("");
    const [document2, setDocument2] = useState("");
    const [birth, setBirthDate] = useState("");
    const [sexDate, setSexDate] = useState("");
    const [kycLvl, setkycLvl] = useState("");
    const [userInfo, setUserInfo] = useState({});
    const [externalUserId, setExternalUserId] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
      const fetchData = async () => {
        try {
        const token = localStorage.getItem("jwt");
  
          const response = await fetch("https://www.swapbits.io/api/auth/users/info", {
              method: "GET",
              headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            });
          const data = await response.json();
          console.log(data)
          setName(data.name);
          setEmail(data.email);
          setDocument1(data.documentNumber);
          setDocument2(data.documentNumber2);
          setBirthDate(data.birth);
          setSexDate(data.sex);
          setkycLvl(data.kycLvl);
          setUserInfo(data);
  
          
        } catch (error) {
          console.error("Error al cargar datos de la API:", error);
        }
      };
  
      fetchData();
    }, []);

    const fetchUserInfo = async () => {
        try {
          const token = localStorage.getItem("jwt");
          const response = await axios.get("https://www.swapbits.io/api/auth/users/info", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUserInfo(response.data); // Actualiza el estado del usuario
        } catch (error) {
          console.error("Error al obtener la información del usuario:", error);
        }
      };
      



    // kyc medium
    
      useEffect(() => {
        const token = localStorage.getItem("jwt");
    
        if (!token) {
          setErrorMessage("Usuario no autenticado. Por favor, inicie sesión.");
          return;
        }
    
        try {
          const decodedToken = JSON.parse(atob(token.split(".")[1]));
          setExternalUserId(`user${decodedToken.userId}`); // Asegúrate de que `userId` esté presente en el JWT
        } catch (error) {
          setErrorMessage("Error decodificando el token.");
          console.error("JWT Error:", error);
        }
      }, []);

      const startProcessLvl2 = async () => {
        if (!externalUserId) {
          setErrorMessage("No se encontró un ID de usuario válido.");
          return;
        }
      
        setErrorMessage(""); // Limpiar errores previos
      
        try {
          // Verificar JWT
          const jwt = localStorage.getItem("jwt");
          if (!jwt) {
            throw new Error("No se encontró un token JWT válido.");
          }
      
          // Verificar datos de contacto
          
      
          // Obtener Access Token
          const tokenResponse = await axios.post(
            "https://www.swapbits.io/api/kyc/access-token",
            { levelName: "enhanced-kyc-level" },
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            }
          );
      
          const accessToken = tokenResponse.data.accessToken;
          const lvlName = "enhanced-kyc-level"
          console.log("Access Token obtenido:", accessToken);
      
          // Inyectar y cargar el script de Sumsub solo si no existe
          if (!document.querySelector('script[src="https://static.sumsub.com/idensic/static/sns-websdk-builder.js"]')) {
            const script = document.createElement("script");
            script.src = "https://static.sumsub.com/idensic/static/sns-websdk-builder.js";
            script.onload = () => launchWebSdk(accessToken, lvlName);
            document.body.appendChild(script);
          } else {
            launchWebSdk(accessToken);
          }
        } catch (error) {
          console.error("Error en el proceso:", error.response?.data || error.message);
          setErrorMessage(error.message || "Error desconocido.");
        }
      };
      
      // Función para lanzar el WebSDK
      const launchWebSdk = (accessToken, lvlName) => {
        const snsWebSdkInstance = window.snsWebSdk
          .init(accessToken, async () => {
            try {
              const jwt = localStorage.getItem("jwt");
              if (!jwt) {
                throw new Error("No se encontró un token JWT válido para renovar el Access Token.");
              }
      
              const tokenResponse = await axios.post(
                "https://www.swapbits.io/api/kyc/access-token",
                { levelName: lvlName },
                {
                  headers: {
                    Authorization: `Bearer ${jwt}`,
                  },
                }
              );
      
              return tokenResponse.data.accessToken;
            } catch (error) {
              console.error("Error obteniendo nuevo Access Token:", error.response?.data || error.message);
              throw error;
            }
          })
          .withConf({
            lang: "es"
          })
          .withOptions({ addViewportTag: false, adaptIframeHeight: true })
          .on("idCheck.onStepCompleted", (payload) => {
            console.log("onStepCompleted", payload);
          })
          .on("idCheck.onError", (error) => {
            console.error("Error en el WebSDK:", error);
            setErrorMessage("Hubo un error en el WebSDK.");
          })
          .onMessage((type, payload) => {
            console.log("onMessage", type, payload);
          })
          .build();
      
        snsWebSdkInstance.launch("#sumsub-websdk-container");
      };
      

  return (
    <div className="dashboard-container">
      <main className="account-container">
        <h2 className="title">Cuenta</h2>
        <div className="multiple-toggle">
          <button
            className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
            onClick={() => handleTabChange('profile')}
          >
            Perfil
          </button>
          <button
            className={`tab-button ${activeTab === 'security' ? 'active' : ''}`}
            onClick={() => handleTabChange('security')}
          >
            Seguridad
          </button>
          <button
            className={`tab-button ${activeTab === 'limits' ? 'active' : ''}`}
            onClick={() => handleTabChange('limits')}
          >
            Límites
          </button>
          <button
            className={`tab-button ${activeTab === 'activity' ? 'active' : ''}`}
            onClick={() => handleTabChange('activity')}
          >
            Reporte de actividad
          </button>
          {/* <button
            className={`tab-button ${activeTab === 'notifications' ? 'active' : ''}`}
            onClick={() => handleTabChange('notifications')}
          >
            Notificaciones
          </button> */}
        </div>

        <div className="tab-content">
          {activeTab === 'profile' && (
            < Profile phone={userInfo.phone} pais={userInfo.pais} kycLvl={kycLvl} name={name} userInfo={userInfo} email={email} birth={birth} document1={document1} document2={document2}  sexDate={sexDate} handleTabChange={handleTabChange}/>
          
          
          )}
          {activeTab === 'security' && (
            <div className="security-content">
              <h3>Seguridad</h3>
              <div className="auth-flex-container">
                {userInfo.twoauth == false ? <TwoFactorAuth onVerified={fetchUserInfo} />
 : <div></div>}
                <ChangePass></ChangePass>
  
</div>

            </div>
          )}
          {activeTab === 'limits' && (
  <div className="limits-content">
    <h3 className="limits-header">Límites</h3>
    <div className="limits-summary">
      {/* <div className="limit-monthly">
        <div className="limit-monthly-header">
          <span className="limit-label">Límite mensual</span>
          <div className="limit-values">
            <span className="limit-used">3.000 </span>
            <span className="limit-total">/ 5.000 EUR</span>
          </div>
          <div className="limit-progress">
            <progress max="100" value="75" className="progress-monthly"></progress>
          </div>
        </div>
      </div> */}

      <div className="limit-annual">
        <div className="limit-annual-header">
          <span className="limit-label">Límite anual</span>
          <div className="limit-values">
            {/* <span className="limit-used"> </span> */}
            {console.log(userInfo)}
            <span className="limit-total">{userInfo.limits[0].annual.amount}/{userInfo.limits[0].annual.limit} EUR</span>
          </div>
          <div className="limit-progress">
          <progress max="100" value={(userInfo.limits[0].annual.amount / userInfo.limits[0].annual.limit) * 100} className="progress-annual"></progress>

          </div>
        </div>
      </div>
    </div>

    <div className="limits-info">
      <div className="info-item">
        <span className="info-icon">ℹ️</span>
        <span className="info-text">Los límites aplican a las operaciones de compra o venta de criptomonedas contra fiat (EUR).</span>
      </div>
      <div className="info-item">
        <span className="info-icon">ℹ️</span>
        <span className="info-text">¡Las operaciones cripto a cripto no tienen límite!</span>
      </div>
    </div>

    <div className="limits-upgrade">
  <h2 className="upgrade-header">Ampliar límites</h2>
  <div className="upgrade-levels">
    <div className="level-basic">
      <h2 className="level-title">Nivel Basico</h2>
      <p className="level-description">Para acceder a este nivel solo es necesario que realices nuestra prueba de vida.</p>
      <p className="level-amount">Monto para operar:</p>
      {/* <p className="level-monthly">Mensual: <strong>4.500,00 EUR</strong></p> */}
      <p className="level-annual">Anual: <strong>10.000,00 EUR</strong></p>
      {
        kycLvl == "Basic" || kycLvl == "Intermediate" || kycLvl == "Full" ? (
          <p className="level-status">Ya estás en este nivel</p>
        ) : (
          <button className="level-button-basic">Enviar documentación</button>
        )
      }
    </div>

    <div className="level-intermediate">
      <h2 className="level-title">Nivel Intermedio</h2>
      <p className="level-description">Para acceder a este nivel es necesario que presentes documentación para validar tu domicilio.</p>
      <p className="level-amount">Monto para operar:</p>
      {/* <p className="level-monthly">Mensual: <strong>60.000,00 EUR</strong></p> */}
      <p className="level-annual">Anual: <strong>720.000,00 EUR</strong></p>
      {
        kycLvl == "Intermediate" || kycLvl == "Full" ? (
          <p className="level-status">Ya estás en este nivel</p>
        ) : (
          <button className="level-button-intermediate" onClick={startProcessLvl2}>Enviar documentación</button>
        )
      }
    </div>

    <div className="level-full">
      <h2 className="level-title">Nivel Full</h2>
      <p className="level-description">Para acceder a este nivel es necesario que presentes documentación para validar tus ingresos.</p>
      <p className="level-amount">Monto para operar:</p>
      {/* <p className="level-monthly">Mensual: <strong>Según avales presentados</strong></p> */}
      <p className="level-annual">Anual: <strong>Según avales presentados</strong></p>
      {
        kycLvl == "Full" ? (
          <p className="level-status">Ya estás en este nivel</p>
        ) : (
          <button className="level-button-full">Enviar documentación</button>
        )
      }
    </div>
  </div>
</div>
<div id="sumsub-websdk-container"></div>


  </div>
  
)}

          {activeTab === 'activity' && (
            <div className="activity-content">
              <h3>Reporte de actividad</h3>
              < Report />
            </div>
          )}
          {activeTab === 'notifications' && (
            <div className="notifications-content">
              <h3>Notificaciones</h3>
              <p>Gestión de notificaciones pendiente...</p>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default UserProfileDashboard;
