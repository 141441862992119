import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/activites.css";
import en from "../langs/en-us.json";
import es from "../langs/es-es.json";
import pl from "../langs/pl-pl.json";
import pt from "../langs/pt-br.json";
import zh from "../langs/zh-cn.json";


const languages = {
  en: { name: "English", flag: "/assets/images/flags/us.png", data: en },
  es: { name: "Español", flag: "/assets/images/flags/es.png", data: es },
  pl: { name: "Polski", flag: "/assets/images/flags/pl.png", data: pl },
  pt: { name: "Português", flag: "/assets/images/flags/br.png", data: pt },
  zh: { name: "中文", flag: "/assets/images/flags/cn.png", data: zh },
}
const Activities = () => {
  const [transactions, setTransactions] = useState([]);
  const [displayedTransactions, setDisplayedTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [tab, setTab] = useState("all"); // Controlar la pestaña activa
  const itemsPerPage = 10;

  const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lang") || "en");
      const [translations, setTranslations] = useState(languages[selectedLang].data);
      useEffect(() => {
        // Guardar idioma seleccionado en localStorage
        localStorage.setItem("lang", selectedLang);
        setTranslations(languages[selectedLang].data);
      }, [selectedLang]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = localStorage.getItem("jwt");
        const response = await axios.get(
          "https://www.swapbits.io/api/wallets/movements",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data) {
          setTransactions(response.data);
          console.log(response.data.slice(0, itemsPerPage))
          setDisplayedTransactions(response.data.slice(0, itemsPerPage));
        } else {
          setTransactions([]);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, []);

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    const newDisplayedTransactions = transactions.slice(
      0,
      nextPage * itemsPerPage
    );
    setDisplayedTransactions(newDisplayedTransactions);
    setCurrentPage(nextPage);
  };

  const handleTransactionClick = (transaction) => {
    console.log(transaction);
    setSelectedTransaction(transaction);
  };

  const closeModal = () => {
    setSelectedTransaction(null);
  };
  const handleClick = () => {
    const sideNavbar = document.querySelector(".side-navbar-wrapper");
    if (sideNavbar) {
      sideNavbar.classList.toggle("active");
    }
  };
  const formatNumber = (amount, currency) => {
    if (!amount) return "0";

    // Si es ARS u otra moneda FIAT, aplicamos el formato 100.000,00
    if (currency === "ARS") {
      return amount > 1
        ? new Intl.NumberFormat("es-AR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(amount)
        : amount.toFixed(2);
    }

    // Para criptomonedas, mostramos hasta 4 decimales sin cortar
    return amount > 1 ? amount.toFixed(2) : amount.toFixed(4);
  };


  const changeTab = (tab) => {
    setTab(tab);
    
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="sections-wrapper">
          <div className="activities-wrapper">
            <div className="container">
              <div className="activities-table-wrapper">
                <div className="market-trades-tab-wrapper">
                  <div className="dashboard-chart-title-wrap history-title-wrapper">
                    <h2 className="dashboard-chart-title">{translations.history}</h2>
                  </div>
                  <div
                    data-current="Tab 1"
                    data-easing="ease"
                    data-duration-in={300}
                    data-duration-out={100}
                    className="market-trades-tab w-tabs"
                  >
                    <div className="custom-tab-menu">
                      <a
                        data-w-tab="Tab 1"
                        className={`custom-tab-link ${
                          tab === "all" ? "active-tab" : ""
                        }`}
                        onClick={() => changeTab("all")}
                      >
                        <div>{translations.all}</div>
                      </a>
                      <a
                        data-w-tab="Tab 2"
                        className={`custom-tab-link ${
                          tab === "deposit" ? "active-tab" : ""
                        }`}
                        onClick={() => changeTab("deposit")}
                      >
                        <div>{translations.deposit}</div>
                      </a>
                      <a
                        data-w-tab="Tab 3"
                        className={`custom-tab-link ${
                          tab === "withdrawal" ? "active-tab" : ""
                        }`}
                        onClick={() => changeTab("withdrawal")}
                      >
                        <div>{translations.withdraw}</div>
                      </a>
                    </div>

                    <div className="market-trades-tab-content w-tab-content">
                      {tab === "all" ? (
                        <div
                          data-w-tab="Tab 1"
                          className="market-trades-tab-pane w-tab-pane w--tab-active"
                        >
                          <div className="activities-table-singel-wrapper">
                            <div className="activities-table-heading-wrapper">
                              <div className="activities-table">
                                <div className="w-layout-grid activities-table-grid">
                                  <div
                                    id="w-node-ec3e70c6-3ab2-bbc2-3f58-7a9be272739a-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="transaction-id-wrapper">
                                      <div className="transaction-id-form-block w-form">
                                        <span
                                          className="checkbox-label w-form-label"
                                          htmlFor="checkbox"
                                        >
                                          {translations.transaction_id}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    id="w-node-_7a390ebd-7a5e-8eb3-24c5-c442b173448e-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="activities-table-heading-singel">
                                      <h3 className="activities-table-heading">
                                        {translations.type}
                                      </h3>
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-e673bd3e-9457-24a3-2a9a-4f230b3543f2-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="activities-table-heading-singel">
                                      <h3 className="activities-table-heading">
                                        {translations.date}
                                      </h3>
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_6a78dd19-118c-e558-9e20-8eb8ccbf4033-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="activities-table-heading-singel">
                                      <h3 className="activities-table-heading">
                                        {translations.amount}
                                      </h3>
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_64ed8a59-5076-dd52-45a9-5a01e4b4fd25-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="activities-table-heading-singel">
                                      <h3 className="activities-table-heading">
                                        {translations.status}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Contenido de la tabla */}
                            {transactions.map((transaction, index) => (
                              <div className="activities-table-content">
                                <div className="w-dyn-list">
                                  {displayedTransactions.length > 0 ? (
                                    <div
                                      role="list"
                                      className="activities-table-collection-list w-dyn-items"
                                    >
                                      <div
                                        role="listitem"
                                        className="activities-table-collection-item w-dyn-item"
                                      >
                                        <div className="activities-table-content-singel">
                                          <div className="activities-table-item">
                                            <div
                                              className="activities-table"
                                              key={index}
                                              onClick={() =>
                                                handleTransactionClick(
                                                  transaction
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div className="w-layout-grid activities-table-grid">

                                                <div
                                                  id="w-node-b02f9df4-1c35-6137-845f-0800be1ff4df-4ce57e1b"
                                                  className="activities-table-singel"
                                                >
                                                  <div className="transaction-id-wrapper">
                                                    <div className="transaction-id-form-block w-form">
                                                      <span className="transaction-id">
                                                        {transaction.id
                                                          ? `${transaction.id.slice(
                                                              0,
                                                              8
                                                            )}...${transaction.id.slice(
                                                              -8
                                                            )}`
                                                          : translations.none}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>

                                                <a
                                                  className="activities-table-link-block w-inline-block"
                                                  style={{
                                                    textTransform: "capitalize",
                                                    fontWeight: "bold",
                                                    color: "#FFFFFF",
                                                  }}
                                                >
                                                  <div className="cryptocurrency-table-token">
                                                    <div>
                                                      {transaction.type
                                                        ? translations[transaction.type]
                                                        : translations.unknown}
                                                    </div>
                                                    <div className="table-data-text-block w-dyn-bind-empty" />
                                                  </div>
                                                </a>

                                                <a
                                                  id="w-node-_5d990926-53cc-24ea-e310-dec86f2b989a-4ce57e1b"
                                                  className="activities-table-link-block w-inline-block"
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "#FFFFFF",
                                                  }}
                                                >
                                                  <div className="table-data-text-block w-dyn-bind-empty" />
                                                  {transaction.createdAt
                                                    ? new Date(
                                                        transaction.createdAt
                                                      ).toLocaleString(
                                                        "en-GB",
                                                        {
                                                          day: "2-digit",
                                                          month: "short",
                                                          year: "numeric",
                                                          hour: "2-digit",
                                                          minute: "2-digit",
                                                          hour12: false,
                                                        }
                                                      )
                                                    : translations.unknown}
                                                </a>

                                                <a
                                                  id="w-node-b9fc485b-0380-bc07-34d9-9dec44727a8a-4ce57e1b"
                                                  className="activities-table-link-block w-inline-block"
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "#333",
                                                  }}
                                                >
                                                  <div className="table-data-text-block w-dyn-bind-empty" />

                                                  {transaction.type ===
                                                  "convert" ? (
                                                    <div className="transaction-convert">
                                                      <span className="amount-out">
                                                        {formatNumber(
                                                          transaction.amountFrom,
                                                          transaction.cryptoFrom
                                                        )}{" "}
                                                        {transaction.cryptoFrom}
                                                      </span>
                                                      <span className="amount-in">
                                                        {formatNumber(
                                                          transaction.amountTo,
                                                          transaction.cryptoTo
                                                        )}{" "}
                                                        {transaction.cryptoTo}
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className={`transaction-${transaction.type}`}
                                                    >
                                                      <span
                                                        className={
                                                          transaction.type ===
                                                          "deposit"
                                                            ? "amount-in"
                                                            : "amount-out"
                                                        }
                                                      >
                                                        {formatNumber(
                                                          transaction.amount,
                                                          transaction.crypto
                                                        )}{" "}
                                                        {transaction.crypto ||
                                                          ""}
                                                      </span>
                                                    </div>
                                                  )}
                                                </a>

                                                <div
                                                  id="w-node-db116570-4621-5918-2a04-34fee5ccc644-4ce57e1b"
                                                  className="status-wrapper"
                                                >
                                                  <span
                                                    className={`status-${transaction.status}`}
                                                  >
                                                    {translations[transaction.status]}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="w-dyn-empty">
                                      <div>{translations.no_items_found}</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )).slice(
                              0,
                              (currentPage) * itemsPerPage
                            )}
                          </div>
                        </div>
                      ) : tab === "deposit" ? (
                        <div
                          data-w-tab="Tab 2"
                          className="market-trades-tab-pane w-tab-pane w--tab-active"
                        >
                          <div className="activities-table-singel-wrapper">
                            <div className="activities-table-heading-wrapper">
                              <div className="activities-table">
                                <div className="w-layout-grid activities-table-grid">
                                  <div
                                    id="w-node-ec3e70c6-3ab2-bbc2-3f58-7a9be272739a-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="transaction-id-wrapper">
                                      <div className="transaction-id-form-block w-form">
                                        <span
                                          className="checkbox-label w-form-label"
                                          htmlFor="checkbox"
                                        >
                                          {translations.transaction_id}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    id="w-node-_7a390ebd-7a5e-8eb3-24c5-c442b173448e-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="activities-table-heading-singel">
                                      <h3 className="activities-table-heading">
                                        {translations.type}
                                      </h3>
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-e673bd3e-9457-24a3-2a9a-4f230b3543f2-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="activities-table-heading-singel">
                                      <h3 className="activities-table-heading">
                                        {translations.date}
                                      </h3>
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_6a78dd19-118c-e558-9e20-8eb8ccbf4033-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="activities-table-heading-singel">
                                      <h3 className="activities-table-heading">
                                        {translations.amount}
                                      </h3>
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_64ed8a59-5076-dd52-45a9-5a01e4b4fd25-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="activities-table-heading-singel">
                                      <h3 className="activities-table-heading">
                                        {translations.status}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Contenido de la tabla */}

                            {transactions.filter(e => e.type == "deposit").map((transaction, index) => (
                              <div className="activities-table-content">
                                <div className="w-dyn-list">
                                  {displayedTransactions.length > 0 ? (
                                    <div
                                      role="list"
                                      className="activities-table-collection-list w-dyn-items"
                                    >
                                      <div
                                        role="listitem"
                                        className="activities-table-collection-item w-dyn-item"
                                      >
                                        <div className="activities-table-content-singel">
                                          <div className="activities-table-item">
                                            <div
                                              className="activities-table"
                                              key={index}
                                              onClick={() =>
                                                handleTransactionClick(
                                                  transaction
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div className="w-layout-grid activities-table-grid">
                                                <div
                                                  id="w-node-b02f9df4-1c35-6137-845f-0800be1ff4df-4ce57e1b"
                                                  className="activities-table-singel"
                                                >
                                                  <div className="transaction-id-wrapper">
                                                    <div className="transaction-id-form-block w-form">
                                                      <span className="transaction-id">
                                                        {transaction.id
                                                          ? `${transaction.id.slice(
                                                              0,
                                                              8
                                                            )}...${transaction.id.slice(
                                                              -8
                                                            )}`
                                                          : "None"}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>

                                                <a
                                                  className="activities-table-link-block w-inline-block"
                                                  style={{
                                                    textTransform: "capitalize",
                                                    fontWeight: "bold",
                                                    color: "#FFFFFF",
                                                  }}
                                                >
                                                  <div className="cryptocurrency-table-token">
                                                    <div>
                                                      {transaction.type
                                                        ? transaction.type
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                          transaction.type.slice(
                                                            1
                                                          )
                                                        : "Unknown"}
                                                    </div>
                                                    <div className="table-data-text-block w-dyn-bind-empty" />
                                                  </div>
                                                </a>

                                                <a
                                                  id="w-node-_5d990926-53cc-24ea-e310-dec86f2b989a-4ce57e1b"
                                                  className="activities-table-link-block w-inline-block"
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "#FFFFFF",
                                                  }}
                                                >
                                                  <div className="table-data-text-block w-dyn-bind-empty" />
                                                  {transaction.createdAt
                                                    ? new Date(
                                                        transaction.createdAt
                                                      ).toLocaleString(
                                                        "en-GB",
                                                        {
                                                          day: "2-digit",
                                                          month: "short",
                                                          year: "numeric",
                                                          hour: "2-digit",
                                                          minute: "2-digit",
                                                          hour12: false,
                                                        }
                                                      )
                                                    : "Unknown"}
                                                </a>

                                                <a
                                                  id="w-node-b9fc485b-0380-bc07-34d9-9dec44727a8a-4ce57e1b"
                                                  className="activities-table-link-block w-inline-block"
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "#333",
                                                  }}
                                                >
                                                  <div className="table-data-text-block w-dyn-bind-empty" />

                                                  {transaction.type ===
                                                  "convert" ? (
                                                    <div className="transaction-convert">
                                                      <span className="amount-out">
                                                        {formatNumber(
                                                          transaction.amountFrom,
                                                          transaction.cryptoFrom
                                                        )}{" "}
                                                        {transaction.cryptoFrom}
                                                      </span>
                                                      <span className="amount-in">
                                                        {formatNumber(
                                                          transaction.amountTo,
                                                          transaction.cryptoTo
                                                        )}{" "}
                                                        {transaction.cryptoTo}
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className={`transaction-${transaction.type}`}
                                                    >
                                                      <span
                                                        className={
                                                          transaction.type ===
                                                          "deposit"
                                                            ? "amount-in"
                                                            : "amount-out"
                                                        }
                                                      >
                                                        {formatNumber(
                                                          transaction.amount,
                                                          transaction.crypto
                                                        )}{" "}
                                                        {transaction.crypto ||
                                                          ""}
                                                      </span>
                                                    </div>
                                                  )}
                                                </a>

                                                <div
                                                  id="w-node-db116570-4621-5918-2a04-34fee5ccc644-4ce57e1b"
                                                  className="status-wrapper"
                                                >
                                                  <span
                                                    className={`status-${transaction.status}`}
                                                  >
                                                    {transaction.status
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      transaction.status.slice(
                                                        1
                                                      )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="w-dyn-empty">
                                      <div>{translations.no_items_found}</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )).slice(
                              0,
                              (currentPage) * itemsPerPage
                            )}
                          </div>
                        </div>
                      ) : tab === "withdrawal" ? (
                        <div
                          data-w-tab="Tab 3"
                          className="market-trades-tab-pane w-tab-pane w--tab-active"
                        >
                          <div className="activities-table-singel-wrapper">
                            <div className="activities-table-heading-wrapper">
                              <div className="activities-table">
                                <div className="w-layout-grid activities-table-grid">
                                  <div
                                    id="w-node-ec3e70c6-3ab2-bbc2-3f58-7a9be272739a-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="transaction-id-wrapper">
                                      <div className="transaction-id-form-block w-form">
                                        <span
                                          className="checkbox-label w-form-label"
                                          htmlFor="checkbox"
                                        >
                                          {translations.transaction_id}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    id="w-node-_7a390ebd-7a5e-8eb3-24c5-c442b173448e-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="activities-table-heading-singel">
                                      <h3 className="activities-table-heading">
                                        {translations.type}
                                      </h3>
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-e673bd3e-9457-24a3-2a9a-4f230b3543f2-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="activities-table-heading-singel">
                                      <h3 className="activities-table-heading">
                                        {translations.date}
                                      </h3>
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_6a78dd19-118c-e558-9e20-8eb8ccbf4033-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="activities-table-heading-singel">
                                      <h3 className="activities-table-heading">
                                        {translations.amount}
                                      </h3>
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_64ed8a59-5076-dd52-45a9-5a01e4b4fd25-4ce57e1b"
                                    className="activities-table-singel"
                                  >
                                    <div className="activities-table-heading-singel">
                                      <h3 className="activities-table-heading">
                                        {translations.status}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Contenido de la tabla */}
                            {transactions.filter(e => e.type == "withdrawal").map((transaction, index) => (
                              <div className="activities-table-content">
                                <div className="w-dyn-list">
                                  {displayedTransactions.length > 0 ? (
                                    <div
                                      role="list"
                                      className="activities-table-collection-list w-dyn-items"
                                    >
                                      <div
                                        role="listitem"
                                        className="activities-table-collection-item w-dyn-item"
                                      >
                                        <div className="activities-table-content-singel">
                                          <div className="activities-table-item">
                                            <div
                                              className="activities-table"
                                              key={index}
                                              onClick={() =>
                                                handleTransactionClick(
                                                  transaction
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div className="w-layout-grid activities-table-grid">
                                                <div
                                                  id="w-node-b02f9df4-1c35-6137-845f-0800be1ff4df-4ce57e1b"
                                                  className="activities-table-singel"
                                                >
                                                  <div className="transaction-id-wrapper">
                                                    <div className="transaction-id-form-block w-form">
                                                      <span className="transaction-id">
                                                        {transaction.id
                                                          ? `${transaction.id.slice(
                                                              0,
                                                              8
                                                            )}...${transaction.id.slice(
                                                              -8
                                                            )}`
                                                          : "None"}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>

                                                <a
                                                  className="activities-table-link-block w-inline-block"
                                                  style={{
                                                    textTransform: "capitalize",
                                                    fontWeight: "bold",
                                                    color: "#FFFFFF",
                                                  }}
                                                >
                                                  <div className="cryptocurrency-table-token">
                                                    <div>
                                                      {transaction.type
                                                        ? transaction.type
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                          transaction.type.slice(
                                                            1
                                                          )
                                                        : "Unknown"}
                                                    </div>
                                                    <div className="table-data-text-block w-dyn-bind-empty" />
                                                  </div>
                                                </a>

                                                <a
                                                  id="w-node-_5d990926-53cc-24ea-e310-dec86f2b989a-4ce57e1b"
                                                  className="activities-table-link-block w-inline-block"
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "#FFFFFF",
                                                  }}
                                                >
                                                  <div className="table-data-text-block w-dyn-bind-empty" />
                                                  {transaction.createdAt
                                                    ? new Date(
                                                        transaction.createdAt
                                                      ).toLocaleString(
                                                        "en-GB",
                                                        {
                                                          day: "2-digit",
                                                          month: "short",
                                                          year: "numeric",
                                                          hour: "2-digit",
                                                          minute: "2-digit",
                                                          hour12: false,
                                                        }
                                                      )
                                                    : "Unknown"}
                                                </a>

                                                <a
                                                  id="w-node-b9fc485b-0380-bc07-34d9-9dec44727a8a-4ce57e1b"
                                                  className="activities-table-link-block w-inline-block"
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "#333",
                                                  }}
                                                >
                                                  <div className="table-data-text-block w-dyn-bind-empty" />

                                                  {transaction.type ===
                                                  "convert" ? (
                                                    <div className="transaction-convert">
                                                      <span className="amount-out">
                                                        {formatNumber(
                                                          transaction.amountFrom,
                                                          transaction.cryptoFrom
                                                        )}{" "}
                                                        {transaction.cryptoFrom}
                                                      </span>
                                                      <span className="amount-in">
                                                        {formatNumber(
                                                          transaction.amountTo,
                                                          transaction.cryptoTo
                                                        )}{" "}
                                                        {transaction.cryptoTo}
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className={`transaction-${transaction.type}`}
                                                    >
                                                      <span
                                                        className={
                                                          transaction.type ===
                                                          "deposit"
                                                            ? "amount-in"
                                                            : "amount-out"
                                                        }
                                                      >
                                                        {formatNumber(
                                                          transaction.amount,
                                                          transaction.crypto
                                                        )}{" "}
                                                        {transaction.crypto ||
                                                          ""}
                                                      </span>
                                                    </div>
                                                  )}
                                                </a>

                                                <div
                                                  id="w-node-db116570-4621-5918-2a04-34fee5ccc644-4ce57e1b"
                                                  className="status-wrapper"
                                                >
                                                  <span
                                                    className={`status-${transaction.status}`}
                                                  >
                                                    {transaction.status
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      transaction.status.slice(
                                                        1
                                                      )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="w-dyn-empty">
                                      <div>{translations.no_items_found}</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )).slice(
                              0,
                              (currentPage) * itemsPerPage
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

<p 
  onClick={handleLoadMore} 
  className="load-more"
  
>
  {translations.load_more}
</p>

                     
                    </div>
                  </div>
                </div>
                <div className="cryptocurrency-prices-select-wrapper absulote" />
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container">
              <div className="footer-details-wrapper">
                <div className="footer-text-block">
                  <a
                    href="http://webflow.com"
                    target="_blank"
                    className="footer-text-link"
                  >
                    <strong>
                      © 2025 Swapbits Financial.All rights reserved
                    </strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="topbar-wrapper">
          <div className="header-content">
            <div className="header-toggle-wrapper">
              <div className="header-toggle" onClick={handleClick}>
                <img
                  src="../assets/images/Toggle.png"
                  loading="lazy"
                  alt=""
                  className="header-toggle-img"
                ></img>
              </div>

              <div className="header-title-wrapper">
                <h1 className="header-title">{translations.activities}</h1>
              </div>
            </div>
            <div className="language-selector">
          <select
            className="language-dropdown"
            value={selectedLang}
            onChange={(e) => setSelectedLang(e.target.value)}
          >
            {Object.entries(languages).map(([key, lang]) => (
              <option key={key} value={key}>
                {lang.name}
              </option>
            ))}
          </select>
          <img
            src={languages[selectedLang].flag}
            alt={languages[selectedLang].name}
            className="language-flag"
          />
        </div>
          </div>
        </div>
      </div>
      <div
        data-w-id="ad850566-76af-f3eb-5277-07c1948f8ecc"
        className="preloader"
      >
        <img
          src="images/Sidebar-Logo_1Sidebar-Logo.png"
          loading="lazy"
          data-w-id="ad850566-76af-f3eb-5277-07c1948f8ecd"
          alt=""
          className="preloader-image"
        />
      </div>
    </>
  );
};

export default Activities;
