import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaUserCircle } from "react-icons/fa";
import "../styles/profile-settings.css";
import { FaGlobe } from "react-icons/fa";

import en from "../langs/en-us.json";
import es from "../langs/es-es.json";
import pl from "../langs/pl-pl.json";
import pt from "../langs/pt-br.json";
import zh from "../langs/zh-cn.json";


const languages = {
  en: { name: "English", flag: "/assets/images/flags/us.png", data: en },
  es: { name: "Español", flag: "/assets/images/flags/es.png", data: es },
  pl: { name: "Polski", flag: "/assets/images/flags/pl.png", data: pl },
  pt: { name: "Português", flag: "/assets/images/flags/br.png", data: pt },
  zh: { name: "中文", flag: "/assets/images/flags/cn.png", data: zh },
};

const Setting = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const [user, setUser] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [copied, setCopied] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [secretKey, setSecretKey] = useState("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState(""); // Estado para manejar errores
  const [success, setSuccess] = useState(""); 
  const [code, setCode] = useState("");
  const [is2FAEnabled, setIs2FAEnabled] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lang") || "en");
  const [translations, setTranslations] = useState(languages[selectedLang].data);


  useEffect(() => {
    // Guardar idioma seleccionado en localStorage
    localStorage.setItem("lang", selectedLang);
    setTranslations(languages[selectedLang].data);
  }, [selectedLang]);

  const loginSessions = [
    {
      id: 1,
      date: "17/02/2025 10:57 hs",
      ip: "98.97.134.50",
      browser: "Microsoft Edge 133.0",
      device: "Desktop",
      os: "Windows 10.0",
    },
    {
      id: 2,
      date: "12/02/2025 10:45 hs",
      ip: "98.97.134.13",
      browser: "Microsoft Edge 132.0",
      device: "Desktop",
      os: "Windows 10.0",
    },
    {
      id: 3,
      date: "25/01/2025 00:50 hs",
      ip: "200.189.23.201",
      browser: "Microsoft Edge 132.0",
      device: "Desktop",
      os: "Windows 10.0",
    },
    {
      id: 4,
      date: "16/01/2025 15:13 hs",
      ip: "200.189.23.6",
      browser: "Microsoft Edge 131.0",
      device: "Desktop",
      os: "Windows 10.0",
    },
    {
      id: 5,
      date: "06/01/2025 07:29 hs",
      ip: "98.97.134.7",
      browser: "Microsoft Edge 131.0",
      device: "Desktop",
      os: "Windows 10.0",
    },
  ];
  
  const actTab = (tab) => {
    setActiveTab(tab);
    setError("")
  }
  
  
  const handleCopy = () => {
    navigator.clipboard.writeText(secretKey);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleClick = () => {
    const sideNavbar = document.querySelector(".side-navbar-wrapper");
    if (sideNavbar) {
      sideNavbar.classList.toggle("active");
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("jwt");
        const response = await axios.get(
          "https://www.swapbits.io/api/users/info",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data)
        setUser(response.data);
        setProfileImage(response.data.imageUrl || null); // Si no hay imagen, se mantiene null
      } catch (error) {
        console.error("Error al obtener los datos del usuario:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const check2FAStatus = async () => {
      try {
        const response = await fetch("https://www.swapbits.io/api/users/security/check2fa", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        const data = await response.json();

        if (response.ok) {
          setIs2FAEnabled(data.enabled); // `enabled` debe ser true o false
        } else {
          setError(data.message || "Error checking 2FA status.");
        }
      } catch (err) {
        console.error("Error checking 2FA:", err);
        setError("Something went wrong.");
      }
    };

    check2FAStatus();
  }, []);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = localStorage.getItem("jwt");
      const response = await axios.post(
        "https://www.swapbits.io/api/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);

      setProfileImage(response.data.url); // Actualiza la imagen con la URL subida
    } catch (error) {
      console.error("Error al subir la imagen:", error);
    }
  };

  const handleDeleteAvatar = async () => {
    try {
      const token = localStorage.getItem("jwt");
      const response = await axios.delete(
        "https://www.swapbits.io/api/upload",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProfileImage(null); // Elimina la imagen del usuario
    } catch (error) {
      console.error("Error al eliminar la imagen:", error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await fetch("https://tu-api.com/users/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(user),
      });

      if (!response.ok) throw new Error("Error updating profile");

      alert("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setUser((prev) => ({
        ...prev,
        notifications: {
          ...prev.notifications,
          [name]: checked,
        },
      }));
    } else {
      setUser((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleClickPassword = async (e) => {
    e.preventDefault();
    setError(""); // Limpiar errores previos
    setSuccess("");

    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setError("All fields are required.");
      return;
    }

    if (newPassword.length < 6 || newPassword.length > 256) {
  setError("New password must be between 6 and 256 characters.");
  return;
}

if (!/[a-z]/.test(newPassword)) {
  setError("New password must contain at least one lowercase letter.");
  return;
}

if (!/[A-Z]/.test(newPassword)) {
  setError("New password must contain at least one uppercase letter.");
  return;
}

if (!/[0-9]/.test(newPassword)) {
  setError("New password must contain at least one number.");
  return;
}

if (!/[^a-zA-Z0-9]/.test(newPassword)) {
  setError("New password must contain at least one special character.");
  return;
}

    if (newPassword !== confirmNewPassword) {
      setError("New passwords do not match.");
      return;
    }

    try {
      const token = localStorage.getItem("jwt");
      const response = await fetch("https://www.swapbits.io/api/auth/change-password", {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  },
  body: JSON.stringify({
    currentPassword,
    newPassword,
  }),
});

      const data = await response.json();

      if (response.ok) {
        setSuccess("Password changed successfully!");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      } else {
        setError(data.message || "Failed to change password.");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      setError("Something went wrong. Please try again.");
    }
  };

  const handleGenerate2FA = async () => {
    setIsGenerating(true);
    setError("");
    setSuccess("");

    try {
      const response = await fetch("https://www.swapbits.io/api/users/security/generate2fa", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setQrCodeUrl(data.qrcode);
        setSecretKey(data.base32);
      } else {
        setError(data.message || "Failed to generate 2FA.");
      }
    } catch (err) {
      console.error("Error generating 2FA:", err);
      setError("Something went wrong.");
    } finally {
      setIsGenerating(false);
    }
  };


  const handleVerify2FA = async () => {
    setError("");
    setSuccess("");

    if (!code) {
      setError("Please enter the 6-digit code.");
      return;
    }

    try {
      const token = localStorage.getItem("jwt")
      const response = await fetch("https://www.swapbits.io/api/users/security/verify2fa", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Asegúrate de usar el token correcto
        },
        body: JSON.stringify({ code: code, secret: secretKey }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess("2FA enabled successfully!");
      } else {
        setError(data.message || "Invalid 2FA code.");
      }
    } catch (err) {
      console.error("Error verifying 2FA:", err);
      setError("Something went wrong.");
    }
  };

  const handleDisable2FA = async () => {
    setError("");
    setSuccess("");

    try {
      const response = await fetch("https://www.swapbits.io/api/users/security/disable2fa", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess("2FA disabled successfully.");
        setIs2FAEnabled(false); // Actualizar estado
        setQrCodeUrl(""); // Limpiar datos
        setSecretKey("");
      } else {
        setError(data.message || "Failed to disable 2FA.");
      }
    } catch (err) {
      console.error("Error disabling 2FA:", err);
      setError("Something went wrong.");
    }
  };

  return (
    <>
      <div className="page-wrapper">

         <div className="topbar-wrapper">
      <div className="header-content">
        <div className="header-toggle-wrapper">
          <div className="header-toggle" onClick={() => handleClick()}>
            <img src="../assets/images/Toggle.png" loading="lazy" alt="" className="header-toggle-img" />
          </div>

          <div className="header-title-wrapper">
            <h1 className="header-title">{translations.settings}</h1>
          </div>
        </div>

        {/* Formulario para cambiar idioma */}
        <div className="language-selector">
          <select
            className="language-dropdown"
            value={selectedLang}
            onChange={(e) => setSelectedLang(e.target.value)}
          >
            {Object.entries(languages).map(([key, lang]) => (
              <option key={key} value={key}>
                {lang.name}
              </option>
            ))}
          </select>
          <img
            src={languages[selectedLang].flag}
            alt={languages[selectedLang].name}
            className="language-flag"
          />
        </div>
      </div>
    </div>

        <div className="sections-wrapper">
          <div className="settings-singel-wrapper">
            <div className="container">
              <div className="settings-box">
                <div className="settings-bottom-content">

                  {/* Profile Settings */}
                  <div className="profile-settings-container">
                    <div className="profile-card">
                      <div className="profile-avatar-wrapper">
                        {profileImage ? (
                          <img
                            src={profileImage}
                            loading="lazy"
                            alt="Profile"
                            className="profile-avatar"
                          />
                        ) : (
                          <FaUserCircle
                            size={100}
                            color="#ccc"
                            className="profile-placeholder-icon"
                          />
                        )}
                      </div>
                      <div className="profile-info-wrapper">
                        <h2 className="profile-name">
                          {user ? user?.name : translations.loading}
                        </h2>
                        <div className="profile-role">
                          {user
                            ? user?.role || translations.not_specified
                            : translations.loading}
                        </div>
                      </div>
                      <div className="profile-actions">
                        <label className="upload-button">
                          {translations.upload}
                          <input
                            type="file"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                        </label>
                        <button
                          className="delete-button"
                          onClick={() => handleDeleteAvatar()}
                        >
                          {translations.delete_avatar}
                        </button>
                      </div>
                      
                    </div>
                  </div>

                  <div className="settings-content">
  {/* Tabs Menu */}
  <div className="settings-tab-menu">
    {["profile", "password", "2fa", "sessions"].map((tab) => (
      <button
        key={tab}
        className={`settings-tab-link ${activeTab === tab ? "active" : ""}`}
        onClick={() => actTab(tab)}
      >
        {translations[tab]}
      </button>
    ))}
  </div>

  {/* Tabs Content */}
  <div className="settings-tabs-content">
    {console.log(activeTab)}
    {activeTab === "profile" && (
      <form onSubmit={handleSubmit} className="settings-form">
        <div className="settings-form-wrapper">

          {/* Full Name & Display Name */}
          <div className="form-row">
            <div className="form-group">
              <h6>{translations.fullname}</h6>
              <p className="input-box">{user?.name}</p>
            </div>
            <div className="form-group">
              <h6>{translations.displayname} <span className="edit-text">({translations.edit})</span></h6>
              <p className="input-box">{user?.displayName || "N/A"}</p>
            </div>
          </div>

          {/* Email Address */}
          <div className="form-group">
            <h6>{translations.emailAddress} <span className="edit-text">({translations.edit})</span></h6>
            <p className="input-box">{user?.email}</p>
          </div>

          {/* Location */}
          <div className="form-group location-box">
            <h6>{translations.location} <span className="edit-text">({translations.edit})</span></h6>
            <p>{translations.country}: {user?.pais || "N/A"}</p>
            <p>{translations.address}: {user?.address || "N/A"}</p>
            <p>{translations.zipCode}: {user?.zip || "N/A"}</p>
            <p>{translations.city}: {user?.city || "N/A"}</p>
          </div>

          {/* Deposit & Withdraw Limits */}
          <div className="form-row">
            <div className="form-group">
              <h6>{translations.limitDeposit}</h6>
              <p className="input-box">${user?.depositLimit || 0}</p>
            </div>
            <div className="form-group">
              <h6>{translations.limitWithdraw}</h6>
              <p className="input-box">${user?.withdrawLimit || 0}</p>
            </div>
          </div>

          {/* Upgrade Level */}
          <button className="upgrade-btn">{translations.upgradeLevel}</button>
          <div className="upgrade-box">
            <h6>{translations.nextLevelSpecifications}</h6>
            <p>{translations.increasedLimitsAvailable}</p>
          </div>

        </div>
      </form>
    )}

{activeTab === "password" && (
  <div className="password-settings">
  <h6 className="password-title">Change Password</h6>
  <form className="password-form" onSubmit={handleClickPassword}>
    <div className="password-input-group">
      <input
        type="password"
        placeholder="Current Password"
        className="password-input"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
    </div>
    <div className="password-input-group">
      <input
        type="password"
        placeholder="New Password"
        className="password-input"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
    </div>
    <div className="password-input-group">
      <input
        type="password"
        placeholder="Confirm New Password"
        className="password-input"
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
      />
    </div>
    {error && <p className="error-message">{error}</p>}
    {success && <p className="success-message">{success}</p>}
    <button type="submit" className="password-button">
      Change Password
    </button>
  </form>
</div>
)}


{activeTab === "2fa" && (
     <div className="twofa-settings">
     <h2 className="twofa-title">Two-Factor Authentication (2FA)</h2>

     {/* Mostrar estado de 2FA */}
     {is2FAEnabled === null ? (
       <p>Loading 2FA status...</p>
     ) : is2FAEnabled ? (
       <>
         <p className="twofa-description">2FA is currently <strong>enabled</strong> on your account.</p>
         <button className="twofa-button disable" onClick={handleDisable2FA}>
           Disable 2FA
         </button>
       </>
     ) : (
       <>
         {!qrCodeUrl ? (
           <>
             <p className="twofa-description">Click the button below to generate your 2FA QR Code.</p>
             <button 
               className="twofa-button" 
               onClick={handleGenerate2FA} 
               disabled={isGenerating}
             >
               {isGenerating ? "Generating..." : "Enable 2FA"}
             </button>
           </>
         ) : (
           <>
             <p className="twofa-description">Scan the QR code below to enable 2FA.</p>

             {/* QR Code */}
             <div className="twofa-qr-container">
               <img src={qrCodeUrl} alt="2FA QR Code" className="twofa-qr" />
             </div>

             {/* Secret Key */}
             <div className="twofa-secret-container">
               <p className="twofa-secret-label">Or enter this code manually:</p>
               <div className="twofa-secret-box">
                 <span className="twofa-secret">{secretKey}</span>
                 <button className="twofa-copy-button" onClick={handleCopy}>
                   {copied ? "Copied!" : "Copy"}
                 </button>
               </div>
             </div>

             {/* Input for 6-digit code */}
             <p className="twofa-instructions">Enter the 6-digit code from your authentication app.</p>
             <div className="twofa-input-group">
               <input
                 type="text"
                 placeholder="Enter 6-digit code"
                 className="twofa-input"
                 value={code}
                 onChange={(e) => setCode(e.target.value)}
               />
             </div>

             {/* Verify Button */}
             <button type="button" className="twofa-button" onClick={handleVerify2FA}>
               Verify 2FA
             </button>
           </>
         )}
       </>
     )}

     {/* Mensajes */}
     {error && <p className="error-message">{error}</p>}
     {success && <p className="success-message">{success}</p>}
   </div>
    )}


{activeTab === "sessions" && (
  <div className="sessions-settings">
    <h6 className="sessions-title">Sessions & Login History</h6>
    <p className="sessions-description">Here you can see your recent login sessions.</p>

    <table className="sessions-table">
      <thead>
        <tr>
          <th>Fecha</th>
          <th>IP</th>
          <th>Navegador</th>
          <th>Dispositivo</th>
          <th>Sistema Operativo</th>
        </tr>
      </thead>
      <tbody>
        {loginSessions.map((session) => (
          <tr key={session.id}>
            <td>{session.date}</td>
            <td>{session.ip}</td>
            <td>{session.browser}</td>
            <td>{session.device}</td>
            <td>{session.os}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}



  </div>
</div>



                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container">
              <div className="footer-details-wrapper">
                <div className="footer-text-block">
                  <a
                    href="http://webflow.com"
                    target="_blank"
                    className="footer-text-link"
                  >
                    <strong>
                      © 2025 Swapbits Financial.All rights reserved
                    </strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-w-id="ad850566-76af-f3eb-5277-07c1948f8ecc"
        className="preloader"
      >
        <img
          src="images/Sidebar-Logo_1Sidebar-Logo.png"
          loading="lazy"
          data-w-id="ad850566-76af-f3eb-5277-07c1948f8ecd"
          alt=""
          className="preloader-image"
        />
      </div>
    </>
  );
};

export default Setting;
