import React, {useEffect, useState} from 'react';
import axios from 'axios';

import en from "../langs/en-us.json";
import es from "../langs/es-es.json";
import pl from "../langs/pl-pl.json";
import pt from "../langs/pt-br.json";
import zh from "../langs/zh-cn.json";


const languages = {
  en: { name: "English", flag: "/assets/images/flags/us.png", data: en },
  es: { name: "Español", flag: "/assets/images/flags/es.png", data: es },
  pl: { name: "Polski", flag: "/assets/images/flags/pl.png", data: pl },
  pt: { name: "Português", flag: "/assets/images/flags/br.png", data: pt },
  zh: { name: "中文", flag: "/assets/images/flags/cn.png", data: zh },
}

const Wallets = () => {
  const [walletBalanceUSDT, setWalletBalanceUSDT] = useState(0);
  const [walletBalanceEUR, setWalletBalanceEUR] = useState(0);
      const [convertedBalances, setConvertedBalances] = useState([]);
      const [selectedAsset, setSelectedAsset] = useState(null);
  

  const [portfolio, setPortfolio] = useState({});
  const [cryptoData, setCryptoData] = useState([]);

const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lang") || "en");
          const [translations, setTranslations] = useState(languages[selectedLang].data);
          useEffect(() => {
            // Guardar idioma seleccionado en localStorage
            localStorage.setItem("lang", selectedLang);
            setTranslations(languages[selectedLang].data);
          }, [selectedLang]);
  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get('https://www.swapbits.io/api/quotes/latest-crypto-listings');
            setCryptoData(response.data);
        } catch (error) {
            console.error('Error fetching cryptocurrency data:', error);
        }
    };
    
    fetchData();
    const interval = setInterval(fetchData, 30000); // Actualiza cada 30 segundos
    return () => clearInterval(interval);
}, []);

  useEffect(() => {
    const fetchWalletInfo = async () => {
      try {
        const token = localStorage.getItem("jwt");
      if (!token) {
        console.error("Token no encontrado en localStorage.");
        return;
      }

      const response = await fetch(
        "https://www.swapbits.io/api/wallets/balance",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        console.log("Información de la billetera:", data);
        const filteredBalances = data.filter(asset => asset.balance > 0);
        convertToEUR(filteredBalances)
      } catch (error) {
        console.error("Error al obtener la información de la billetera:", error);
      }
    };

    fetchWalletInfo();
  }, []);

  const convertToEUR = async (portfolio) => {
    try {
      const cryptos = portfolio.reduce((acc, asset) => {
        acc[asset.symbol] = asset.balance;
        return acc;
      }, {});
      
      const response = await fetch("https://www.swapbits.io/api/convert-cryptos", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cryptos }),
      });

      if (!response.ok) {
        throw new Error("Error al convertir criptomonedas");
      }

      const conversionData = await response.json();
      const convertedEur = Object.keys(conversionData).map(symbol => ({
        name: symbol,
        value: parseFloat(conversionData[symbol].eur),
      })).filter(asset => asset.name == "total")[0].value;
      const convertedUsdt = Object.keys(conversionData).map(symbol => ({
        name: symbol,
        value: parseFloat(conversionData[symbol].usdt),
      })).filter(asset => asset.name == "total")[0].value;
      console.log("Criptomonedas convertidas:", convertedEur);

      setWalletBalanceUSDT(convertedUsdt)
      setWalletBalanceEUR(convertedEur)
      
      
    } catch (error) {
      console.error("Error al convertir criptomonedas:", error);
    }
  };

  const processPortfolio = (data) => {
    const aggregatedPortfolio = {};
    
    Object.entries(data.balances).forEach((value) => {
      const token = value[1].symbol;
      const amount = value[1].balance;
      
      if (!aggregatedPortfolio[token]) {
        aggregatedPortfolio[token] = 0;
      }
      aggregatedPortfolio[token] += parseFloat(amount);
    });

    const filteredPortfolio = Object.fromEntries(
      Object.entries(aggregatedPortfolio).filter(([_, amount]) => amount > 0)
    );
    
    convertPortfolio(filteredPortfolio);
  };

  const convertPortfolio = async (portfolio) => {
    try {
      const response = await fetch("https://www.swapbits.io/api/convert-cryptos", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cryptos: portfolio }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Portafolio convertido:", data.total);
      setPortfolio(Object.fromEntries(
        Object.entries(data).map(([key, value]) => [key, parseFloat(value.usdt)])
      ));
      setWalletBalanceUSDT(data.total.usdt)
      setWalletBalanceEUR(data.total.eur)
      // calculateTotalInUSDT(data);
    } catch (error) {
      console.error("Error al convertir el portafolio:", error);
    }
  };

  const calculateTotalInUSDT = (quotesData) => {
    let totalInUSDT = 0;
    Object.entries(portfolio).forEach(([token, amount]) => {
      const quote = quotesData[token];
      console.log("Cotización:", quote);
      if (quote) {
        totalInUSDT += amount * parseFloat(quote.usdt);
      }
    });
    setWalletBalanceUSDT(totalInUSDT);
  }

    const handleClick = () => {
        const sideNavbar = document.querySelector('.side-navbar-wrapper');
        if (sideNavbar) {
          sideNavbar.classList.toggle('active');
        }
      };
    return (
        <>

  <div className="page-wrapper">
    
    <div className="sections-wrapper">
      <div className="wallets-wrapper">
        <div className="container">

        <div className="wallets-balance-chart-wrapper">
  <div className="wallets-balance-wrapper">
    <div className="wallets-balance-text-wrapper">
      <div className="wallets-balance-text-block">{translations.totalBalance}</div>
      <h2 className="wallets-balance-code">{new Intl.NumberFormat('de-DE').format(walletBalanceUSDT)} USDT</h2>
      <div className="wallets-balance-price">{new Intl.NumberFormat('de-DE').format(walletBalanceEUR)} EUR</div>
    </div>
    <div className="wallets-balance-details-wrapper">
     
        <a href="/dashboard/deposit" className="balance-categorys-button deposite w-button">{translations.deposit}</a>
      
      
        <a href="/dashboard/withdraw" className="balance-categorys-button withdraw w-button">{translations.withdraw}</a>
      
    </div>
  </div>
  <div className="wallets-chart-wrapper">
    <div className="balance-categorys-top-content">
      <div className="balance-categorys">
        
      </div>
      <div className="balance-categorys-button-wrapper">
        {/* {grafico a futuro} */}
      </div>
    </div>
  </div>
</div>


    <div className="sections-wrapper">
                <div className="prices-wrapper">
                    <div className="container">
                        <div className="cryptocurrency-prices-table-wrapper">
                            <div className="cryptocurrency-prices-table-top-content">
                                <h2 className="dashboard-chart-title">{translations.today_currency_price}</h2>
                            </div>
                            <div className="cryptocurrency-table-headings">
                                <div className="w-layout-grid cryptocurrency-table-grid">
                                    <h3 className="cryptocurrency-table-heading">{translations.token}</h3>
                                    <h3 className="cryptocurrency-table-heading">{translations.symbol}</h3>
                                    <h3 className="cryptocurrency-table-heading">{translations.price}</h3>
                                    <h3 className="cryptocurrency-table-heading">{translations["24h"]}</h3>
                                    <h3 className="cryptocurrency-table-heading">{translations.market_cap}</h3>
                                    <h3 className="cryptocurrency-table-heading">{translations.volume}</h3>
                                </div>
                            </div>
                            {cryptoData.map((crypto, index) => (
                                <div key={index} className="cryptocurrency-table">
                                    <div className="w-layout-grid cryptocurrency-table-grid">
                                    <div className="cryptocurrency-table-single">
                                        <div className="cryptocurrency-table-token">
                                          {/* <div class="cryptocurrency-table-token-img-wrap"><img src="../assets/images/bitcoin-btc-logo-2-p-1080.png"  sizes="100vw" srcset="../assets/images/bitcoin-btc-logo-2-p-500.png 500w, ../assets/images/bitcoin-btc-logo-2-p-800.png 800w, ../assets/images/bitcoin-btc-logo-2-p-1080.png 1080w, ../assets/images/bitcoin-btc-logo-2-p-1600.png 1600w, ../assets/images/bitcoin-btc-logo-2-p-2000.png 2000w, ../assets/images/bitcoin-btc-logo-2-p-2600.png 2600w, ../assets/images/bitcoin-btc-logo-2-p-3200.png 3200w, ../assets/images/bitcoin-btc-logo-2.png 3420w" alt="" class="cryptocurrency-table-token-img" /></div> */}

                                          <div class="cryptocurrency-table-token-text-block">{crypto.name}</div>
                                         
                                          </div>
                                          </div>
                                        <div className="cryptocurrency-table-single">
                                        <div class="cryptocurrency-table-text-wrap">
                    <div class="cryptocurrency-table-text-block">{crypto.symbol}</div>
                  </div>
                                          </div>

                                        <div className="cryptocurrency-table-single">
                                        <div class="cryptocurrency-table-text-wrap">
                    <div class="cryptocurrency-table-text-block">${crypto.quote.EUR.price.toFixed(2)}</div>
                  </div>
                                          </div>

                                        <div className="cryptocurrency-table-single">
                                        <div class="exchange-card-detais">
                                          <div class="exchange-card-info high" className={crypto.quote.EUR.percent_change_24h > 0 ? 'text-green' : 
                                                            crypto.quote.EUR.percent_change_24h < 0 ? 'text-red' : 'text-orange'}>
                                                {crypto.quote.EUR.percent_change_24h.toFixed(2)}%</div>
                  </div>
                                            
                                        </div>
                                        <div className="cryptocurrency-table-single">
                                        <div class="cryptocurrency-table-text-wrap">
                    <div class="cryptocurrency-table-text-block">${crypto.quote.EUR.market_cap.toLocaleString()}</div>
                  </div>
                                          </div>
                                        <div className="cryptocurrency-table-single">
                                        <div class="cryptocurrency-table-text-wrap">
                    <div class="cryptocurrency-table-text-block">${crypto.quote.EUR.volume_24h.toLocaleString()}</div>
                  </div>
                                          
                                          </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div className="footer-details-wrapper">
            <div className="footer-text-block">
              <a
                href="http://webflow.com"
                target="_blank"
                className="footer-text-link"
              >
                <strong>© 2025 Swapbits Financial.All rights reserved</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style={{ display: "none" }} className="wallets-pop-up">
      <div className="wallets-singel-wrapper">
        <div
          style={{
            WebkitTransform:
              "translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            msTransform:
              "translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
          }}
          className="wallets-balance-pop one"
        >
          <div className="wallets-balance-pop-top-content">
            <div className="wallets-balance-pop-details">
              <img
                src="images/Wallets-Balance-Pop-Details-Image_1Wallets-Balance-Pop-Details-Image.png"
                loading="lazy"
                alt=""
                className="wallets-balance-pop-details-image"
              />
              <div className="wallets-balance-pop-details-name-info">
                <h1 className="wallets-balance-pop-details-name">
                  ETH Balance
                </h1>
                <div className="wallets-balance-pop-details-info-text">
                  Ethereum
                </div>
              </div>
              <div
                data-w-id="3ea67f2b-e045-2095-5e91-8f613b65953b"
                className="clouse-button-wrapper"
              >
                <img
                  src="images/Clouse-Img.svg"
                  loading="lazy"
                  alt=""
                  className="clouse-button-image"
                />
              </div>
            </div>
            <div className="wallets-balance-form-wrapper">
              <div className="wallets-balance-form-block w-form">
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  className="wallets-balance-form"
                  data-wf-page-id="6796f465ce700a674ce57e2f"
                  data-wf-element-id="c4b3dcd9-448a-1c72-627d-fe76a7842584"
                >
                  <div className="wallets-balance-singel">
                    <label
                      htmlFor="email"
                      className="wallets-balance-form-field-label"
                    >
                      Address
                    </label>
                    <input
                      className="wallets-balance-form-input w-input"
                      maxLength={256}
                      name="email"
                      data-name="Email"
                      placeholder="Los angeles, California"
                      type="email"
                      id="email"
                      required=""
                    />
                  </div>
                  <div className="available-balance-wrapper">
                    <div className="available-balance-text-block">
                      Available Balance
                    </div>
                    <div className="available-balance-code">124658975 ETH</div>
                    <div className="available-balance-price">
                      $05, 346. 219 USD
                    </div>
                  </div>
                  <div className="wallets-balance-singel">
                    <label
                      htmlFor="email-2"
                      className="wallets-balance-form-field-label"
                    >
                      Amount to widthdraw
                    </label>
                    <input
                      className="wallets-balance-form-input w-input"
                      maxLength={256}
                      name="email-2"
                      data-name="Email 2"
                      placeholder="2, 2459687"
                      type="email"
                      id="email-2"
                      required=""
                    />
                    <div className="wallets-balance-limit">
                      $1,000,000.00 daily withdrawal limit.
                    </div>
                  </div>
                  <div className="wallets-balance-singel">
                    <label
                      htmlFor="email-3"
                      className="wallets-balance-form-field-label"
                    >
                      Transaction Fee
                    </label>
                    <input
                      className="wallets-balance-form-input w-input"
                      maxLength={256}
                      name="email-3"
                      data-name="Email 3"
                      placeholder={546900}
                      type="email"
                      id="email-3"
                      required=""
                    />
                  </div>
                  <div className="wallets-balance-singel">
                    <label
                      htmlFor="email-3"
                      className="wallets-balance-form-field-label"
                    >
                      Total
                    </label>
                    <input
                      className="wallets-balance-form-input w-input"
                      maxLength={256}
                      name="email-3"
                      data-name="Email 3"
                      placeholder={546900}
                      type="email"
                      id="email-3"
                      required=""
                    />
                  </div>
                  <input
                    type="submit"
                    data-wait="Please wait..."
                    className="widthdraw-submit-button w-button"
                    defaultValue="Widthdraw"
                  />
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style={{ display: "none" }} className="deposite-pop-up">
      <div className="wallets-singel-wrapper">
        <div
          style={{
            WebkitTransform:
              "translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            msTransform:
              "translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
          }}
          className="wallets-balance-pop two"
        >
          <div className="wallets-balance-pop-top-content">
            <div className="wallets-balance-pop-details">
              <img
                src="images/Wallets-Balance-Pop-Details-Image_1Wallets-Balance-Pop-Details-Image.png"
                loading="lazy"
                alt=""
                className="wallets-balance-pop-details-image"
              />
              <div className="wallets-balance-pop-details-name-info">
                <h1 className="wallets-balance-pop-details-name">
                  ETH Balance
                </h1>
                <div className="wallets-balance-pop-details-info-text">
                  Ethereum
                </div>
              </div>
              <div
                data-w-id="ea9fc99b-66cd-a4fe-64e3-a8bb87aa877c"
                className="clouse-button-wrapper two"
              >
                <img
                  src="images/Clouse-Img.svg"
                  loading="lazy"
                  alt=""
                  className="clouse-button-image"
                />
              </div>
            </div>
            <div className="total-balance-wrapper">
              <div className="total-balance-text-block-wrapper">
                <div className="total-balance-text-block">Total balance</div>
              </div>
              <div className="total-balance-price-wrapper">
                <div className="total-amout-text">24.1236</div>
                <div className="total-amout-code">3,700.96 USD</div>
              </div>
            </div>
            <div className="memo-box">
              <div className="memo-title-wrapper">
                <h1 className="memo-title">Memo</h1>
                <p className="memo-paragraph-text">
                  Failure to use the Memomay result in the loss of your deposit.
                </p>
                <div className="memo-form-block w-form">
                  <form
                    id="email-form-2"
                    name="email-form-2"
                    data-name="Email Form 2"
                    method="get"
                    className="memo-form"
                    data-wf-page-id="6796f465ce700a674ce57e2f"
                    data-wf-element-id="5b505b47-dcce-aba5-9725-2461c5882d7f"
                  >
                    <input
                      className="memo-text-field w-input"
                      maxLength={256}
                      name="email-4"
                      data-name="Email 4"
                      placeholder={245936468555264}
                      type="email"
                      id="email-4"
                      required=""
                    />
                    <a href="#" className="memo-cpy-field w-inline-block">
                      <img
                        src="images/Input-Cpy_1Input-Cpy.png"
                        loading="lazy"
                        alt=""
                        className="memo-cpy-field-img"
                      />
                    </a>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
                <div className="memo-scan">
                  <img
                    src="images/Memo-Scan-Image_1Memo-Scan-Image.png"
                    loading="lazy"
                    alt=""
                    className="memo-scan-image"
                  />
                </div>
              </div>
            </div>
            <div className="memo-box">
              <div className="memo-title-wrapper">
                <h1 className="memo-title">Address</h1>
                <p className="memo-paragraph-text">
                  Failure to use the Memomay result in the loss of your deposit.
                </p>
                <div className="memo-form-block w-form">
                  <form
                    id="email-form-2"
                    name="email-form-2"
                    data-name="Email Form 2"
                    method="get"
                    className="memo-form"
                    data-wf-page-id="6796f465ce700a674ce57e2f"
                    data-wf-element-id="f4843504-5bcb-fb52-64d0-74155a44b00f"
                  >
                    <input
                      className="memo-text-field w-input"
                      maxLength={256}
                      name="email-4"
                      data-name="Email 4"
                      placeholder="Your Address"
                      type="email"
                      id="email-4"
                      required=""
                    />
                    <a href="#" className="memo-cpy-field w-inline-block">
                      <img
                        src="images/Input-Cpy_1Input-Cpy.png"
                        loading="lazy"
                        alt=""
                        className="memo-cpy-field-img"
                      />
                    </a>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
                <div className="memo-scan">
                  <img
                    src="images/Memo-Scan-Image_1Memo-Scan-Image.png"
                    loading="lazy"
                    alt=""
                    className="memo-scan-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="topbar-wrapper">
      <div className="header-content">
        <div className="header-toggle-wrapper">
        <div className="header-toggle" onClick={handleClick}><img src="../assets/images/Toggle.png" loading="lazy" alt="" className="header-toggle-img"></img></div>

          <div className="header-title-wrapper">
            <h1 className="header-title">{translations.wallets}</h1>
          </div>
        </div>
        <div className="language-selector">
          <select
            className="language-dropdown"
            value={selectedLang}
            onChange={(e) => setSelectedLang(e.target.value)}
          >
            {Object.entries(languages).map(([key, lang]) => (
              <option key={key} value={key}>
                {lang.name}
              </option>
            ))}
          </select>
          <img
            src={languages[selectedLang].flag}
            alt={languages[selectedLang].name}
            className="language-flag"
          />
        </div>
        
      </div>
    </div>
  </div>
  <div data-w-id="ad850566-76af-f3eb-5277-07c1948f8ecc" className="preloader">
    <img
      src="images/Sidebar-Logo_1Sidebar-Logo.png"
      loading="lazy"
      data-w-id="ad850566-76af-f3eb-5277-07c1948f8ecd"
      alt=""
      className="preloader-image"
    />
  </div>
</>

    );
};

export default Wallets;