import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/prices.css';
import en from "../langs/en-us.json";
import es from "../langs/es-es.json";
import pl from "../langs/pl-pl.json";
import pt from "../langs/pt-br.json";
import zh from "../langs/zh-cn.json";


const languages = {
  en: { name: "English", flag: "/assets/images/flags/us.png", data: en },
  es: { name: "Español", flag: "/assets/images/flags/es.png", data: es },
  pl: { name: "Polski", flag: "/assets/images/flags/pl.png", data: pl },
  pt: { name: "Português", flag: "/assets/images/flags/br.png", data: pt },
  zh: { name: "中文", flag: "/assets/images/flags/cn.png", data: zh },
}
const Prices = () => {
    const [cryptoData, setCryptoData] = useState([]);
    const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lang") || "en");
          const [translations, setTranslations] = useState(languages[selectedLang].data);
          useEffect(() => {
            // Guardar idioma seleccionado en localStorage
            localStorage.setItem("lang", selectedLang);
            setTranslations(languages[selectedLang].data);
          }, [selectedLang]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://www.swapbits.io/api/quotes/latest-crypto-listings');
                setCryptoData(response.data);
            } catch (error) {
                console.error('Error fetching cryptocurrency data:', error);
            }
        };
        
        fetchData();
        const interval = setInterval(fetchData, 30000); // Actualiza cada 30 segundos
        return () => clearInterval(interval);
    }, []);
    
    const handleClick = () => {
        const sideNavbar = document.querySelector('.side-navbar-wrapper');
        if (sideNavbar) {
            sideNavbar.classList.toggle('active');
        }
    };
    
    return (
        <div className="page-wrapper">
            <div className="topbar-wrapper">
                <div className="header-content">
                    <div className="header-toggle-wrapper">
                        <div className="header-toggle" onClick={handleClick}>
                            <img src="../assets/images/Toggle.png" loading="lazy" alt="" className="header-toggle-img" />
                        </div>
                        <div className="header-title-wrapper">
                            <h1 className="header-title">{translations.prices}</h1>
                        </div>
                        
                    </div>
                    <div className="language-selector">
          <select
            className="language-dropdown"
            value={selectedLang}
            onChange={(e) => setSelectedLang(e.target.value)}
          >
            {Object.entries(languages).map(([key, lang]) => (
              <option key={key} value={key}>
                {lang.name}
              </option>
            ))}
          </select>
          <img
            src={languages[selectedLang].flag}
            alt={languages[selectedLang].name}
            className="language-flag"
          />
        </div>
                </div>
            </div>
            <div className="sections-wrapper">
                <div className="prices-wrapper">
                    <div className="container">
                        <div className="cryptocurrency-prices-table-wrapper">
                            <div className="cryptocurrency-prices-table-top-content">
                                <h2 className="dashboard-chart-title">{translations.today_currency_price}</h2>
                            </div>
                            <div className="cryptocurrency-table-headings">
                                <div className="w-layout-grid cryptocurrency-table-grid">
                                    <h3 className="cryptocurrency-table-heading">{translations.token}</h3>
                                    <h3 className="cryptocurrency-table-heading">{translations.symbol}</h3>
                                    <h3 className="cryptocurrency-table-heading">{translations.price}</h3>
                                    <h3 className="cryptocurrency-table-heading">{translations["24h"]}</h3>
                                    <h3 className="cryptocurrency-table-heading">{translations.market_cap}</h3>
                                    <h3 className="cryptocurrency-table-heading">{translations.volume}</h3>
                                </div>
                            </div>
                            {cryptoData.map((crypto, index) => (
                                <div key={index} className="cryptocurrency-table">
                                    <div className="w-layout-grid cryptocurrency-table-grid">
                                    <div className="cryptocurrency-table-single">
                                        <div className="cryptocurrency-table-token">
                                          {/* <div class="cryptocurrency-table-token-img-wrap"><img src="../assets/images/bitcoin-btc-logo-2-p-1080.png"  sizes="100vw" srcset="../assets/images/bitcoin-btc-logo-2-p-500.png 500w, ../assets/images/bitcoin-btc-logo-2-p-800.png 800w, ../assets/images/bitcoin-btc-logo-2-p-1080.png 1080w, ../assets/images/bitcoin-btc-logo-2-p-1600.png 1600w, ../assets/images/bitcoin-btc-logo-2-p-2000.png 2000w, ../assets/images/bitcoin-btc-logo-2-p-2600.png 2600w, ../assets/images/bitcoin-btc-logo-2-p-3200.png 3200w, ../assets/images/bitcoin-btc-logo-2.png 3420w" alt="" class="cryptocurrency-table-token-img" /></div> */}

                                          <div class="cryptocurrency-table-token-text-block">{crypto.name}</div>
                                         
                                          </div>
                                          </div>
                                        <div className="cryptocurrency-table-single">
                                        <div class="cryptocurrency-table-text-wrap">
                    <div class="cryptocurrency-table-text-block">{crypto.symbol}</div>
                  </div>
                                          </div>

                                        <div className="cryptocurrency-table-single">
                                        <div class="cryptocurrency-table-text-wrap">
                    <div class="cryptocurrency-table-text-block">${crypto.quote.EUR.price.toFixed(2)}</div>
                  </div>
                                          </div>

                                        <div className="cryptocurrency-table-single">
                                        <div class="exchange-card-detais">
                                          <div class="exchange-card-info high" className={crypto.quote.EUR.percent_change_24h > 0 ? 'text-green' : 
                                                            crypto.quote.EUR.percent_change_24h < 0 ? 'text-red' : 'text-orange'}>
                                                {crypto.quote.EUR.percent_change_24h.toFixed(2)}%</div>
                  </div>
                                            
                                        </div>
                                        <div className="cryptocurrency-table-single">
                                        <div class="cryptocurrency-table-text-wrap">
                    <div class="cryptocurrency-table-text-block">${crypto.quote.EUR.market_cap.toLocaleString()}</div>
                  </div>
                                          </div>
                                        <div className="cryptocurrency-table-single">
                                        <div class="cryptocurrency-table-text-wrap">
                    <div class="cryptocurrency-table-text-block">${crypto.quote.EUR.volume_24h.toLocaleString()}</div>
                  </div>
                                          
                                          </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="container">
                        <div className="footer-details-wrapper">
                            <div className="footer-text-block">
                                <strong>© 2025 Swapbits Financial. All rights reserved</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Prices;