import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import "../styles/Register.css";

const Register = () => {
  useEffect(() => {
    document.title = "Register - Swapbits";
  }, []);
  const [firstname, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isWait, setIsWait] = useState(false);
  const [isVerificationStep, setIsVerificationStep] = useState(false);
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  const handleRegister = async () => {
    if (!termsAccepted) {
      setErrorMessage("Debe aceptar los términos y condiciones para registrarse.");
      setIsWait(false);
      return;
    }
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).+$/;
    if (!regex.test(password)) {
      setErrorMessage("La contraseña debe tener al menos una letra, un número y un carácter especial.");
      setIsWait(false);
      return;
    }

    const userData = {
      email,
      password,
    };

    try {
      setIsWait(true); // Activamos el estado de espera
      const response = await fetch("https://www.swapbits.io/api/auth/v2/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }

      const data = await response.json();
      console.log("Registro exitoso:", data);

      if (data.status === "pending") {
        setSuccessMessage("Código de verificación enviado a tu email.");
        setErrorMessage("");
        setIsVerificationStep(true);
        setToken(data.sessionToken)
      } else {
        setErrorMessage(data.message || "Error en el registro");
      }
    } catch (error) {
      console.error("Error al registrar:", error);
      setErrorMessage("Error al conectar con el servidor");
    } finally {
      setIsWait(false); // Restablecemos el estado de espera
    }
  };

  const handleVerifyEmail = async () => {
    const verificationData = {
      email: email,
      password: password,
      code: verificationCode,
    };

    try {
      setIsWait(true); // Activamos el estado de espera
      const response = await fetch("https://www.swapbits.io/api/auth/v2/verify-email?token=" + token, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(verificationData),
      });

      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }

      const data = await response.json();
      console.log("Verificación exitosa:", data);
      console.log(data.status)
      if (data.message === "Email verified successfully") {
        setSuccessMessage("Registro y verificación exitosos! Redirigiendo a Login...");
        setErrorMessage("");
        setTimeout(() => {
          window.location.href = "/login";
        }, 5000);
      
      } else {
        setErrorMessage(data.message || "Error en la verificación");
      }
    } catch (error) {
      console.error("Error al verificar:", error);
      setErrorMessage("Error al conectar con el servidor");
    } finally {
      setIsWait(false); // Restablecemos el estado de espera
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(isVerificationStep)
    if (isVerificationStep) {
      handleVerifyEmail();
    } else {
      handleRegister();
    }
  };

  useEffect(() => {
    const wrappers = document.querySelectorAll(".wrapper");

    wrappers.forEach((wrapper) => {
      const blurredColor = wrapper.querySelector(".blurred-color");

      if (!blurredColor) return;

      let lastOffsetX = 0.01; // Última posición en X
      let lastOffsetY = 0.01; // Última posición en Y

      const handleMouseMove = (event) => {
        const rect = wrapper.getBoundingClientRect();
        const isInBounds =
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom;

        if (isInBounds) {
          // Calcular desplazamiento dinámico dentro del wrapper
          lastOffsetX = ((event.clientX - rect.left) / rect.width - 0.5) * 200; // De -100% a 100%
          lastOffsetY = ((event.clientY - rect.top) / rect.height - 0.5) * 200; // De -100% a 100%

          // Aplicar posición dinámica
          // blurredColor.style.transition = "transform 0.01s ease-out"; // Suavizar la transición

          blurredColor.style.transform = `translate3d(${lastOffsetX}%, ${lastOffsetY}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
          blurredColor.style.willChange = "transform";
          blurredColor.style.transformStyle = "preserve-3d";
        } else {
          // Si el mouse sale, usa la última posición como referencia
          blurredColor.style.transform = `translate3d(${lastOffsetX.toFixed(
            2
          )}%, ${lastOffsetY.toFixed(
            2
          )}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
        }
      };

      // Añadir listener para el movimiento del mouse
      document.addEventListener("mousemove", handleMouseMove);

      // Limpiar al desmontar
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
      };
    });
  }, []);

  useEffect(() => {
    const wrappers = document.querySelectorAll(".wrapper");

    wrappers.forEach((wrapper) => {
      const blurredColor = wrapper.querySelector(".blurred-color");

      if (!blurredColor) return;

      let isMouseInside = false;
      let opacityInterval = null;
      let currentOpacity = 0;

      const handleMouseEnter = () => {
        isMouseInside = true;
        if (opacityInterval) clearInterval(opacityInterval);

        opacityInterval = setInterval(() => {
          if (currentOpacity < 1) {
            currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incrementar opacidad
            blurredColor.style.opacity = currentOpacity.toFixed(2);
          } else {
            clearInterval(opacityInterval); // Detener en 1
            opacityInterval = null;
          }
        }, 10); // Incrementar cada 10ms
      };

      const handleMouseLeave = () => {
        isMouseInside = false;
        if (opacityInterval) clearInterval(opacityInterval);

        opacityInterval = setInterval(() => {
          if (currentOpacity > 0) {
            currentOpacity = Math.max(currentOpacity - 0.01, 0); // Reducir opacidad
            blurredColor.style.opacity = currentOpacity.toFixed(2);
          } else {
            clearInterval(opacityInterval); // Detener en 0
            opacityInterval = null;
          }
        }, 10); // Reducir cada 10ms
      };

      // Añadir listeners al contenedor
      wrapper.addEventListener("mouseenter", handleMouseEnter);
      wrapper.addEventListener("mouseleave", handleMouseLeave);

      // Limpiar listeners al desmontar
      return () => {
        wrapper.removeEventListener("mouseenter", handleMouseEnter);
        wrapper.removeEventListener("mouseleave", handleMouseLeave);
        if (opacityInterval) clearInterval(opacityInterval);
      };
    });
  }, []);

  useEffect(() => {
    const content = document.querySelectorAll(".sing-up-banner");
    let opacityInterval = null;

    content.forEach((e) => {
      const img = e.querySelector(".img-sing-up-banner");

      let currentOpacity = 0;

      if (opacityInterval) clearInterval(opacityInterval);

      opacityInterval = setInterval(() => {
        if (currentOpacity < 1) {
          currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incrementar opacidad
          img.style.opacity = currentOpacity.toFixed(2);
        } else {
          clearInterval(opacityInterval); // Detener en 1
          opacityInterval = null;
        }
      }, 10);
    });

    // Limpiar intervalo cuando el componente se desmonte
    return () => {
      clearInterval(opacityInterval);
    };
  }, []); // Esto asegura que solo se ejecute una vez después del renderizado inicial

  useEffect(() => {
    const content = document.querySelectorAll(".account");
    let opacityInterval = null;

    content.forEach((e) => {
      const img = e.querySelector(".content");

      let currentOpacity = 0;

      if (opacityInterval) clearInterval(opacityInterval);

      opacityInterval = setInterval(() => {
        if (currentOpacity < 1) {
          currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incrementar opacidad
          img.style.opacity = currentOpacity.toFixed(2);
        } else {
          clearInterval(opacityInterval); // Detener en 1
          opacityInterval = null;
        }
      }, 10);
    });

    // Limpiar intervalo cuando el componente se desmonte
    return () => {
      clearInterval(opacityInterval);
    };
  }, []);

  return (
    <div className="w-layout-grid grid-account">
      <div
        id="w-node-_91e91119-4cd0-b208-64fb-d7e460d04b22-c53bab13"
        className="sing-up-banner"
      >
        <div
          data-w-id="dcf76999-9728-230e-4fee-d4328006904d"
          style={{ opacity: "0" }}
          className="img-sing-up-banner"
        ></div>
      </div>
      <div
        id="w-node-ef0b684a-ea0c-4d38-013c-342f04cab92a-c53bab13"
        className="account"
      >
        <div style={{ opacity: "0" }} className="content">
          <div className="block-account">
            <div
              data-w-id="8cfb9692-2833-c835-cab3-9782b886d920"
              className="wrapper"
            >
              <div style={{ opacity: "0" }} className="blurred-color"></div>
              <div className="content-wrapper">
                <div className="account-form">
                  <div className="block-heading-account">
                    <div className="subtitle">Sign up</div>
                    <h2 className="heading">Get Started</h2>
                    <p className="paragraph-form"></p>
                  </div>
                  <div className="form-block w-form">
                    <form
                      id="wf-form-Sing-up-Form"
                      name="wf-form-Sing-up-Form"
                      data-name="Sing up Form"
                      method="get"
                      className="form"
                      data-wf-page-id="67968b85dad40729c53bab13"
                      data-wf-element-id="8cfb9692-2833-c835-cab3-9782b886d925"
                    >
                      {!isVerificationStep ? (
                        <>
                          <div className="field">
                            <label htmlFor="email-Account" className="field-label">
                              Email
                            </label>
                            <input
                              className="text-field w-input"
                              maxLength="256"
                              name="Email-Account"
                              data-name="Email Account"
                              placeholder="Your Email"
                              type="email"
                              id="email-Account"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="field">
                            <label htmlFor="Password" className="field-label">
                              Password
                            </label>
                            <input
                              className="text-field w-input"
                              maxLength="256"
                              name="Password"
                              data-name="Password"
                              placeholder="Password"
                              type="password"
                              id="Password"
                              required
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <div className="field">
                            <label htmlFor="terms" className="field-label">
                              <input
                                type="checkbox"
                                id="terms"
                                checked={termsAccepted}
                                onChange={() => setTermsAccepted(!termsAccepted)}
                              />
                              I accept the terms and conditions
                            </label>
                          </div>
                        </>
                      ) : (
                        <div className="field">
                          <label htmlFor="verification-code" className="field-label">
                            Verification Code
                          </label>
                          <input
                            className="text-field w-input"
                            maxLength="256"
                            name="Verification-Code"
                            data-name="Verification Code"
                            placeholder="Enter Verification Code"
                            type="text"
                            id="verification-code"
                            required
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                          />
                        </div>
                      )}
                      <input
                        type="button"
                        className="submit-button w-button"
                        value={isWait ? "Please wait..." : "Submit"}
                        disabled={isWait}
                        onClick={handleSubmit}
                      />
                    </form>
                    {successMessage && <div className="text-success">{successMessage}</div>}
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-account">
              Already have an account?{" "}
              <a href="login" className="link-account">
                Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
