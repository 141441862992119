import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import "../styles/Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [sessionToken, setSessionToken] = useState(null);
  const [code, setCode] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isWait, setIsWait] = useState(false);
  const [isWait2, setIsWait2] = useState(false);
  const [success, setSuccess] = useState(false);
  const [verificationType, setVerificationType] = useState(""); // Nuevo estado para el tipo de verificación

  const navigate = useNavigate();

  const getUserIP = async () => {
    const response = await fetch("https://api64.ipify.org?format=json");
    const data = await response.json();
    console.log("ip:", data);
    return data.ip;
  };

  useEffect(() => {
    document.title = "Login - SwapBits";
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsCodeSent(false);
    setIsWait(true);
    const ip = await getUserIP();

    try {
      const response = await fetch("https://www.swapbits.io/api/auth/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "User-Agent": navigator.userAgent, // También envía el User-Agent real
        },
        body: JSON.stringify({ email, password, ip }),
      });

      if (!response.ok) {
        setIsWait(false);

        if (response.status === 401) {
          setErrorMessage("Email o contraseña incorrectos");
        } else {
          setErrorMessage("Error en el servidor. Por favor, inténtalo más tarde.");
        }
        return;
      }

      const data = await response.json();
      console.log(data);
      setSessionToken(data.sessionToken);
      setVerificationType(data.verificationType); // Establecer el tipo de verificación
      setIsCodeSent(true); // Si el login es exitoso, enviamos el código
      setIsWait(false);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Error al conectar con el servidor.");
    }
  };

  const handleCodeSubmit = async (event) => {
    event.preventDefault();
    setIsWait2(true);
    if (code.length !== 6) {
      setErrorMessage("El código debe tener exactamente 6 dígitos");
      setIsWait2(false);
      return;
    }
    const ip = await getUserIP();

    try {
      const endpoint =
        verificationType === "EMAIL_CODE"
          ? "https://www.swapbits.io/api/auth/verify-code"
          : "https://www.swapbits.io/api/auth/2fa";

      const body = verificationType === "EMAIL_CODE" ? { email: email, token: sessionToken, code: code } : { code: code, token: sessionToken, ip: ip };
      console.log("body:", body);
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        setIsWait2(false);
        if (response.status === 400) {
          setErrorMessage("Código incorrecto o expirado");
        } else {
          setErrorMessage("Error en el servidor. Por favor, inténtalo más tarde.");
        }
        return;
      }

      const data = await response.json();
      localStorage.setItem("jwt", data.accessToken);
      setIsLoggedIn(true);
      setIsWait2(false);
      setIsCodeSent(false);
      window.location.href = "/dashboard/home";
    } catch (error) {
      setErrorMessage("Error al conectar con el servidor.");
    }
  };

  useEffect(() => {
    const wrappers = document.querySelectorAll(".wrapper");

    wrappers.forEach((wrapper) => {
      const blurredColor = wrapper.querySelector(".blurred-color");

      if (!blurredColor) return;

      let lastOffsetX = 0.01; // Última posición en X
      let lastOffsetY = 0.01; // Última posición en Y

      const handleMouseMove = (event) => {
        const rect = wrapper.getBoundingClientRect();
        const isInBounds =
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom;

        if (isInBounds) {
          // Calcular desplazamiento dinámico dentro del wrapper
          lastOffsetX = ((event.clientX - rect.left) / rect.width - 0.5) * 200; // De -100% a 100%
          lastOffsetY = ((event.clientY - rect.top) / rect.height - 0.5) * 200; // De -100% a 100%

          // Aplicar posición dinámica
          // blurredColor.style.transition = "transform 0.01s ease-out"; // Suavizar la transición

          blurredColor.style.transform = `translate3d(${lastOffsetX}%, ${lastOffsetY}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
          blurredColor.style.willChange = "transform";
          blurredColor.style.transformStyle = "preserve-3d";
        } else {
          // Si el mouse sale, usa la última posición como referencia
          blurredColor.style.transform = `translate3d(${lastOffsetX.toFixed(
            2
          )}%, ${lastOffsetY.toFixed(
            2
          )}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
        }
      };

      // Añadir listener para el movimiento del mouse
      document.addEventListener("mousemove", handleMouseMove);

      // Limpiar al desmontar
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
      };
    });
  }, []);

  useEffect(() => {
    const wrappers = document.querySelectorAll(".wrapper");

    wrappers.forEach((wrapper) => {
      const blurredColor = wrapper.querySelector(".blurred-color");

      if (!blurredColor) return;

      let isMouseInside = false;
      let opacityInterval = null;
      let currentOpacity = 0;

      const handleMouseEnter = () => {
        isMouseInside = true;
        if (opacityInterval) clearInterval(opacityInterval);

        opacityInterval = setInterval(() => {
          if (currentOpacity < 1) {
            currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incrementar opacidad
            blurredColor.style.opacity = currentOpacity.toFixed(2);
          } else {
            clearInterval(opacityInterval); // Detener en 1
            opacityInterval = null;
          }
        }, 10); // Incrementar cada 10ms
      };

      const handleMouseLeave = () => {
        isMouseInside = false;
        if (opacityInterval) clearInterval(opacityInterval);

        opacityInterval = setInterval(() => {
          if (currentOpacity > 0) {
            currentOpacity = Math.max(currentOpacity - 0.01, 0); // Reducir opacidad
            blurredColor.style.opacity = currentOpacity.toFixed(2);
          } else {
            clearInterval(opacityInterval); // Detener en 0
            opacityInterval = null;
          }
        }, 10); // Reducir cada 10ms
      };

      // Añadir listeners al contenedor
      wrapper.addEventListener("mouseenter", handleMouseEnter);
      wrapper.addEventListener("mouseleave", handleMouseLeave);

      // Limpiar listeners al desmontar
      return () => {
        wrapper.removeEventListener("mouseenter", handleMouseEnter);
        wrapper.removeEventListener("mouseleave", handleMouseLeave);
        if (opacityInterval) clearInterval(opacityInterval);
      };
    });
  }, []);

  useEffect(() => {
          const content = document.querySelectorAll(".account");
          let opacityInterval = null;
  
          content.forEach((e) => {
            const img = e.querySelector(".content");
        
            let currentOpacity = 0;
        
            if (opacityInterval) clearInterval(opacityInterval);
        
            opacityInterval = setInterval(() => {
              if (currentOpacity < 1) {
                currentOpacity = Math.min(currentOpacity + 0.01, 1); // Incrementar opacidad
                img.style.opacity = currentOpacity.toFixed(2);
              } else {
                clearInterval(opacityInterval); // Detener en 1
                opacityInterval = null;
              }
            }, 10);
          });
        
          // Limpiar intervalo cuando el componente se desmonte
          return () => {
              clearInterval(opacityInterval);
            };
        }, []);

  return (
    <div className="account">
      {/* <a href="index.html" className="brand-account w-nav-brand"></a> */}
      <div style={{ opacity: "0", zIndex: 3 }} className="content">
        <div className="block-account">
          <div
            data-w-id="8cfb9692-2833-c835-cab3-9782b886d920"
            className="wrapper"
          >
            <div style={{ opacity: "0" }} className="blurred-color"></div>
            <div className="content-wrapper">
              <div className="account-form">
                <div className="block-heading-account">
                  <div className="subtitle">Log in</div>
                  <h2 className="heading">Log in to your account</h2>
                </div>
                <div className="form-block w-form">
                  <form
                    id="wf-form-Log-in-Form"
                    name="wf-form-Log-in-Form"
                    data-name="Log in Form"
                    method="get"
                    className="form"
                    data-wf-page-id="67968b85dad40729c53bab12"
                    data-wf-element-id="8cfb9692-2833-c835-cab3-9782b886d925"
                  >
                    <div className="field">
                      <label for="email-Account" className="field-label">
                        Email
                      </label>
                      <input
                        className="text-field w-input"
                        maxlength="256"
                        name="Email-Account"
                        data-name="Email Account"
                        placeholder="Your Email"
                        type="email"
                        id="email-Account"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="field">
                      <label for="Password" className="field-label">
                        Password
                      </label>
                      <input
                        className="text-field w-input"
                        maxlength="256"
                        name="Password"
                        data-name="Password"
                        placeholder="Password"
                        type="password"
                        id="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <input
                      type="submit"
                      data-wait="Please wait..."
                      className="submit-button w-button"
                      value={isWait ? "Please wait..." : "Log in"}
                      onClick={handleSubmit}
                      disabled={isWait}
                    />
                  </form>
                  
                  
                  {isCodeSent && (
                    <div className="form-block w-form">
                      <div className="field">
                        <label
                          htmlFor="verification-code"
                          className="field-label"
                        >
                          {verificationType === "EMAIL_CODE" ? "Email Verification Code" : "2FA Verification Code"}
                        </label>
                        <input
                          className="text-field w-input"
                          maxLength="6"
                          name="Verification Code"
                          placeholder="Enter the code"
                          type="text"
                          id="verification-code"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          required
                        />
                      </div>
                      <button
                        onClick={handleCodeSubmit}
                        className="submit-button w-button"
                        
                      >
                        {isWait2 ? "Please wait..." : "Submit Code"}
                      </button>
                    </div>
                  )}
                  {isLoggedIn && (
                    <div className="success-message w-form-done">
                      <div className="text-success">
                      Thank you! You are successfully logged in!
                        </div >
                      </div>
                  )}
                  {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="text-account">
            <a href="register" className="link-account">
              Create an account
            </a>{" "}
              —  {" "}
            <a href="#" className="link-account">
              Forgot password?
            </a>
          </div>
        </div>
      </div>
      <div style={{ opacity: "0", zIndex: 1 }} className="blur-top"></div>
    </div>
  );
};

export default Login;
