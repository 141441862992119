import React, { useState } from 'react';
import axios from 'axios';
import "../styles/changepass.css";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [show2FAModal, setShow2FAModal] = useState(false);
  const [authCode, setAuthCode] = useState('');

  const handle2FAVerification = async () => {
    try {
      const token = localStorage.getItem('jwt');
      const response = await axios.post(
        'https://www.swapbits.io/api/auth/users/security/verifyOauth',
        { token: authCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.valid) {
          setAuthCode("")

        setShow2FAModal(false);
        handlePasswordChange(); // Proceed to password change
      } else {
        setError('Código de autenticación inválido.');
        setAuthCode("")

      }
    } catch (err) {
      setAuthCode("")

      setError('Error al verificar el código 2FA.');
    }
  };

  const check2FAStatus = async () => {
    try {
      const token = localStorage.getItem('jwt');
      const response = await axios.get(
        'https://www.swapbits.io/api/auth/users/info',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.twoauth) {
        setIs2FAEnabled(true);
        setShow2FAModal(true);
      } else {
        handlePasswordChange(); // No 2FA, proceed to password change
      }
    } catch (err) {
      setError('Error al verificar el estado 2FA.');
    }
  };

  const handlePasswordChange = async () => {
    setLoading(true);
    setError('');
    setSuccessMessage('');
    setAuthCode("")


    try {
      const token = localStorage.getItem('jwt');
      const response = await axios.post(
        'https://www.swapbits.io/api/auth/users/change-password',
        { currentPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAuthCode("")
      setSuccessMessage('La contraseña se ha actualizado correctamente.');
    } catch (err) {
      setError(err.response?.data?.message || 'Error al cambiar la contraseña');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentPassword === newPassword) {
      setError('La nueva contraseña no puede ser igual a la actual.');
      return;
    }
    if (
      newPassword.length < 8 ||
      !/[A-Z]/.test(newPassword) ||
      !/[a-z]/.test(newPassword) ||
      !/[0-9]/.test(newPassword) ||
      !/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)
    ) {
      setError('La nueva contraseña debe tener al menos 8 caracteres, incluyendo mayúsculas, minúsculas, números y símbolos especiales.');
      return;
    }

    check2FAStatus(); // Check if 2FA is enabled
  };

  return (
    <div className="auth-change-password">
      <h2 className="auth-change-password-title">Cambiar contraseña</h2>
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="auth-form-group">
          <label className="auth-label">Contraseña actual *</label>
          <input
            type="password"
            placeholder="Tu contraseña actual"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="auth-input"
            required
          />
        </div>
        <div className="auth-form-group">
          <label className="auth-label">Nueva contraseña *</label>
          <input
            type="password"
            placeholder="Tu nueva contraseña"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="auth-input"
            required
          />
        </div>
        <div className="auth-form-actions">
          <button
            type="submit"
            className="auth-save-button"
            disabled={loading}
          >
            {loading ? 'Guardando...' : 'Guardar'}
          </button>
        </div>
      </form>
      {error && <p className="auth-error-message">{error}</p>}
      {successMessage && <p className="auth-success-message">{successMessage}</p>}

      {show2FAModal && (
        <div className="auth-modal">
          <div className="auth-modal-content">
            <button className="auth-modal-close" onClick={() => setShow2FAModal(false)}>X</button>
            <h3>Verificación 2FA</h3>
            <p>Introduce el código de autenticación</p>
            <input
              type="text"
              placeholder="Código 2FA"
              value={authCode}
              onChange={(e) => setAuthCode(e.target.value)}
              className="auth-input"
            />
            <button onClick={handle2FAVerification} className="auth-save-button">
              Verificar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
