import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Profile = ({ name, kycLvl, userInfo, email, birth, document1, document2, sexDate, handleTabChange, pais, phone }) => {
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(userInfo.phone || '');

  const handlePhoneEdit = () => {
    setIsEditingPhone(true);
  };

  const handlePhoneSave = () => {
    // Aquí puedes agregar lógica para guardar el número de teléfono
    setIsEditingPhone(false);
  };

  return (
    <div className="profile-container">
      <h2 className="profile-title">Mi perfil</h2>
      <div className="profile-card">
        <div className="investor-status">
          <div className="investor-details">
            <span className="investor-name">{name}</span>
            {kycLvl === 'Basic' ? (
              <div>
                <span>Básico</span>
                <a
                  onClick={() => handleTabChange('limits')}
                  style={{ cursor: 'pointer' }}
                >
                  Subir al nivel 2
                </a>
              </div>
            ) : kycLvl === 'Intermediate' ? (
              <div>
                <span>Intermedio</span>
                <a
                  onClick={() => handleTabChange('limits')}
                  style={{ cursor: 'pointer' }}
                >
                  Subir al nivel 3
                </a>
              </div>
            ) : kycLvl === 'Full' ? (
              <div>
                <span>Completo</span>
                <span>Nivel máximo alcanzado</span>
              </div>
            ) : (
              <div>
                <span>Sin verificar</span>
                <a href="#">Empezar verificación</a>
              </div>
            )}
          </div>
          <div className="investor-doc-status success">
            <i className="icon-check-circle"></i>
            <span>Documentación aprobada</span>
          </div>
        </div>
        
      </div>
      <div className="email-section">
        <span className="email-label">Email</span>
        <div className="email-card">
          <span>Email</span>
          <span className="email-text">{email}</span>
        </div>
        <button className="email-button">Cambiar email</button>
      </div>
      <div className="personal-data">
        <span className="data-title">Datos personales</span>
        <div className="data-card">
          <div className="data-item">
            <span>Dirección:</span>
            <span></span>
          </div>
          <div className="data-item">
            <span>Telefono:</span>
            <span>{phone}</span>
          </div>
          <div className="data-item">
            <span>Fecha de nacimiento:</span>
            <span>{birth}</span>
          </div>
          <div className="data-item">
            <span>DNI:</span>
            <span>{document1}</span>
          </div>
          <div className="data-item">
            <span>Actividad:</span>
            <span></span>
          </div>
          <div className="data-item">
            <span>CUIT/CUIL:</span>
            <span>{document2}</span>
          </div>
          <div className="data-item">
            <span>Pais:</span>
            <span>{pais}</span>
          </div>
          <div className="data-item">
            <span>Género:</span>
            <span>{sexDate}</span>
          </div>
          {/* <div className="data-item">
            <span>Estado civil:</span>
            <span></span>
          </div> */}
          
        </div>
      </div>
      <div className="delete-account">
        <a className="delete-link" id="account_profile_delete_init">
          <span>Eliminar cuenta</span>
          <i className="icon-chevron-right"></i>
        </a>
      </div>
    </div>
  );
};

export default Profile;
