import React, { useEffect } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import {
  FaArrowLeft,
  FaShoppingBag,
  FaChalkboard,
  FaCcVisa,
  FaBitcoin,
  FaChartBar,
  FaProjectDiagram,
  FaUser,
  FaIdCard,
  FaExchangeAlt,
  FaThLarge,
  FaFileInvoiceDollar,
  FaImage,
  FaImages,
  FaSignInAlt,
  FaExclamationTriangle,
  FaFileAlt,
  FaPuzzlePiece,
  FaTh,
  FaEnvelopeOpenText,
  FaChevronRight,
} from "react-icons/fa";
import "../styles/AdminHeader.css";

const Sidebar = () => {
  const useSidebarToggle = (event) => {
    event.preventDefault();
    const subMenuWrap = event.currentTarget.nextElementSibling;
  
    if (subMenuWrap) {
      if (subMenuWrap.style.height === "0px" || subMenuWrap.style.height === "") {
        // Abrir submenú: Establece altura al contenido completo
        subMenuWrap.style.height = subMenuWrap.scrollHeight + "px";
      } else {
        // Cerrar submenú: Restablece altura a 0
        subMenuWrap.style.height = "0px";
      }
  
      // Alternar la clase 'open' para cualquier otra personalización
      subMenuWrap.classList.toggle("open");
  
      // Manejo del ícono de flecha
      const arrowIcon = event.currentTarget.querySelector(".menu-arrow");
      if (arrowIcon) {
        arrowIcon.classList.toggle("open");
      }
  
      // Cerrar otros submenús abiertos
      const allSubMenus = document.querySelectorAll(".has-sub.menu-wrap ");
      allSubMenus.forEach((menu) => {
        if (menu !== subMenuWrap) {
          menu.style.height = "0px"; // Cierra los otros submenús
          menu.classList.remove("open");
          const siblingArrow =
            menu.previousElementSibling.querySelector(".menu-arrow");
          if (siblingArrow) {
            siblingArrow.classList.remove("open");
          }
        }
      });
    }
  };
  
  

  return (
    <div className="sidebar-container">
      <div className="sidebar-element sidebar-head">
        <div className="sidebar-brand">
          <a className="sidebar-logo-link" href="/demo9/">
            <img
              className="sidebar-logo-light"
              src="/demo9/static/media/logo2x.4c5ecef1ef62c40807a5.png"
              alt="logo"
            />
            <img
              className="sidebar-logo-dark"
              src="/demo9/static/media/logo-dark2x.cbb0b695c78f6b7938c2.png"
              alt="logo"
            />
          </a>
        </div>
        <div className="menu-trigger">
          <a
            href="#toggle"
            className="nav-toggle quick-nav-icon toggle-hidden-xl"
          >
            <FaArrowLeft />
          </a>
        </div>
      </div>

      <div className="sidebar-scroll">
        <div className="sidebar-content-container">
          <div className="sidebar-menu-container">
            <ul className="menu-list-sidebar">
              <li className="menu-heading-dashboard">
                <h6 className="overline-title-primary-alt">Dashboard</h6>
              </li>

              <li className="menu-item-active current-page">
                <a className="menu-link" href="/demo9/">
                  <span className="menu-icon">
                    <FaChalkboard />
                  </span>
                  <span className="menu-text">Default Dashboard</span>
                </a>
              </li>

              <li className="menu-item-sales">
                <a className="menu-link" href="/demo9/sales">
                  <span className="menu-icon">
                    <FaCcVisa />
                  </span>
                  <span className="menu-text">Sales Dashboard</span>
                </a>
              </li>

              <li className="menu-item-crypto">
                <a className="menu-link" href="/demo9/crypto">
                  <span className="menu-icon">
                    <FaBitcoin />
                  </span>
                  <span className="menu-text">Crypto Dashboard</span>
                </a>
              </li>

              <li className="menu-item-analytics">
                <a className="menu-link" href="/demo9/analytics">
                  <span className="menu-icon">
                    <FaChartBar />
                  </span>
                  <span className="menu-text">Analytics Dashboard</span>
                </a>
              </li>

              {/* Agrega más elementos de menú aquí */}

              <li className="menu-heading-prebuilt">
                <h6 className="menu-title-primary-alt">Pre-built Pages</h6>
              </li>

              <li className="menu-item-projects">
                <a
                  className="menu-link toggle-projects"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-tile-thumb"></em>
                  </span>
                  <span className="menu-icon">
                    <FaProjectDiagram />
                  </span>
                  <span className="menu-text">Projects</span>
                  <span className="menu-arrow">
                    <FaChevronRight />
                  </span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap ">
                  <ul className="menu-sub-projects">
                    <li className="menu-item-project-cards">
                      <a className="menu-link" href="/demo9/project-card">
                        <span className="menu-text">Project Cards</span>
                      </a>
                    </li>
                    <li className="menu-item-project-list">
                      <a className="menu-link" href="/demo9/project-list">
                        <span className="menu-text">Project List</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-user-manage">
                <a
                  className="menu-link toggle-user-manage"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-users"></em>
                  </span>
                  <span className="menu-icon">
                    <FaUser />
                  </span>
                  <span className="menu-text">User Manage</span>
                  <span className="menu-arrow">
                    <FaChevronRight />
                  </span>
                </a>

                <div style={{height: '0px'}} className="has-sub menu-wrap -user-manage">
                  <ul className="menu-sub-user-manage">
                    <li className="menu-item-user-list-regular">
                      <a className="menu-link" href="/demo9/user-list-regular">
                        <span className="menu-text">User List - Regular</span>
                      </a>
                    </li>
                    <li className="menu-item-user-list-compact">
                      <a className="menu-link" href="/demo9/user-list-compact">
                        <span className="menu-text">User List - Compact</span>
                      </a>
                    </li>
                    <li className="menu-item-user-details-regular">
                      <a
                        className="menu-link"
                        href="/demo9/user-details-regular/1"
                      >
                        <span className="menu-text">
                          User Details - Regular
                        </span>
                      </a>
                    </li>
                    <li className="menu-item-user-profile-regular">
                      <a
                        className="menu-link"
                        href="/demo9/user-profile-regular"
                      >
                        <span className="menu-text">
                          User Profile - Regular
                        </span>
                      </a>
                    </li>
                    <li className="menu-item-user-contact-card">
                      <a className="menu-link" href="/demo9/user-contact-card">
                        <span className="menu-text">User Contact - Card</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-kyc">
                <a
                  className="menu-link toggle-kyc"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-file-docs"></em>
                  </span>
                  <span className="menu-icon">
                    <FaIdCard />
                  </span>
                  <span className="menu-text">AML / KYCs</span>
                  <span className="menu-arrow">
                    <FaChevronRight />
                  </span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -kyc">
                  <ul className="menu-sub-kyc">
                    <li className="menu-item-kyc-list-regular">
                      <a className="menu-link" href="/demo9/kyc-list-regular">
                        <span className="menu-text">KYC List - Regular</span>
                      </a>
                    </li>
                    <li className="menu-item-kyc-details-regular">
                      <a
                        className="menu-link"
                        href="/demo9/kyc-details-regular/UD01544"
                      >
                        <span className="menu-text">KYC Details - Regular</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-transaction">
                <a
                  className="menu-link toggle-transaction"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-tranx"></em>
                  </span>
                  <span className="menu-icon">
                    <FaExchangeAlt />
                  </span>
                  <span className="menu-text">Transaction</span>
                  <span className="menu-arrow">
                    <FaChevronRight />
                  </span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -transaction">
                  <ul className="menu-sub-transaction">
                    <li className="menu-item-trans-basic">
                      <a className="menu-link" href="/demo9/transaction-basic">
                        <span className="menu-text">Trans List - Basic</span>
                      </a>
                    </li>
                    <li className="menu-item-trans-crypto">
                      <a className="menu-link" href="/demo9/transaction-crypto">
                        <span className="menu-text">Trans List - Crypto</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-applications">
                <a
                  className="menu-link toggle-applications"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-grid-alt"></em>
                  </span>
                  <span className="menu-icon">
                    <FaThLarge />
                  </span>
                  <span className="menu-text">Applications</span>
                  <span className="menu-arrow">
                    <FaChevronRight />
                  </span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -applications">
                  <ul className="menu-sub-applications">
                    <li className="menu-item-messages">
                      <a className="menu-link" href="/demo9/app-messages">
                        <span className="menu-text">Messages</span>
                      </a>
                    </li>
                    <li className="menu-item-chat">
                      <a className="menu-link" href="/demo9/app-chat">
                        <span className="menu-text">Chats / Messenger</span>
                      </a>
                    </li>
                    <li className="menu-item-mailbox">
                      <a className="menu-link" href="/demo9/app-inbox">
                        <span className="menu-text">Mailbox</span>
                      </a>
                    </li>
                    <li className="menu-item-calendar">
                      <a className="menu-link" href="/demo9/app-calender">
                        <span className="menu-text">Calendar</span>
                      </a>
                    </li>
                    <li className="menu-item-kanban">
                      <a className="menu-link" href="/demo9/app-kanban">
                        <span className="menu-text">Kanban</span>
                      </a>
                    </li>
                    <li className="menu-item-file-manager">
                      <a className="menu-link" href="/demo9/app-file-manager">
                        <span className="menu-text">File Manager</span>
                        <span className="menu-badge">new</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-products">
                <a
                  className="menu-link toggle-products"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-card-view"></em>
                  </span>
                  <span className="menu-text">Products</span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -products">
                  <ul className="menu-sub-products">
                    <li className="menu-item-product-list">
                      <a className="menu-link" href="/demo9/product-list">
                        <span className="menu-text">Product List</span>
                      </a>
                    </li>
                    <li className="menu-item-product-card">
                      <a className="menu-link" href="/demo9/product-card">
                        <span className="menu-text">Product Card</span>
                      </a>
                    </li>
                    <li className="menu-item-product-details">
                      <a className="menu-link" href="/demo9/product-details/0">
                        <span className="menu-text">Product Details</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-invoice">
                <a
                  className="menu-link toggle-invoice"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-file-docs"></em>
                  </span>
                  <span className="menu-icon">
                    <FaFileInvoiceDollar />
                  </span>
                  <span className="menu-text">Invoice</span>
                  <span className="menu-arrow">
                    <FaChevronRight />
                  </span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -invoice">
                  <ul className="menu-sub-invoice">
                    <li className="menu-item-invoice-list">
                      <a className="menu-link" href="/demo9/invoice-list">
                        <span className="menu-text">Invoice List</span>
                      </a>
                    </li>
                    <li className="menu-item-invoice-details">
                      <a className="menu-link" href="/demo9/invoice-details/1">
                        <span className="menu-text">Invoice Details</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-image-gallery">
                <a className="menu-link" href="/demo9/image-gallery">
                  <span className="menu-icon">
                    <em className="icon icon-img"></em>
                  </span>
                  <span className="menu-icon">
                    <FaImages />
                  </span>
                  <span className="menu-text">Image Gallery</span>
                  <span className="menu-arrow">
                    <FaChevronRight />
                  </span>
                </a>
              </li>

              <li className="menu-heading-misc-pages">
                <h6 className="menu-title-primary-alt">Misc Pages</h6>
              </li>
              <li className="menu-item-auth-pages">
                <a
                  className="menu-link toggle-auth-pages"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-signin"></em>
                  </span>
                  <span className="menu-icon">
                    <FaSignInAlt />
                  </span>
                  <span className="menu-text">Auth Pages</span>
                  <span className="menu-arrow">
                    <FaChevronRight />
                  </span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -auth-pages">
                  <ul className="menu-sub-auth-pages">
                    <li className="menu-item-auth-login">
                      <a
                        rel="noopener noreferrer"
                        className="menu-link"
                        href="/demo9/auth-login"
                        target="_blank"
                      >
                        <span className="menu-text">Login / Signin</span>
                      </a>
                    </li>
                    <li className="menu-item-auth-register">
                      <a
                        rel="noopener noreferrer"
                        className="menu-link"
                        href="/demo9/auth-register"
                        target="_blank"
                      >
                        <span className="menu-text">Register / Signup</span>
                      </a>
                    </li>
                    <li className="menu-item-auth-reset">
                      <a
                        rel="noopener noreferrer"
                        className="menu-link"
                        href="/demo9/auth-reset"
                        target="_blank"
                      >
                        <span className="menu-text">Forgot Password</span>
                      </a>
                    </li>
                    <li className="menu-item-auth-success">
                      <a
                        rel="noopener noreferrer"
                        className="menu-link"
                        href="/demo9/auth-success"
                        target="_blank"
                      >
                        <span className="menu-text">Success / Confirm</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="menu-item-error-pages">
                <a
                  className="menu-link toggle-error-pages"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-files"></em>
                  </span>
                  <span className="menu-icon">
                    <FaExclamationTriangle />
                  </span>
                  <span className="menu-text">Error Pages</span>
                  <span className="menu-arrow">
                    <FaChevronRight />
                  </span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -error-pages">
                  <ul className="menu-sub-error-pages">
                    <li className="menu-item-error-404-classic">
                      <a
                        rel="noopener noreferrer"
                        className="menu-link"
                        href="/demo9/errors/404-classic"
                        target="_blank"
                      >
                        <span className="menu-text">404 Classic</span>
                      </a>
                    </li>
                    <li className="menu-item-error-504-classic">
                      <a
                        rel="noopener noreferrer"
                        className="menu-link"
                        href="/demo9/errors/504-classic"
                        target="_blank"
                      >
                        <span className="menu-text">504 Classic</span>
                      </a>
                    </li>
                    <li className="menu-item-error-404-modern">
                      <a
                        rel="noopener noreferrer"
                        className="menu-link"
                        href="/demo9/errors/404-modern"
                        target="_blank"
                      >
                        <span className="menu-text">404 Modern</span>
                      </a>
                    </li>
                    <li className="menu-item-error-504-modern">
                      <a
                        rel="noopener noreferrer"
                        className="menu-link"
                        href="/demo9/errors/504-modern"
                        target="_blank"
                      >
                        <span className="menu-text">504 Modern</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-other-pages">
                <a
                  className="menu-link toggle-other-pages"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-files"></em>
                  </span>
                  <span className="menu-icon">
                    <FaFileAlt />
                  </span>
                  <span className="menu-text">Other Pages</span>
                  <span className="menu-arrow">
                    <FaChevronRight />
                  </span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -other-pages">
                  <ul className="menu-sub-other-pages">
                    <li className="menu-item-blank-startup">
                      <a className="menu-link" href="/demo9/_blank">
                        <span className="menu-text">Blank / Startup</span>
                      </a>
                    </li>
                    <li className="menu-item-faq-help">
                      <a className="menu-link" href="/demo9/pages/faq">
                        <span className="menu-text">Faqs / Help</span>
                      </a>
                    </li>
                    <li className="menu-item-terms-policy">
                      <a className="menu-link" href="/demo9/pages/terms-policy">
                        <span className="menu-text">Terms / Policy</span>
                      </a>
                    </li>
                    <li className="menu-item-regular-v1">
                      <a className="menu-link" href="/demo9/pages/regular-v1">
                        <span className="menu-text">Regular Page - v1</span>
                      </a>
                    </li>
                    <li className="menu-item-regular-v2">
                      <a className="menu-link" href="/demo9/pages/regular-v2">
                        <span className="menu-text">Regular Page - v2</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-ui-elements">
                <a
                  className="menu-link toggle-ui-elements"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-layers"></em>
                  </span>
                  <span className="menu-icon">
                    <FaPuzzlePiece />
                  </span>
                  <span className="menu-text">Ui Elements</span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -ui-elements">
                  <ul className="menu-sub-ui-elements">
                    <li className="menu-item-alerts">
                      <a className="menu-link" href="/demo9/components/alerts">
                        <span className="menu-text">Alerts</span>
                      </a>
                    </li>
                    <li className="menu-item-accordions">
                      <a
                        className="menu-link"
                        href="/demo9/components/accordions"
                      >
                        <span className="menu-text">Accordions</span>
                      </a>
                    </li>
                    <li className="menu-item-avatar">
                      <a className="menu-link" href="/demo9/components/avatar">
                        <span className="menu-text">Avatar</span>
                      </a>
                    </li>
                    <li className="menu-item-badges">
                      <a className="menu-link" href="/demo9/components/badges">
                        <span className="menu-text">Badges</span>
                      </a>
                    </li>
                    <li className="menu-item-buttons">
                      <a className="menu-link" href="/demo9/components/buttons">
                        <span className="menu-text">Buttons</span>
                      </a>
                    </li>
                    <li className="menu-item-button-group">
                      <a
                        className="menu-link"
                        href="/demo9/components/button-group"
                      >
                        <span className="menu-text">Button Group</span>
                      </a>
                    </li>
                    <li className="menu-item-breadcrumbs">
                      <a
                        className="menu-link"
                        href="/demo9/components/breadcrumbs"
                      >
                        <span className="menu-text">Breadcrumbs</span>
                      </a>
                    </li>
                    <li className="menu-item-cards">
                      <a className="menu-link" href="/demo9/components/cards">
                        <span className="menu-text">Cards</span>
                      </a>
                    </li>
                    <li className="menu-item-carousel">
                      <a
                        className="menu-link"
                        href="/demo9/components/carousel"
                      >
                        <span className="menu-text">Carousel</span>
                      </a>
                    </li>
                    <li className="menu-item-dropdowns">
                      <a
                        className="menu-link"
                        href="/demo9/components/dropdowns"
                      >
                        <span className="menu-text">Dropdowns</span>
                      </a>
                    </li>
                    <li className="menu-item-modals">
                      <a className="menu-link" href="/demo9/components/modals">
                        <span className="menu-text">Modals</span>
                      </a>
                    </li>
                    <li className="menu-item-pagination">
                      <a
                        className="menu-link"
                        href="/demo9/components/pagination"
                      >
                        <span className="menu-text">Pagination</span>
                      </a>
                    </li>
                    <li className="menu-item-popovers">
                      <a
                        className="menu-link"
                        href="/demo9/components/popovers"
                      >
                        <span className="menu-text">Popovers</span>
                      </a>
                    </li>
                    <li className="menu-item-progress">
                      <a
                        className="menu-link"
                        href="/demo9/components/progress"
                      >
                        <span className="menu-text">Progress</span>
                      </a>
                    </li>
                    <li className="menu-item-spinner">
                      <a className="menu-link" href="/demo9/components/spinner">
                        <span className="menu-text">Spinner</span>
                      </a>
                    </li>
                    <li className="menu-item-tabs">
                      <a className="menu-link" href="/demo9/components/tabs">
                        <span className="menu-text">Tabs</span>
                      </a>
                    </li>
                    <li className="menu-item-toast">
                      <a className="menu-link" href="/demo9/components/toast">
                        <span className="menu-text">Toast</span>
                      </a>
                    </li>
                    <li className="menu-item-typography">
                      <a
                        className="menu-link"
                        href="/demo9/components/typography"
                      >
                        <span className="menu-text">Typography</span>
                      </a>
                    </li>
                    <li className="menu-item-tooltips">
                      <a
                        className="menu-link"
                        href="/demo9/components/tooltips"
                      >
                        <span className="menu-text">Tooltips</span>
                      </a>
                    </li>
                    <li className="menu-item-utilities">
                      <a
                        className="menu-link toggle-utilities"
                        href="#"
                        onClick={useSidebarToggle}
                      >
                        <span className="menu-text">Utilities</span>
                      </a>
                      <div style={{height: '0px'}} className="has-sub menu-wrap -utilities">
                        <ul className="menu-sub-utilities">
                          <li className="menu-item-border">
                            <a
                              className="menu-link"
                              href="/demo9/components/util-border"
                            >
                              <span className="menu-text">Borders</span>
                            </a>
                          </li>
                          <li className="menu-item-colors">
                            <a
                              className="menu-link"
                              href="/demo9/components/util-colors"
                            >
                              <span className="menu-text">Colors</span>
                            </a>
                          </li>
                          <li className="menu-item-display">
                            <a
                              className="menu-link"
                              href="/demo9/components/util-display"
                            >
                              <span className="menu-text">Display</span>
                            </a>
                          </li>
                          <li className="menu-item-embedded">
                            <a
                              className="menu-link"
                              href="/demo9/components/util-embeded"
                            >
                              <span className="menu-text">Embeded</span>
                            </a>
                          </li>
                          <li className="menu-item-flex">
                            <a
                              className="menu-link"
                              href="/demo9/components/util-flex"
                            >
                              <span className="menu-text">Flex</span>
                            </a>
                          </li>
                          <li className="menu-item-text">
                            <a
                              className="menu-link"
                              href="/demo9/components/util-text"
                            >
                              <span className="menu-text">Text</span>
                            </a>
                          </li>
                          <li className="menu-item-sizing">
                            <a
                              className="menu-link"
                              href="/demo9/components/util-sizing"
                            >
                              <span className="menu-text">Sizing</span>
                            </a>
                          </li>
                          <li className="menu-item-spacing">
                            <a
                              className="menu-link"
                              href="/demo9/components/util-spacing"
                            >
                              <span className="menu-text">Spacing</span>
                            </a>
                          </li>
                          <li className="menu-item-others">
                            <a
                              className="menu-link"
                              href="/demo9/components/util-others"
                            >
                              <span className="menu-text">Others</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-crafted-icons">
                <a
                  className="menu-link toggle-crafted-icons"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-dot-box"></em>
                  </span>
                  <span className="menu-text">Crafted Icons</span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -crafted-icons">
                  <ul className="menu-sub-crafted-icons">
                    <li className="menu-item-svg-icons">
                      <a className="menu-link" href="/demo9/svg-icons">
                        <span className="menu-text">SVG Icon-Exclusive</span>
                      </a>
                    </li>
                    <li className="menu-item-nioicon">
                      <a className="menu-link" href="/demo9/nioicon">
                        <span className="menu-text">Nioicon - HandCrafted</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-tables">
                <a
                  className="menu-link toggle-tables"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-table-view"></em>
                  </span>
                  <span className="menu-text">Tables</span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -tables">
                  <ul className="menu-sub-tables">
                    <li className="menu-item-basic-tables">
                      <a className="menu-link" href="/demo9/table-basic">
                        <span className="menu-text">Basic Tables</span>
                      </a>
                    </li>
                    <li className="menu-item-special-tables">
                      <a className="menu-link" href="/demo9/table-special">
                        <span className="menu-text">Special Tables</span>
                      </a>
                    </li>
                    <li className="menu-item-datatables">
                      <a className="menu-link" href="/demo9/table-datatable">
                        <span className="menu-text">DataTables</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-forms">
                <a
                  className="menu-link toggle-forms"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-card-view"></em>
                  </span>
                  <span className="menu-text">Forms</span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -forms">
                  <ul className="menu-sub-forms">
                    <li className="menu-item-form-elements">
                      <a
                        className="menu-link"
                        href="/demo9/components/form-elements"
                      >
                        <span className="menu-text">Form Elements</span>
                      </a>
                    </li>
                    <li className="menu-item-checkbox-radio">
                      <a
                        className="menu-link"
                        href="/demo9/components/checkbox-radio"
                      >
                        <span className="menu-text">Checkbox Radio</span>
                      </a>
                    </li>
                    <li className="menu-item-advanced-controls">
                      <a
                        className="menu-link"
                        href="/demo9/components/advanced-control"
                      >
                        <span className="menu-text">Advanced Controls</span>
                      </a>
                    </li>
                    <li className="menu-item-input-group">
                      <a
                        className="menu-link"
                        href="/demo9/components/input-group"
                      >
                        <span className="menu-text">Input Group</span>
                      </a>
                    </li>
                    <li className="menu-item-form-upload">
                      <a
                        className="menu-link"
                        href="/demo9/components/form-upload"
                      >
                        <span className="menu-text">Form Upload</span>
                      </a>
                    </li>
                    <li className="menu-item-datetime-picker">
                      <a
                        className="menu-link"
                        href="/demo9/components/datetime-picker"
                      >
                        <span className="menu-text">Date Time Picker</span>
                      </a>
                    </li>
                    <li className="menu-item-number-spinner">
                      <a
                        className="menu-link"
                        href="/demo9/components/number-spinner"
                      >
                        <span className="menu-text">Number Spinner</span>
                      </a>
                    </li>
                    <li className="menu-item-nouislider">
                      <a
                        className="menu-link"
                        href="/demo9/components/nouislider"
                      >
                        <span className="menu-text">noUiSlider</span>
                      </a>
                    </li>
                    <li className="menu-item-form-layouts">
                      <a
                        className="menu-link"
                        href="/demo9/components/form-layouts"
                      >
                        <span className="menu-text">Form Layouts</span>
                      </a>
                    </li>
                    <li className="menu-item-form-validation">
                      <a
                        className="menu-link"
                        href="/demo9/components/form-validation"
                      >
                        <span className="menu-text">Form Validation</span>
                      </a>
                    </li>
                    <li className="menu-item-wizard-basic">
                      <a
                        className="menu-link"
                        href="/demo9/components/wizard-basic"
                      >
                        <span className="menu-text">Wizard Basic</span>
                      </a>
                    </li>
                    <li className="menu-item-rich-editor">
                      <a
                        className="menu-link toggle-rich-editor"
                        href="#"
                        onClick={useSidebarToggle}
                      >
                        <span className="menu-text">Rich Editor</span>
                      </a>
                      <div style={{height: '0px'}} className="has-sub menu-wrap -rich-editor">
                        <ul className="menu-sub-rich-editor">
                          <li className="menu-item-quill">
                            <a
                              className="menu-link"
                              href="/demo9/components/quill"
                            >
                              <span className="menu-text">Quill</span>
                            </a>
                          </li>
                          <li className="menu-item-tinymce">
                            <a
                              className="menu-link"
                              href="/demo9/components/tinymce"
                            >
                              <span className="menu-text">Tinymce</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-charts">
                <a
                  className="menu-link toggle-charts"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-pie"></em>
                  </span>
                  <span className="menu-text">Charts</span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -charts">
                  <ul className="menu-sub-charts">
                    <li className="menu-item-chartjs">
                      <a className="menu-link" href="/demo9/charts/chartjs">
                        <span className="menu-text">Chart Js</span>
                      </a>
                    </li>
                    <li className="menu-item-knobs">
                      <a className="menu-link" href="/demo9/charts/knobs">
                        <span className="menu-text">Knobs</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-widgets">
                <a
                  className="menu-link toggle-widgets"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-puzzle"></em>
                  </span>
                  <span className="menu-icon">
                    <FaTh />
                  </span>
                  <span className="menu-text">Widgets</span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -widgets">
                  <ul className="menu-sub-widgets">
                    <li className="menu-item-card-widgets">
                      <a
                        className="menu-link"
                        href="/demo9/components/widgets/cards"
                      >
                        <span className="menu-text">Card Widgets</span>
                      </a>
                    </li>
                    <li className="menu-item-chart-widgets">
                      <a
                        className="menu-link"
                        href="/demo9/components/widgets/charts"
                      >
                        <span className="menu-text">Chart Widgets</span>
                      </a>
                    </li>
                    <li className="menu-item-rating-widgets">
                      <a
                        className="menu-link"
                        href="/demo9/components/widgets/rating"
                      >
                        <span className="menu-text">Rating Widgets</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-miscellaneous">
                <a
                  className="menu-link toggle-miscellaneous"
                  href="#"
                  onClick={useSidebarToggle}
                >
                  <span className="menu-icon">
                    <em className="icon icon-block-over"></em>
                  </span>
                  <span className="menu-text">Miscellaneous</span>
                </a>
                <div style={{height: '0px'}} className="has-sub menu-wrap -miscellaneous">
                  <ul className="menu-sub-miscellaneous">
                    <li className="menu-item-slick-sliders">
                      <a
                        className="menu-link"
                        href="/demo9/components/misc/slick-slider"
                      >
                        <span className="menu-text">Slick Sliders</span>
                      </a>
                    </li>
                    <li className="menu-item-jstree">
                      <a
                        className="menu-link"
                        href="/demo9/components/misc/jsTree"
                      >
                        <span className="menu-text">JsTree</span>
                      </a>
                    </li>
                    <li className="menu-item-toastify">
                      <a
                        className="menu-link"
                        href="/demo9/components/misc/toastify"
                      >
                        <span className="menu-text">React Toastify</span>
                      </a>
                    </li>
                    <li className="menu-item-sweet-alert">
                      <a
                        className="menu-link"
                        href="/demo9/components/misc/sweet-alert"
                      >
                        <span className="menu-text">Sweet Alert</span>
                      </a>
                    </li>
                    <li className="menu-item-dual-listbox">
                      <a
                        className="menu-link"
                        href="/demo9/components/misc/dual-list"
                      >
                        <span className="menu-text">React DualListBox</span>
                      </a>
                    </li>
                    <li className="menu-item-beautiful-dnd">
                      <a
                        className="menu-link"
                        href="/demo9/components/misc/beautiful-dnd"
                      >
                        <span className="menu-text">React Beautiful Dnd</span>
                      </a>
                    </li>
                    <li className="menu-item-google-map">
                      <a
                        className="menu-link"
                        href="/demo9/components/misc/map"
                      >
                        <span className="menu-text">Google Map</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item-email-template">
                <a className="menu-link" href="/demo9/email-template">
                  <span className="menu-icon">
                    <em className="icon icon-text-rich"></em>
                  </span>
                  <span className="menu-icon">
                    <FaEnvelopeOpenText />
                  </span>
                  <span className="menu-text">Email Template</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
