import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaBars, FaExchangeAlt, FaTag, FaWallet, FaChartLine, FaCog, FaSignOutAlt } from "react-icons/fa";
import "../styles/DashboardHeader.css";
import { LanguageContext } from "../context/LanguageContext"; // Importa el contexto
// Importa los archivos JSON de traducción
import en from "../langs/en-us.json";
import es from "../langs/es-es.json";
import pl from "../langs/pl-pl.json";
import pt from "../langs/pt-br.json";
import zh from "../langs/zh-cn.json";

// Lista de idiomas
const languages = {
  en: { name: "English", flag: "/assets/images/flags/us.png", data: en },
  es: { name: "Español", flag: "/assets/images/flags/es.png", data: es },
  pl: { name: "Polski", flag: "/assets/images/flags/pl.png", data: pl },
  pt: { name: "Português", flag: "/assets/images/flags/br.png", data: pt },
  zh: { name: "中文", flag: "/assets/images/flags/cn.png", data: zh },
};


const DashboardHeader = () => {
  // const { translations } = useContext(LanguageContext); // Obtiene las traducciones desde el contexto
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem("jwt");
        if (!token) {
          window.location.href = "/login";
          return;
        }

        const response = await axios.get("https://www.swapbits.io/api/users/info", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.role === "Admin") {
          setIsAdmin(true);
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
        // window.location.href = "/login";
      }
    };

    fetchUserInfo();
  }, []);
  const handleClick = () => {
    const sideNavbar = document.querySelector('.side-navbar-wrapper');
  
      sideNavbar.classList.toggle('active');
    
  };

  const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lang") || "en");
    const [translations, setTranslations] = useState(languages[selectedLang].data);
  
    // Actualizar idioma en tiempo real
    useEffect(() => {
      const timeoutId = setInterval(() => {
        // localStorage.setItem("lang", localStorage.getItem("lang"));
        setSelectedLang(localStorage.getItem("lang"))
        setTranslations(languages[selectedLang].data);
        // console.log(languages[selectedLang].data)
      }, 100);
      return () => clearTimeout(timeoutId);
    }, [selectedLang]);

  return (
    <div className="page-wrapper">
      <div className="side-navbar-wrapper">
        <div className="side-navbar-singel-wrapper">
          <div className="sidebar-logo-wrapper">
            <a href="../assets/images/swapbits_logo_high_quality_transparent-2.png" className="sidebar-logo-link-block w-inline-block">
              <img src="../assets/images/swapbits_logo_high_quality_transparent-2.png" loading="lazy" alt="Logo" />
            </a>
            <div className="mobile-menu-close" onClick={handleClick}>
              <img src="../assets/images/Clouse.svg" loading="lazy" alt="Close" className="clouse" />
            </div>
          </div>
          <div className="sidebar-menu-wrapper">
            <Link to="/" className="sidebar-menu-link-block w-inline-block">
              <div className="sidebar-menu-link-icon-wrap">
                <FaBars />
              </div>
              <div className="sidebar-menu-text-block">{translations.dashboard}</div>
            </Link>
            <Link to="dashboard/exchange" className="sidebar-menu-link-block w-inline-block">
              <div className="sidebar-menu-link-icon-wrap">
                <FaExchangeAlt />
              </div>
              <div className="sidebar-menu-text-block">{translations.exchange}</div>
            </Link>
            <Link to="dashboard/prices" className="sidebar-menu-link-block w-inline-block">
              <div className="sidebar-menu-link-icon-wrap">
                <FaTag />
              </div>
              <div className="sidebar-menu-text-block">{translations.prices}</div>
            </Link>
            <Link to="dashboard/wallet" className="sidebar-menu-link-block w-inline-block">
              <div className="sidebar-menu-link-icon-wrap">
                <FaWallet />
              </div>
              <div className="sidebar-menu-text-block">{translations.wallets}</div>
            </Link>
            <Link to="dashboard/activities" className="sidebar-menu-link-block w-inline-block">
              <div className="sidebar-menu-link-icon-wrap">
                <FaChartLine />
              </div>
              <div className="sidebar-menu-text-block">{translations.activities}</div>
            </Link>
            <Link to="dashboard/settings" className="sidebar-menu-link-block w-inline-block">
              <div className="sidebar-menu-link-icon-wrap">
                <FaCog />
              </div>
              <div className="sidebar-menu-text-block">{translations.settings}</div>
            </Link>
          </div>
          <Link to="/logout" className="sidebar-log-out-wrapper w-inline-block">
            <div className="sidebar-log-out-image-wrapper">
              <FaSignOutAlt className="sidebar-log-out-image" />
            </div>
            <div className="sidebar-log-out-image-text-block">{translations.logout}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
